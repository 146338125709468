import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { Fade } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import SecurityUserContext from "../../../../context/SecurityUserContext";

function CreateNeighborhood(props) {
  const { setNeighborhoodBeingCreated } = useContext(SecurityUserContext);
  const [tempName, setTempName] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    if (tempName !== "") {
      props.setNeighborhoodName(tempName);
      props.setModalShow(false);
      props.setNeighborhoodCreated(false);
      setNeighborhoodBeingCreated(true);
    } else {
      console.log("empty");
      inputEmptyError();
    }
  };

  const inputEmptyError = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  };

  return (
    <div class="hero min-h-full drop-shadow-xl">
      <div class="hero-content flex-col lg:flex-row-reverse rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div class="card flex-shrink-0 w-full">
          <div class="card-body">
            <div className="flex flex-col">
              <div className="font-bold text-[#2a303c] mb-10">
                <p className="text-center text-4xl">
                  Create A New Neighborhood
                </p>
              </div>
              <div className="form-control">
                <Tooltip
                  title={`${
                    showError ? "Please enter a neighborhood name" : ""
                  }`}
                  placement="top"
                  TransitionComponent={Fade}
                  open={`${showError ? "true" : "false"}`}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#e93e36",
                        "& .MuiTooltip-arrow": {
                          color: "common.black",
                        },
                      },
                    },
                  }}
                >
                  <div>
                    <input
                      value={tempName}
                      onChange={(e) => setTempName(e.target.value)}
                      type="text"
                      placeholder="Neighborhood Name"
                      class="input input-bordered w-full bg-white"
                    />
                  </div>
                </Tooltip>
              </div>

              <div className="flex flex-row justify-between">
                <div class="form-control mt-6 pr-2">
                  <button class="btn" onClick={() => props.setModalShow(false)}>
                    Cancel
                  </button>
                </div>
                <div class="form-control mt-6">
                  <button class="btn btn-info" onClick={handleSubmit}>
                    Create Neighborhood
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNeighborhood;
