import React, { useState, useEffect } from "react";
// import { auth } from "../../firebase.config";
import { auth } from "../../../firebase.auth";
import {
  getAuth,
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { BsCheckLg } from "react-icons/bs";

function LoggedOut(props) {
  const [email, setEmail] = useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );
  const [errorResponse, setErrorResponse] = useState("");
  const auth = getAuth();
  const [linkSent, setLinkSent] = useState(false);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "http://localhost:3000/securityservices/signin",
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.example.ios",
    // },
    // android: {
    //   packageName: "com.example.android",
    //   installApp: true,
    //   minimumVersion: "12",
    // },
    // dynamicLinkDomain: "example.page.link",
  };

  const trySignIn = async () => {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        console.log("link was sent successfully");
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        setLinkSent(true);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  const handleEmailChange = (e) => {
    // clearError();
    setEmail(e.target.value);
  };

  if (linkSent === false) {
    return (
      <div class="hero min-h-screen bg-base-200">
        <div class="hero-content flex-col lg:flex-row-reverse">
          <div class="text-center lg:text-left">
            <h1 class="text-5xl font-bold">You appear to be logged out</h1>
            <p class="py-6">Enter in your email</p>
            <p>
              An email will be sent to you that will have a verification link.
            </p>
            
            <p>
              Use that link to sign back in.
            </p>
          </div>
          <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
            <div class="card-body">
              <div class="form-control">
                <label class="label">
                  <span class="label-text">Email</span>
                </label>
                <input
                  value={email}
                  onChange={handleEmailChange}
                  type="text"
                  placeholder="email"
                  class="input input-bordered"
                />
              </div>
              <div class="form-control mt-6">
                <button class="btn btn-info" onClick={trySignIn}>
                  Send Verification Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div class="hero min-h-screen bg-base-200">
        <div class="hero-content flex-col lg:flex-row-reverse">
          <div class="card flex-shrink-0 w-full  shadow-2xl bg-base-100">
            <div class="card-body">
              <div className="flex flex-row">
                <div className="self-center">
                  <BsCheckLg style={{ fontSize: "60px", color: "green" }} />
                </div>
                <div class=" ml-8 place-self-center lg:text-left">
                  <h1 class="text-4xl font-bold">Email Link Sent</h1>
                  <p class="pt-2">Please check your email for verification link</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoggedOut;