import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdCopy } from "react-icons/io";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../../components/Loader";
import { IoIosWarning } from "react-icons/io";

function NeighborhoodInfo() {
  const { nid: paramNeighborhoodID } = useParams();
  const navigate = useNavigate();
  const {
    neighborhoodJustCreated,
    setNeighborhoodJustCreated,
    newNeighborhoodData,
    neighborhoods,
  } = useContext(SecurityUserContext);
  const [neighborhoodURL, setNeighborhoodURL] = useState("");
  const [buttonHTML, setButtonHTML] = useState("");
  const [discordInviteButton, setDiscordInviteButton] = useState("");
  const [neighborhoodInformation, setNeighborhoodInformation] = useState([""]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [neighborhooodBeingDeleted, setNeighborhoodBeingDeleted] =
    useState(false);
  const [currentURLBase, setCurrentURLBase] = useState("")

  useEffect(() => {
    if (neighborhoods.length !== 0) {
      neighborhoods.map((item, i) => {
        if (item.id === paramNeighborhoodID) {
          setNeighborhoodInformation(item);
        }
      });
    }
  }, [neighborhoods]);

//get url base to insert as var into posts
useEffect(() => {
  let pathURL = window.location.href
  let pathSplit = pathURL.split('/')
  setCurrentURLBase(pathSplit[2])
  console.log(pathSplit)
}, [])

  useEffect(() => {
    if (neighborhoodInformation !== null) {
      //Modify with actual site URL later
      let tempurl = `https://nexusneighbor.com/${neighborhoodInformation.neighborhoodID}`;
      setNeighborhoodURL(tempurl);
      let tempHTML = `<a href=${tempurl}><button style="padding:6px 4px; width:275px; height:50px; background-image: linear-gradient(to right, #7c3aed, #4f46e5); color:white; border-radius:5px; border:none;">Nexus Neighbor</button></a>`;
      setButtonHTML(tempHTML);

      let tempDiscordInvite = `<a href=${neighborhoodInformation.discordInviteURL}><button style="padding:6px 4px; width:275px; height:50px; background-color:#5865f1; color:white; border-radius:5px; border:none;">Discord Invite</button></a>`;
      setDiscordInviteButton(tempDiscordInvite);
    }
  }, [neighborhoodInformation]);

  const handleCopyLink = () => {
    toast.success(
      `The following URL link was copied to your clipboard ${neighborhoodURL} `
    );
    navigator.clipboard.writeText(neighborhoodURL);
  };

  const handleCopyHTML = () => {
    toast.success(
      `The button HTML was copied, please paste it into your website`
    );
    navigator.clipboard.writeText(buttonHTML);
  };

  const handleCopyDiscordLink = () => {
    toast.success(
      `The following URL link was copied to your clipboard ${neighborhoodInformation.discordInviteURL}`
    );
    navigator.clipboard.writeText(neighborhoodInformation.discordInviteURL)
  };

  const handleDiscordButtonCopyHTML = () => {
    toast.success(
      `The Discord invite button was copied, please paste it into your website`
    );
    navigator.clipboard.writeText(discordInviteButton);
  };

  const handleDeleteNeighborhood = async () => {
    console.log("Neighborhood deleted");
    setNeighborhoodBeingDeleted(true);
    await axios
      .get("/deleteNeighborhood", {
        params: { neighborhoodID: paramNeighborhoodID },
      })
      .then((response) => {
        console.log(response.data);
        setShowWarningModal(false);
        setNeighborhoodBeingDeleted(false);
        navigate("/admin");
      });
  };

  const WarningModal = () => {
    return (
      <div class="flex drop-shadow-xl self-center">
        <div class="py-10 px-10 flex-col rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
          <div className="flex flex-col items-center	">
            <div className="flex flex-col">
              <div className="flex flex-row justify-center">
                <div className="mr-4 pt-1">
                  <IoIosWarning className="text-3xl text-red-700 text-center"/>
                </div>
                <div className="self-center text-red-700 text-2xl font-bold mb-5">
                  Warning
                </div>
              </div>
              <div className="">
                Are you sure you want to delete this neighborhood?
              </div>
              <div className="mb-5">This action cannot be undone!</div>
              <div className="flex justify-center">
                <div className="">
                  <button
                    className="btn mr-5 border-none"
                    onClick={() => setShowWarningModal(false)}
                  >
                    No Cancel
                  </button>
                  <button
                    className="btn bg-red-700 hover:bg-red-900 border-none"
                    onClick={handleDeleteNeighborhood}
                  >
                    Yes Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="hero min-w-max ">
      <div className="flex items-stretch items-center mt-10 pt-4 px-20 shadow-2xl bg-[#ffffff] rounded-xl">
        <div
          className={`flex flex-col min-h-full place-content-center ${
            neighborhooodBeingDeleted ? "blur" : ""
          } `}
        >
          <div className=" p-10 ">
            <h1 className="text-center text-4xl text-[#2a303c]">
              Neighborhood Information Sheet
            </h1>
          </div>
          <div className="basis-3/4 p-4">
            {neighborhoodInformation !== null ? (
              <div className="flex flex-col w-full p-2 mb-2">
                <div className="flex flex-row w-1/2 mb-2">
                  <div className="text-lg">Neighborhood Name:</div>
                  <div className="text-lg ml-10 text-[#2a303c]">
                    {neighborhoodInformation.name}
                  </div>
                </div>
                <div className="mb-2 bg-white rounded rounded-md p-1">
                  <div className="flex flex-col">
                    <div className="font-bold">
                      Below You'll find 2 important links
                    </div>
                    <div className="pl-2">
                      1. Neighborhood Link: your users will use to report, view,
                      or reply to incidents in the neighborhood.
                    </div>
                    <div className="pl-2">
                      2. Discord link invite: invites neighbors to get real-time
                      notifications of activity on their phone.
                    </div>
                    <div className="1/2">
                      <div className="font-bold pt-1">
                        You have two options for each link
                      </div>
                      <div className="pl-2">
                        Option 1: Copy the URL and share the URL link with your
                        neighborhood
                      </div>
                      <div className="pl-2">
                        Option 2: Copy the button and past it into your website
                      </div>
                      <div className="text-sm mt-1">
                        Note: you can access this information inside your
                        neighborhood settings.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2 bg-white rounded rounded-md p-1 border-t-2 border-b-2">
                  <div className="font-bold text-[#2a303c]">
                    Neighborhood Link
                  </div>
                  <div className="flex flex-row w-full mb-2 justify-between">
                    <div className="text-lg">
                      Neighborhood URL Link To Share:
                    </div>
                    <div className="flex flex-row">
                      <div
                        onClick={handleCopyLink}
                        className="cursor-pointer text-lg ml-10 text-[#2a303c] py-1 px-2 rounded-lg border border-[#2a303c]"
                      >
                        {neighborhoodURL}
                      </div>
                      <div className="flex self-center ml-auto">
                        <IoMdCopy
                          className="text-[#2a303c] text-3xl cursor-pointer"
                          onClick={handleCopyLink}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row w-full mt-4 justify-between">
                    <div className="text-lg 1/2">
                      Button HTML To Paste Into Your Site:
                    </div>
                    <div className="flex flex-row">
                      <div className="">
                        <button
                        className="btn btn-sm bg-gradient-to-r from-violet-600 to-indigo-600 text-white py-1 px-2 w-[200px] border-none"
                          onClick={handleCopyHTML}
                        >
                          Nexus Neighbor
                        </button>
                      </div>
                      <div className="pt-2">
                        <IoMdCopy
                          className="text-[#2a303c] text-3xl cursor-pointer"
                          onClick={handleCopyHTML}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded rounded-md p-1 border-t-2 border-b-2">
                  <div className="font-bold text-[#2a303c]">Discord Link</div>
                  <div className="flex flex-row w-full mb-2 justify-between">
                    <div className="text-lg">Discord URL Link To Share:</div>
                    <div className="flex flex-row">
                      <div
                        onClick={handleCopyDiscordLink}
                        className="cursor-pointer text-lg ml-10 text-[#2a303c]  py-1 px-2 rounded-lg border border-[#2a303c]"
                      >
                        {neighborhoodInformation.discordInviteURL}
                      </div>
                      <div className="flex self-center ml-auto">
                        <IoMdCopy
                          className="text-[#2a303c] text-3xl cursor-pointer"
                          onClick={handleCopyDiscordLink}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row w-full mt-4 justify-between">
                    <div className="text-lg 1/2">
                      Button HTML To Paste Into Your Site:
                    </div>
                    <div className="flex flex-row">
                      <div className="">
                        <button
                          className="btn btn-sm bg-[#5865f1] text-white py-1 px-2 border-none w-[200px]"
                          onClick={handleDiscordButtonCopyHTML}
                        >
                          Discord Invite
                        </button>
                      </div>
                      <div className="pt-2">
                        <IoMdCopy
                          className="text-[#2a303c] text-3xl cursor-pointer"
                          onClick={handleDiscordButtonCopyHTML}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No Neighborhood Information at this time</div>
            )}

            <div className="flex flex-row justify-end	pr-4">
              <div className="">
                <button
                  className="btn btn-wide bg-red-700 border-none hover:bg-red-900"
                  onClick={() => setShowWarningModal(true)}
                >
                  Delete Neighborhood
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {neighborhooodBeingDeleted ? (
        <div className="flex flex-col justify-center items-center">
          <Loader />
          <div className="mt-2 font-bold text-3xl text-[#1fb2a6]">
            Deleting Neighborhood
          </div>
        </div>
      ) : null}
      {showWarningModal ? <WarningModal /> : null}
    </div>
  );
}

export default NeighborhoodInfo;
