import React, { useState, useContext, useEffect } from "react";
import SecurityUserContext from "../../../context/SecurityUserContext";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { VscChromeClose } from "react-icons/vsc";
import Reportincident from "../ReportIncident";
import Reports from "./AdminComponents/Reports";
import NeighborhoodActivity from "../NeighborhoodActivity";
import { toast } from "react-toastify";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GiGhostAlly } from "react-icons/gi";
import { RiCommunityLine } from "react-icons/ri";
import { MdSendToMobile } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { BsShare } from "react-icons/bs";
import { IoMdArrowRoundBack } from "react-icons/io";
import NeighborhoodInfo from "./AdminComponents/NeighborhoodInfo";

function AdminNeighborhood() {
  const navigate = useNavigate();
  const { nid: paramNeighborhoodID } = useParams();
  const {
    allIncidents,
    allIncidentReplies,
    neighborhoods,
    setDiscordWebhookURL,
  } = useContext(SecurityUserContext);
  const [neighborhoodName, setNeighborhoodName] = useState("");
  const [neighborhoodIncidents, setNeighborhoodIncidents] = useState(null);
  const [neighborhoodIncidentReplies, setNeighborhoodIncidentReplies] =
    useState(null);
  const [showReportIncident, setShowReportIncident] = useState(false);
  const [whatItemToShow, setWhatItemToShow] = useState("activity");
  const [iconSelected, setIconSelected] = useState("activity");
  const [loading, setLoading] = useState(true);
  const [currentBaseURL, setCurrentBaseURL] = useState("")

  //getting data for neighborhood populated
  useEffect(() => {
    if (allIncidents !== null) {
      console.log(neighborhoods);
      console.log(allIncidents);
      //setting neighborhood information
      let tempIncidentsArray = [];
      let tempRepliesArray = [];
      //setting neighborhood name
      neighborhoods.map((item, i) => {
        if (item.id === paramNeighborhoodID) {
          setNeighborhoodName(item.name);
          setDiscordWebhookURL(item.webhookURL);
        }
      });
      //setting all incidents for this neighborhood
      allIncidents.map((item, i) => {
        if (item.neighborhoodID === paramNeighborhoodID) {
          tempIncidentsArray.push(item);
        }
        setNeighborhoodIncidents(tempIncidentsArray);
      });
      //setting all replies for this neighborhood
      if (allIncidentReplies !== null) {
        console.log(allIncidentReplies);
        allIncidentReplies.map((item, i) => {
          if (item.neighborhoodID === paramNeighborhoodID) {
            tempRepliesArray.push(item);
          }
          setNeighborhoodIncidentReplies(tempRepliesArray);
        });
      }
    }
  }, [allIncidents, allIncidentReplies]);

  //get url base to insert as var into posts
  useEffect(() => {
    let pathURL = window.location.href
    let pathSplit = pathURL.split('/')
    setCurrentBaseURL(pathSplit[2])
    console.log(pathSplit)
  }, [])

  useEffect(() => {
    if (allIncidents && allIncidentReplies !== null) {
      setLoading(false);
    }
  }, [allIncidents, allIncidentReplies]);

  useEffect(() => {
    if (neighborhoodIncidentReplies !== null) {
      console.log(neighborhoodIncidentReplies);
    }
  }, [neighborhoodIncidentReplies]);

  const createSharableLink = () => {
    let tempURL = `http://${currentBaseURL}/${paramNeighborhoodID}`
    toast.success(
      `The following URL link was copied to your clipboard ${tempURL} `
    );
    navigator.clipboard.writeText(tempURL);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div
      className={`drawer bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:pt-10 py-2`}
    >
      <input id="my-drawer" type="checkbox" class="drawer-toggle" />
      <div class="drawer-content ">
        <div className="flex min-h-screen mx-10 bg-[#f8fafb] rounded-xl drop-shadow-2xl">
          {/* Button that shows on mobile to open menu */}
          <div className="flex flex-row w-full">
            <div className="flex flex-col justify-center sm:pl-3 pl-1 mr-1 w-24 z-50">
              <div className="self-center">
                <label
                  for="my-drawer"
                  class="btn-sm btn-circle swap swap-rotate drawer-button "
                >
                  <input type="checkbox" />
                  <div className="btn rounded-full border bg-adminConsoleText drop-shadow-xl p-1">
                    <GiHamburgerMenu
                      style={{ color: "#f8fafb" }}
                      className="p-1 text-[32] sm:text-[38px] cursor-pointer"
                    />
                  </div>
                </label>
              </div>
              <div className="flex flex-col pt-16">
                <div
                  className="self-center pb-6 tooltip"
                  data-tip="Activity"
                  onClick={() => setWhatItemToShow("activity")}
                >
                  <GiGhostAlly
                    className={`p-1 cursor-pointer ${
                      whatItemToShow === "activity"
                        ? "text-[#2a303c] text-[36px]"
                        : "text-[34px]"
                    }`}
                  />
                </div>
                <div
                  className="self-center pb-6 tooltip"
                  data-tip="Post New Incident"
                  onClick={() => setWhatItemToShow("postIncident")}
                >
                  <MdSendToMobile
                    className={`p-1 cursor-pointer ${
                      whatItemToShow === "postIncident"
                        ? "text-[#2a303c] text-[36px]"
                        : "text-[34px]"
                    }`}
                  />
                </div>
                <div
                  className="self-center pb-6 tooltip"
                  data-tip="Reports"
                  onClick={() => setWhatItemToShow("reports")}
                >
                  <FaRegChartBar
                    className={`p-1 cursor-pointer ${
                      whatItemToShow === "reports"
                        ? "text-[#2a303c] text-[36px]"
                        : "text-[34px]"
                    }`}
                  />
                </div>
                <div
                  className="self-center pb-6 tooltip"
                  data-tip="Neighborhood Info"
                  onClick={() => setWhatItemToShow("neighborhoodInfo")}
                >
                  <RiCommunityLine
                    className={`p-1 cursor-pointer ${
                      whatItemToShow === "neighborhoodInfo"
                        ? "text-[#2a303c] text-[36px]"
                        : "text-[34px]"
                    }`}
                  />
                </div>
                <div
                  className="self-center pb-6 tooltip"
                  data-tip="Copy Sharable Link"
                  onClick={createSharableLink}
                >
                  <BsShare className="p-1 cursor-pointer text-[34px]" />
                </div>
                <div className="self-center pb-6 tooltip" data-tip="Go Home">
                  <IoMdArrowRoundBack
                    className="p-1 cursor-pointer text-[34px]"
                    onClick={handleGoBack}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="flex h-24 mr-10">
                <div className="mt-auto text-3xl text-[#2a303c] font-bold">
                  {neighborhoodName}
                </div>
              </div>
              <div className="w-full">
                {/* Page content here */}
                {whatItemToShow === "activity" ? (
                  !loading ? (
                    <NeighborhoodActivity
                      neighborhoodPropId={paramNeighborhoodID}
                      neighborhoodPropName={neighborhoodName}
                      neighborhoodIncidents={neighborhoodIncidents}
                      neighborhoodReplies={neighborhoodIncidentReplies}
                    />
                  ) : null
                ) : null}
                {whatItemToShow === "postIncident" ? (
                  <Reportincident
                    neighborhoodPropId={paramNeighborhoodID}
                    neighborhoodPropName={neighborhoodName}
                  />
                ) : null}
                {whatItemToShow === "neighborhoodInfo" ? (
                  <NeighborhoodInfo />
                ) : null}
                {whatItemToShow === "reports" ? <Reports /> : null}
                {whatItemToShow === "home" ? navigate("/admin") : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="drawer-side">
        <label for="my-drawer" className="drawer-overlay"></label>

        <ul class="menu p-4 w-60 bg-[#cbd5e1] text-base-content">
          {/*Sidebar content here*/}
          <div className="flex flex-col justify-between items-center">
            <div className="stat-title text-lg text-[#2a303c] ">
              {neighborhoodName}
            </div>
          </div>
          <div className="space-y-4 mt-14 text-[#2a303c]">
            <li onClick={() => setWhatItemToShow("activity")} key="activity">
              <div className="btn btn-ghost justify-start">Activty</div>
            </li>
            <li
              onClick={() => setWhatItemToShow("postIncident")}
              key="postincident"
            >
              <div className="btn btn-ghost justify-start">Post Incident</div>
            </li>
            <li onClick={() => setWhatItemToShow("reports")} key="reports">
              <div className="btn btn-ghost justify-start">Reports</div>
            </li>
            <li
              onClick={() => setWhatItemToShow("neighborhoodInfo")}
              key="NeighborhoodInfo"
              className=""
            >
              <div className="btn btn-ghost justify-start">
                Neighborhood Info
              </div>
            </li>
            <li onClick={createSharableLink} key="sharableLink">
              <div className="flex flex-row btn btn-ghost p-0 justify-start cursor-pointer">
                <div className="">
                  <AiOutlineShareAlt
                    style={{ fontSize: "32px" }}
                    className="p-1 cursor-pointer"
                  />
                </div>
                <div>Sharable Link</div>
              </div>
            </li>
            <li onClick={() => setWhatItemToShow("home")}>
              <div className="btn btn-active btn-ghost justify-start">Home</div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default AdminNeighborhood;
