import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { IncidentsProvider } from "./context/IncidentsContext";
import history from "./history";
import Home from "./pages/Home";
import IncidentDetails from "./pages/IncidentDetails";
import IncidentsList from "./pages/IncidentsList";
import ReportIncident from "./pages/ReportIncident";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SecurityUserProvider } from "./context/SecurityUserContext";
import HomeBlank from "./pages/HomeBlank"
//Admin import section
import AdminHome from "./adminsrc/pages/Admin/AdminHome"
import AdminIncidentDetails from "./adminsrc/pages/IncidentDetails";
import AdminIncidentsList from "./adminsrc/pages/IncidentsList";
import AdminReportIncident from "./adminsrc/pages/ReportIncident";
import NotLoggedIn from "./adminsrc/pages/UserSignin/NotLoggedIn";
import UserSignup from "./adminsrc/pages/UserSignin/Signup";
import Signin from "./adminsrc/pages/UserSignin/Signin";
import LoggedOut from "./adminsrc/pages/UserSignin/LoggedOut";
import AdminNeighborhood from "./adminsrc/pages/Admin/AdminNeighborhood";
import HomeNotLoggedIn from "./adminsrc/pages/HomeNotLoggedIn";
import SubscriptionProducts from "./adminsrc/pages/SubscriptionProducts";
import AcceptPayment from "./adminsrc/pages/Payments/AcceptPayment";
import PaymentSuccess from "./adminsrc/pages/Payments/PaymentSuccess";
import SigningIn from "./adminsrc/pages/UserSignin/SigningIn";
import ReportIncidentMain from "./adminsrc/pages/ReportIncidentMain";
import ForgotPassword from "./adminsrc/pages/UserSignin/ForgotPassword";



function App() {
  return (
    <div>
    <SecurityUserProvider>
        <IncidentsProvider>
          <Router history={history}>
            <div>
              <Routes>
                <Route path="/" exact element={<HomeBlank />}/>
                <Route path="/:nid" exact element={<Home />} />
                <Route path="/:nid/incidents" exact element={<IncidentsList />} />
                <Route path="/:nid/reportincident" exact element={<ReportIncident />} />
                <Route path="/:nid/incidentdetails/:id" element={<IncidentDetails />} />

                <Route path="/admin" exact element={<AdminHome />} />
                <Route path="/admin/HomeNotLoggedIn" exact element={< HomeNotLoggedIn/>} />
                <Route path="/admin/SubscriptionProducts" exact element={<SubscriptionProducts />} />
                <Route path="/admin/AcceptPayment" exact element={<AcceptPayment />} />
                <Route path="/admin/PaymentSuccess" exact element={<PaymentSuccess />} />
                <Route path="/admin/SigningIn" exact element={<SigningIn />} />
                <Route path="/admin/incidents" exact element={<AdminIncidentsList />} />
                <Route path="/admin/reportincident" exact element={<AdminReportIncident />} />
                <Route path="/admin/reportincidentmain" exact element={<ReportIncidentMain />} />
                <Route path="/admin/:nid/incidentdetails/:id" element={<AdminIncidentDetails />} />
                <Route path="/admin/neighborhood/:nid" element={<AdminNeighborhood />} />
                <Route path="/admin/securityservices/signup" exact element={<UserSignup />} />
                <Route path="/admin/securityservices/signin" exact element={<Signin />} />
                <Route path="/admin/forgotpassword" exact element={< ForgotPassword />} />
              </Routes>
            </div>
          </Router>
          <ToastContainer />
         </IncidentsProvider>
    </SecurityUserProvider>
    </div>
  );
}

export default App;
