import React, { useState, useContext, useEffect } from "react";
import {
  useParams,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { add, format } from "date-fns";
import { GiPoliceBadge, GiPoliceCar } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GoComment } from "react-icons/go";
import { GrDocumentVideo } from "react-icons/gr";
import ReplyModal from "../components/ReplyModal";
import { toast } from "react-toastify";
import ReplyCard from "../components/ReplyCard";
import ViewBigImage from "../components/ViewBigImage";
import BarLoader from "react-spinners/BarLoader";
import ReactPlayer from "react-player";
import SecurityUserContext from "../../context/SecurityUserContext";
import logo1 from "../assets/logo1.png"

function IncidentDetails() {
  const { nid: paramNeighborhoodID, id: paramMatchId } = useParams();
  const {
    allIncidents,
    allIncidentReplies,
    neighborhoodID,
    setNeighborhoodID,
    neighborhoods,
  } = useContext(SecurityUserContext);
  const [incidentViewing, setIncidentViewing] = useState(null);
  const [dateReported, setDateReported] = useState(null);
  const [shareColor, setShareColor] = useState("#9aa1af");
  const [replyColor, setReplyColor] = useState("#9aa1af");
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [replyModalSubmitted, setReplyModalSubmitted] = useState(false);
  const location = useLocation();
  const [currentURL, setCurrentURL] = useState("");
  const [shareURL, setShareURL] = useState("")
  const [copied, setCopied] = useState("copy");
  const [originalImageList, setOriginalImageList] = useState([]);
  const [originalVideoList, setOriginalVideoList] = useState([]);
  const [replyImageList, setReplyImageList] = useState([]);
  const [replyVideoList, setReplyVideoList] = useState([]);
  const [allRepliesWithThisUID, setAllRepliesWithThisUID] = useState(null);
  const [repliesFound, setRepliesFound] = useState(false);
  const [imageArrayViewing, setImageArrayViewing] = useState([]);
  const [indexViewing, setIndexViewing] = useState(0);
  const [showBigImage, setShowBigImage] = useState(false);
  const [singleReplyImageList, setSingleReplyImageList] = useState([]);
  const [singleReplyImageIndex, setSingleReplyImageIndex] = useState(0);
  const [singleReplyVideo, setSingleReplyVideo] = useState("");
  const [showReplyBigImage, setShowReplyBigImage] = useState(false);
  const [showReplyVideo, setShowReplyVideo] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sortedReplies, setSortedReplies] = useState(null);
  const [videoToView, setVideoToView] = useState("");
  const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
  const [neighborhoodName, setNeighborhoodName] = useState(null);
  const resetArray = [];
  const videoFormats = ["mp4", "mov", "wmv", "avi"];

  const imageNotFound =
    "https://firebasestorage.googleapis.com/v0/b/security-client-d3934.appspot.com/o/Heathwood%2Fimage-not-found.png?alt=media&token=dce34eb7-924d-471d-866e-f5d4c90fa527";

  //Setup to check if user dropped in from home screen or a link.
  //  If a link then it will set neighborhoodID and trigger fetch firestore
  useEffect(() => {
    console.log(neighborhoodID);
    if (neighborhoodID === null) {
      setNeighborhoodID(paramNeighborhoodID);
      console.log(paramNeighborhoodID);
    }
    setCurrentURL(window.location.href);
  }, []);

  //To load incident into incidentViewing var
  useEffect(() => {
    if (allIncidents !== null) {
      if (paramMatchId !== undefined) {
        console.log(paramMatchId);
        const incidentToView = allIncidents.find((c) => c.id === paramMatchId);
        setIncidentViewing({ ...incidentToView });
      }
    }
  }, [allIncidents]);

  useEffect(() => { 
    let pathURL = window.location.href
    let pathSplit = pathURL.split('/')
    // setCurrentURLBase(pathSplit[2])
    console.log(pathSplit)
    let reconstructedURL = null
    let recombineURLItems = []
    pathSplit.map((item, i) => {
      if (item !== "admin") {
        recombineURLItems.push(item)
      }
    })
    reconstructedURL = recombineURLItems.join('/')
    setShareURL(reconstructedURL)
    console.log(reconstructedURL)
  }, [])

  useEffect(() => {
    if (neighborhoodID !== null) {
      console.log(neighborhoods);
      neighborhoods.map((item) => {
        if (item.id === neighborhoodID) {
          setNeighborhoodName(item.name);
        }
      });
    }
  }, [neighborhoodID]);

  useEffect(() => {
    if (incidentViewing !== null) {
      setDateReported(
        format(incidentViewing.dateReported.toDate(), "MM/dd/yyyy 'at' h:m a")
      );

      //set original image urls into array
      if (incidentViewing.imageUrls !== null) {
        const extensionRegEx = new RegExp(/\.([0-9a-z]+)(?:[\?#]|$)/i);
        let tempImageList = [];
        let tempVideoList = [];

        incidentViewing.imageURLs.map((item, i) => {
          const theExtension = item.match(extensionRegEx);
          if (videoFormats.includes(theExtension[1])) {
            tempVideoList.push(item);
          } else {
            tempImageList.push(item);
          }
        });
        setOriginalImageList(tempImageList);
        setOriginalVideoList(tempVideoList);
      }
    }
  }, [incidentViewing]);

  useEffect(() => {
    //load up all replies that have the same ID as the incident viewing store in allRepliesWithThisUID
    if (allIncidentReplies !== null) {
      let tempArray = [];
      let tempRepliesFound = false;
      allIncidentReplies.map((item, i) => {
        if (item.incidentUID === paramMatchId) {
          tempArray.push(item);
          console.log(item);
          // setAllRepliesWithThisUID((allRepliesWithThisUID) => [...allRepliesWithThisUID,item]);
          tempRepliesFound = true;
        }
      });
      setRepliesFound(tempRepliesFound);
      console.log(tempArray);
      setAllRepliesWithThisUID(tempArray);
      setReplyModalSubmitted(false);
    }
  }, [allIncidentReplies]);

  //push any images that are in the replies with this UID into replyImageList to hold all reply images
  //  Since imageURLs was an array I had to map for images then map through the images in each UID then
  //  store the image URL.
  //  Sorts reply images/videos into their appropriate hooks
  useEffect(() => {
    if (allRepliesWithThisUID !== null) {
      let tempReplyImages = [];
      let tempReplyVideos = [];

      allRepliesWithThisUID.map((item, i) => {
        if (item.imageURLs !== undefined) {
          const extensionRegEx = new RegExp(/\.([0-9a-z]+)(?:[\?#]|$)/i);
          item.imageURLs.map((item, i) => {
            const theExtension = item.match(extensionRegEx);
            if (videoFormats.includes(theExtension[1])) {
              console.log("triggered");
              tempReplyVideos.push(item);
            } else {
              tempReplyImages.push(item);
            }
          });
        }
      });
      setReplyImageList(tempReplyImages);
      setReplyVideoList(tempReplyVideos);
      setReplyModalSubmitted(false);
    }
  }, [allRepliesWithThisUID]);
  //

  //remove after have replies area set.  just testing to see what replies to show if any.
  useEffect(() => {
    if (repliesFound) {
      console.log("There are replies found");
      console.log(allRepliesWithThisUID);
      sortRepliesAscending();
    }
  }, [repliesFound, allRepliesWithThisUID]);

  const shareClicked = () => {
    toast.success(
      `The following URL link was copied to your clipboard ${shareURL} `
    );
    navigator.clipboard.writeText(shareURL);
    setCopied("copied");
    console.log(shareURL)
  };

  useEffect(() => {
    console.log(originalImageList);
    console.log(replyImageList);
    console.log(allRepliesWithThisUID);
  }, [originalImageList, replyImageList, allRepliesWithThisUID]);

  //activate imageViewer when clicked on images and videos on side panel.
  const handleViewBigImage = (imageArray, index) => {
    console.log("viewbigimagehandler");
    setImageArrayViewing(imageArray);
    setIndexViewing(index);
    setShowBigImage(true);
    setIsViewerOpen(true);
  };

  //activate imageViewer when replycard wants to view big image of reply image
  useEffect(() => {
    if (showReplyBigImage) {
      setShowBigImage(true);
      setImageArrayViewing(singleReplyImageList);
      setIndexViewing(singleReplyImageIndex);
      setIsViewerOpen(true);
    }
  }, [showReplyBigImage]);

  //activate videoPlayer whe reply card wants to view a video in replies
  useEffect(() => {
    if (showReplyVideo) {
    }
  }, [showReplyVideo]);

  const sortRepliesAscending = () => {
    let tempArray = null;
    if (allRepliesWithThisUID !== null) {
      console.log("im in replies");
      tempArray = allRepliesWithThisUID.sort(
        (objA, objB) => Number(objA.dateReported) - Number(objB.dateReported)
      );
      setSortedReplies(tempArray);
    }
  };

  const handleVideoClicked = (item) => {
    console.log(item);
    // setVideoToView(item);
    // setOpenVideoPlayer(true);
    window.open(item, "_blank", "location=yes");
  };

  useEffect(() => {
    console.log(sortedReplies);
  }, [sortedReplies]);

  if (incidentViewing === null) {
    return (
      <div className="flex flex-col h-full justify-center items-center h-screen">
        <div className="stat-value mb-3">Loading...</div>
        <BarLoader loading="true" color="#3abff8" width={300} />
      </div>
    );
  }
  return (
    <div className="w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px]">
      <div class="flex flex-col h-full min-h-screen">
        <div className="basis-1/5 mt-5">
          <div className="flex items-end		">
            <Link to={`/admin`}>
              <div className="flex flex-row items-end">
                <div className="mr-5 ml-3">
                  <img src={logo1} alt="Logo Image" height={80} width={60} />
                </div>
                <div className="flex flex-row">
                  <div className="font-bold text-2xl text-[#0037a5]">
                    Nexus
                  </div>
                  <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="basis-1/5 mt-4 sm:mt-auto self-center flex flex-row">
          <div className="text-2xl text-base-100">
            {neighborhoodName !== null ? neighborhoodName : null}
          </div>
          <div className="text-2xl"> : Incident</div>
        </div>

        <div
          class={`basis-3/5 mb-auto hero-content self-center flex flex-col-reverse sm:flex-row w-full items-start ${
            showReplyModal ? " blur-sm" : ""
          }`}
        >
          <div className="w-full sm:w-3/4">
            <div>
              <div class="card w-full shadow-2xl bg-base-100 p-3">
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between pb-3 border-b-2 border-slate-500">
                    <div className="flex flex-row">
                      <div className="mr-2">{dateReported}</div>
                      <div className="">
                        Reported by {incidentViewing.reporterName}
                      </div>
                    </div>
                    <div className="flex flex-row items-center ">
                      <span className="mr-2">Requesting help: </span>
                      {incidentViewing.requestingHelp ? (
                        <div class="animate-pulse badge badge-info">Yes</div>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row pt-3">
                    <div className="flex flex-col w-full pr-2 ">
                      <div className="flex flex-col ">
                        <div className="flex flex-row">
                          <span className="stat-title mr-2 mb-2">
                            Incident Type:{" "}
                          </span>
                          {incidentViewing.eventType}
                        </div>
                        <div className="flex flex-row">
                          <span className="stat-title mr-2 mb-2">
                            Location Of Incident:{" "}
                          </span>
                          {incidentViewing.locationOfIncident}
                        </div>
                        <span className="stat-title">Description:</span>
                        <span>{incidentViewing.descriptionOfIncident}</span>
                      </div>

                      <div className="h-10 w-full"></div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between flex-wrap">
                    <div className="flex flex-row items-center">
                      <span className="stat-title">Notified:</span>
                      {incidentViewing.whoWasNotified.length !== 0
                        ? incidentViewing.whoWasNotified.map(function (
                            item,
                            i
                          ) {
                            return (
                              <div key={item + i}>
                                {item === "Police" ? (
                                  <div class="badge badge-success gap-2 m-2">
                                    <GiPoliceBadge />
                                    Police
                                  </div>
                                ) : null}
                                {item === "Security" ? (
                                  <div class="badge badge-success gap-2 m-2">
                                    <GiPoliceCar />
                                    Security
                                  </div>
                                ) : null}
                                {item === "Association" ? (
                                  <div class="badge badge-success gap-2 m-2">
                                    <HiUserGroup />
                                    Association
                                  </div>
                                ) : null}
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div className="flex place-items-center">
                      <div
                        class="tooltip rounded-full hover hover:bg-[#36d399]/25 place-items-center p-2 cursor-pointer"
                        onMouseEnter={() => setReplyColor("#36d399")}
                        onMouseLeave={() => setReplyColor("#9aa1af")}
                        data-tip="Reply With Information"
                        onClick={() => setShowReplyModal(true)}
                      >
                        <GoComment
                          style={{ fontSize: "32px", color: replyColor }}
                          className="p-1 "
                        />
                      </div>
                      <div
                        class="tooltip ml-2 rounded-full hover hover:bg-[#06b6d4]/25 place-items-center p-2 cursor-pointer"
                        onMouseEnter={() => setShareColor("#06b6d4")}
                        onMouseLeave={() => setShareColor("#9aa1af")}
                        data-tip="Share"
                        onClick={shareClicked}
                      >
                        <AiOutlineShareAlt
                          style={{ fontSize: "32px", color: shareColor }}
                          className="p-1 "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {repliesFound && sortedReplies
              ? sortedReplies.map(function (item, i) {
                  return (
                    <ReplyCard
                      className="hover:border-gray-700 border-1"
                      key={item.id}
                      item={item}
                      setSingleReplyImageList={setSingleReplyImageList}
                      setSingleReplyImageIndex={setSingleReplyImageIndex}
                      setShowReplyBigImage={setShowReplyBigImage}
                      setImageArrayViewing={setImageArrayViewing}
                      setVideoToView={setVideoToView}
                      setOpenVideoPlayer={setOpenVideoPlayer}
                    />
                  );
                })
              : null}
          </div>
          <div className="flex flex-col flex-wrap w-full sm:w-1/4 ">
            {incidentViewing !== null ? (
              <div class="flex flex-wrap w-full drop-shadow-2xl bg-base-100 rounded-md p-3">
                <div class="flex flex-col items-center w-full text-center">
                  <h2 class="text-slate-200 text-lg">Images and Video</h2>
                </div>
                {originalImageList.length === 0 &&
                replyImageList.length === 0 &&
                originalVideoList.length === 0 &&
                replyVideoList.length === 0 ? (
                  <div className="h-full mt-2">
                    <div className="flex flex-wrap items-center text-center">
                      <span>
                        No images or videos uploaded for this incident yet
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="h-full mt-2">
                    {originalImageList.length > 0 ||
                    originalVideoList.length > 0 ? (
                      <div className="text-slate-400">
                        Original Images And Videos{" "}
                      </div>
                    ) : null}
                    <div className="flex justify-start flex-wrap mt-1">
                      {originalImageList.length > 0
                        ? originalImageList.map(function (item, i) {
                            console.log(item);
                            return (
                              <diva
                                class="avatar mr-1 justify-self-center mt-1"
                                key={item + i}
                                onClick={() =>
                                  handleViewBigImage(originalImageList, i)
                                }
                              >
                                <div class="w-16 rounded hover:border border-info cursor-pointer">
                                  <img src={item} alt={imageNotFound} />
                                </div>
                              </diva>
                            );
                          })
                        : null}
                      {originalVideoList.length > 0
                        ? originalVideoList.map(function (item, i) {
                            console.log(item);
                            return (
                              <div
                                class="avatar mr-1 mt-1"
                                key={item + i}
                                onClick={() => handleVideoClicked(item)}
                              >
                                <div class="w-16 rounded hover:border border-info cursor-pointer bg-white">
                                  <div className="flex justify-center items-center self-auto">
                                    <GrDocumentVideo
                                      className="mt-3 mb-3"
                                      style={{ fontSize: "38px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <div className="mt-2">
                      {replyImageList.length > 0 ||
                      replyVideoList.length > 0 ? (
                        <div className="text-slate-400	">
                          Reply Images And Videos{" "}
                        </div>
                      ) : null}
                      <div className="flex justify-start flex-wrap mt-1">
                        {replyImageList !== null
                          ? replyImageList.map(function (item, i) {
                              console.log(item);
                              return (
                                <div
                                  class="avatar mr-1 justify-self-center mt-1"
                                  key={item + i}
                                  onClick={() =>
                                    handleViewBigImage(replyImageList, i)
                                  }
                                >
                                  <div class="w-16 rounded hover:border border-info cursor-pointer">
                                    <img src={item} alt={imageNotFound} />
                                  </div>
                                </div>
                              );
                            })
                          : null}
                        {replyVideoList !== null
                          ? replyVideoList.map(function (item, i) {
                              return (
                                <div
                                  class="avatar mr-1 mt-1"
                                  key={item + i}
                                  onClick={() => handleVideoClicked(item)}
                                >
                                  <div class="w-16 rounded hover:border border-info cursor-pointer bg-white">
                                    <div className="flex justify-center items-center self-auto">
                                      <GrDocumentVideo
                                        className="mt-3 mb-3"
                                        style={{ fontSize: "38px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        {showBigImage ? (
          <ViewBigImage
            imageArrayViewing={imageArrayViewing}
            indexViewing={indexViewing}
            setImageArrayViewing={setImageArrayViewing}
            setShowReplyBigImage={setShowReplyBigImage}
            isViewerOpen={isViewerOpen}
            setIsViewerOpen={setIsViewerOpen}
            setShowBigImage={setShowBigImage}
          />
        ) : null}
        {openVideoPlayer ? (
          <div class="flex flex-col " style={{ zIndex: 99 }}>
            <div
              className="ml-auto hover cursor-pointer"
              onClick={() => setOpenVideoPlayer(false)}
            >
              <span>X</span>
            </div>
            <div>
              <ReactPlayer
                className="react-player"
                url={videoToView}
                controls={true}
                playing={true}
                width={1280}
                height={720}
              />
            </div>
          </div>
        ) : null}
      </div>
      {showReplyModal ? (
        <ReplyModal
          setModalShow={setShowReplyModal}
          incidentViewing={incidentViewing}
          setReplyModalSubmitted={setReplyModalSubmitted}
        />
      ) : null}
    </div>
  );
}

export default IncidentDetails;
