import React from "react";
import { ClipLoader } from "react-spinners";

function Loader() {
  return(
    <ClipLoader
    color="#1fb2a6"
    size={100}
    speedMultiplier={1}
  />
)}

export default Loader;
