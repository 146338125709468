import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { FiCheckCircle } from "react-icons/fi";
import { IoMdCopy } from "react-icons/io";
import { toast } from "react-toastify";

function NewNeighborhoodInfoModal(props) {
  const {
    neighborhoodJustCreated,
    setNeighborhoodJustCreated,
    newNeighborhoodData,
  } = useContext(SecurityUserContext);

  const [neighborhoodURL, setNeighborhoodURL] = useState("");
  const [buttonHTML, setButtonHTML] = useState("");
  const [discordInviteButton, setDiscordInviteButton] = useState("");

  // useEffect(() => {
  //   if (props.newNeighborhoodInfo !== null) {
  //     setNewNeighborhoodInformation(props.newNeighborhoodInfo);
  //   }
  // }, []);

  useEffect(() => {
    if (newNeighborhoodData !== null) {
      //Modify with actual site URL later
      let tempurl = `https://nexusneighbor.com/${newNeighborhoodData.neighborhoodID}`;
      setNeighborhoodURL(tempurl);
      let tempHTML = `<a href=${tempurl}><button style="padding:6px 4px; width:150px; height:34px; background-image: linear-gradient(to right, #7c3aed, #4f46e5); color:white; border-radius:5px; border:none;">Nexus Neighbor</button></a>`;
      setButtonHTML(tempHTML);

      let tempDiscordInvite = `<a href=${newNeighborhoodData.discordInviteURL}><button style="padding:6px 4px; width:150px; height:34px; background-color:#5865f1; color:white; border-radius:5px; border:none;">Discord Invite</button></a>`;
      setDiscordInviteButton(tempDiscordInvite);
    }
  }, [newNeighborhoodData]);

  const handleCopyLink = () => {
    toast.success(
      `The following URL link was copied to your clipboard ${neighborhoodURL} `
    );
    navigator.clipboard.writeText(neighborhoodURL);
  };

  const handleCopyHTML = () => {
    toast.success(
      `The button HTML was copied, please paste it into your website`
    );
    navigator.clipboard.writeText(buttonHTML);
  };

  const handleCopyDiscordLink = () => {
    toast.success(
      `The following URL link was copied to your clipboard ${newNeighborhoodData.discordInviteURL}`
    );
    navigator.clipboard.writeText(newNeighborhoodData.discordInviteURL)
  };

  const handleDiscordButtonCopyHTML = () => {
    toast.success(
      `The Discord invite button was copied, please paste it into your website`
    );
    navigator.clipboard.writeText(discordInviteButton);
  };

  const handleCloseBtnPushed = () => {
    setNeighborhoodJustCreated(false);
  };

  return (
    <div class="hero min-h-full drop-shadow-xl">
      <div class=" py-16 px-20 flex-col lg:flex-row-reverse rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] max-w-5xl">
        <div className="flex flex-col px-4">
          <div className="flex flex-row mt-4 items-center	">
            <FiCheckCircle className="text-7xl text-[#16a34a] mr-4 drop-shadow" />
            <div className="font-bold text-4xl text-[#2a303c] text-center justify-self-center">
              Your new neighborhood was created
            </div>
          </div>
          <div className="divider"></div>
          <div className="text-2xl text-[#2a303c] text-center mb-4">
            About Your New Neighborhood
          </div>
          {newNeighborhoodData !== null ? (
            <div className="flex flex-col w-full p-2 mb-2">
              <div className="flex flex-row w-1/2 mb-2">
                <div className="text-lg">Neighborhood Name:</div>
                <div className="text-lg ml-10 text-[#2a303c]">
                  {newNeighborhoodData.name}
                </div>
              </div>
              <div className="mb-2 bg-white rounded rounded-md p-1">
                <div className="flex flex-col">
                  <div className="font-bold">
                    Below You'll find 2 important links
                  </div>
                  <div className="pl-2">
                    1. Neighborhood Link: Your users will use this to report, view,
                    or reply to incidents in the neighborhood.
                  </div>
                  <div className="pl-2">
                    2. Discord Link: Invites neighbors to get real-time
                    notifications of activity on their phone, PC or Mac.
                  </div>
                  <div className="1/2">
                    <div className="font-bold pt-1">
                      You have two options for each link
                    </div>
                    <div className="pl-2">
                      Option 1: Copy the URL and share the URL link with your
                      neighborhood
                    </div>
                    <div className="pl-2">
                      Option 2: Copy the button and past it into your website
                    </div>
                    <div className="text-sm mt-1">Note: you can access this information again inside your neighborhood settings.</div>
                  </div>
                </div>
              </div>

              <div className="mb-2 bg-white rounded rounded-md p-1">
                <div className="font-bold text-[#2a303c]">
                  Neighborhood Link
                </div>
                <div className="flex flex-row w-full mb-2">
                  <div className="text-lg">Neighborhood URL Link To Share:</div>
                  <div className="flex flex-row">
                  <div
                    onClick={handleCopyLink}
                    className="cursor-pointer text-lg ml-10 text-[#2a303c] py-1 px-2 rounded-lg border border-[#2a303c]"
                  >
                    {neighborhoodURL}
                  </div>
                  <div className="flex self-center ml-auto">
                    <IoMdCopy
                      className="text-[#2a303c] text-3xl cursor-pointer"
                      onClick={handleCopyLink}
                    />
                  </div>
                  </div>
                </div>

                <div className="flex flex-row w-full mt-4 justify-between">
                  <div className="text-lg 1/2">
                    Button HTML To Paste Into Your Site:
                  </div>
                  <div className="flex flex-row">
                    <div className="">
                      <button
                        className="btn btn-sm bg-gradient-to-r from-violet-600 to-indigo-600 text-white py-1 px-2 w-[200px] border-none"
                        onClick={handleCopyHTML}
                      >
                        Nexus Neighbor
                      </button>
                    </div>
                    <div className="pt-2">
                      <IoMdCopy
                        className="text-[#2a303c] text-3xl cursor-pointer"
                        onClick={handleCopyHTML}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white rounded rounded-md p-1">
                <div className="font-bold text-[#2a303c]">Discord Link</div>
                <div className="flex flex-row w-full mb-2 justify-between">
                  <div className="text-lg">Discord URL Link To Share:</div>
                  <div className="flex flex-row">
                    <div
                      onClick={handleCopyDiscordLink}
                      className="cursor-pointer text-lg ml-10 text-[#2a303c]  py-1 px-2 rounded-lg border border-[#2a303c]"
                    >
                      {newNeighborhoodData.discordInviteURL}
                    </div>
                    <div className="flex self-center ml-auto">
                      <IoMdCopy
                        className="text-[#2a303c] text-3xl cursor-pointer"
                        onClick={handleCopyDiscordLink}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full mt-4 justify-between">
                  <div className="text-lg 1/2">
                    Button HTML To Paste Into Your Site:
                  </div>
                  <div className="flex flex-row">
                    <div className="">
                      <button
                        className="btn btn-sm bg-[#5865f1] text-white py-1 px-2 border-none w-[200px]"
                        onClick={handleDiscordButtonCopyHTML}
                      >
                        Discord Invite
                      </button>
                    </div>
                    <div className="pt-2">
                      <IoMdCopy
                        className="text-[#2a303c] text-3xl cursor-pointer"
                        onClick={handleDiscordButtonCopyHTML}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="divider"></div>

          <div className="btn btn-wide self-end		" onClick={handleCloseBtnPushed}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNeighborhoodInfoModal;
