// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const { REACT_APP_FIRESTORE } = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIRESTORE,
  authDomain: "security-client-d3934.firebaseapp.com",
  projectId: "security-client-d3934",
  storageBucket: "security-client-d3934.appspot.com",
  messagingSenderId: "849589046328",
  appId: "1:849589046328:web:0ff972a2052bd6f8f92acd",
  measurementId: "G-HNTF5RQ112"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);