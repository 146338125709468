import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import axios from "axios";
import Loader from "../../../components/Loader";
import BarLoader from "react-spinners/BarLoader";

function AdminHomeSettings(props) {
  const {
    signedIn,
    setSignedIn,
    setSignout,
    companyName,
    createNewNeighborhood,
    neighborhoodBeingCreated,
    neighborhoodCreated,
    setNeighborhoodCreated,
    neighborhoods,
    allIncidents,
    allIncidentReplies,
    neighborhoodsLoaded,
    adminUserDetails,
    globalLoading,
    setGlobalLoading,
    newNeighborhoodData,
    neighborhoodJustCreated,
    setNeighborhoodJustCreated,
  } = useContext(SecurityUserContext);
  const [accountCreatedDate, setAccountCreatedDate] = useState("");
  const [stripeCustNum, setStripeCustNum] = useState("");

  useEffect(() => {
    if (adminUserDetails !== null) {
      setStripeCustNum(adminUserDetails.stripeCustId);
      console.log(adminUserDetails.stripeCustId);
    }
  }, [adminUserDetails]);

  const getCustStripeInfo = async () => {
    setGlobalLoading(true);
    await axios
      .get("/getStripeCustomerInfo", {
        params: { stripeCustNum: stripeCustNum },
      })
      .then((response) => {
        window.location.href = response.data;
      });
  };

  const handleEditNameClicked = () => {
    props.setShowEditNameModal(true)
  }

  return (
    <div className="">
      <div className="flex flex-col h-full">
        <div className="px-3 flex flex-col align-middle">
          <div className="mt-6 text-xl font-bold text-[#2a303c]">Settings</div>
          <div className="flex flex-col mt-6">
            <div className="text-lg font-bold">Account Information</div>
            {adminUserDetails !== null ? (
              <div className="p-3">
                <div className="flex flex-col mb-2">
                  <div className="flex flex-row justify-between">
                    <div>
                      <div className="text-[#2a303c]">
                        Neighborhood Or Company Name
                      </div>
                      <div>{adminUserDetails.name}</div>
                    </div>
                    <div
                    className="btn btn-sm w-50 px-4"
                    onClick={handleEditNameClicked}
                  >
                    Edit Name
                  </div>
                  </div>
                </div>
                <div className="flex flex-col mb-2">
                  <div className="text-[#2a303c]">Email</div>
                  <div>{adminUserDetails.email}</div>
                </div>
              </div>
            ) : null}
            <div className="divider"></div>
            <div className="text-lg font-bold ">Billing Information</div>
            {adminUserDetails !== null ? (
              <div className="p-3">
                <div className="flex flex-row justify-between">
                  <div className="text-[#2a303c]">Edit Billing Information</div>
                  {globalLoading ? (
                    <BarLoader
                      loading={`${globalLoading ? "true" : "false"}`}
                      color="#3abff8"
                      size={200}
                    />
                  ) : null}
                  <div
                    className="btn btn-sm w-50 px-4"
                    onClick={getCustStripeInfo}
                  >
                    Edit Information
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHomeSettings;
