import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../context/SecurityUserContext";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import logo1 from "../../assets/logo1.png";

export default function AcceptPayment() {
  const navigate = useNavigate();
  const { setStripePurchaseId } = useContext(SecurityUserContext);

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    console.log(sessionId);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("");
    }
  }, [sessionId]);

  const ProductDisplay = () => (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:h-full">
      <div className="mb-8 mt-3 ml-5 mr-5 sm:ml-[75px] sm:mr-[75px] flex flex-col sm:flex-row self-center sm:self-stretch sm:justify-between">
        <a href="https://nexusneighborapp.com" target="_blank">
          <div className="flex flex-row">
            <img src={logo1} alt="Logo Image" height={80} width={60} />
            <div className="flex flex-row content-end self-end ml-5">
              <div className="font-bold text-2xl text-[#0037a5]">Nexus</div>
              <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
            </div>
          </div>
        </a>
        <div className="flex sm:flex-row space-x-8 text-black h-10 place-self-end">
          <a href="https://nexusneighborapp.com">
            <div className="pt-5">Home</div>
          </a>
          <a href="https://nexusneighbor.com/admin/acceptpayment">
            <div className="pt-5">Pricing</div>
          </a>
          <a href="https://nexusneighborapp.com/contact-us">
            <div className="pt-5">Contact Us</div>
          </a>
        </div>
      </div>

      <div className="flex flex-col mx-2 sm:mx-2 justify-center items-center mb-4">
        <div className="flex flex-col mb-10">
          <div className="text-center text-[42px] text-[#0d111f] mb-4 font-bold">
            Purchase a subscription
          </div>
          <div className="text-center text-[#0d111f] text-lg">
            Choose the plan that works for your community
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 items-center rounded-md sm:h-[675px]">
          <div className="flex flex-col rounded-xl bg-[#0d111f] p-6 w-[350px] h-[675px] drop-shadow-xl">
            <div className="basis-2/5 sm:w-[300px] ">
              <div className="text-2xl text-accent mb-6 font-bold mt-4">
                Monthly
              </div>
              <div className="flex flex-row mb-3">
                <div className="text-4xl font-bold text-white">$19</div>
                <div className="ml-2 self-end">/ month</div>
              </div>
              <div className="text-white mb-6">Billed monthly</div>
              <div
                className="btn btn-accent w-full rounded-md"
                onClick={handleSubmitMonthly}
              >
                Subscribe
              </div>
              <div className="p-1 text-center text-sm"> cancel anytime</div>
            </div>
            <div className="basis-3/5  bg-[#0b0f1b] mt-10">
              <div className="flex flex-col sm:border-t-1">
                <div className="flex flex-row border-b border-slate-800 py-3">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Real-time Incident Notifications
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Mobile text notifications for admins
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Access To Historical Data Via Charts and Graphs
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Create Multiple Neighborhoods
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2 mb-5">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Automaticly Create Discord For Neighborhood
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-xl bg-[#0d111f] p-6 w-[350px] h-[675px] drop-shadow-xl">
            <div className="basis-2/5 sm:w-[300px] ">
              <div className="text-2xl text-primary mb-6 font-bold mt-4">
                Annual
              </div>
              <div className="flex flex-row mb-3">
                <div className="text-4xl font-bold text-white">$182</div>
                <div className="ml-2 self-end">/ year</div>
              </div>
              <div className="text-white mb-6">Billed yearly</div>
              <div
                className="btn btn-primary w-full rounded-md "
                onClick={handleSubmitYearly}
              >
                Subscribe
              </div>
              <div className="p-1 text-center text-sm"> cancel anytime</div>
            </div>
            <div className="basis-3/5  bg-[#0b0f1b] mt-10">
              <div className="flex flex-col sm:border-t-1">
                <div className="flex flex-row border-b border-slate-800 py-3">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">20% discount from monthly</div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-3">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Real-time Incident Notifications
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Mobile text notifications for admins
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Access To Historical Data Via Charts and Graphs
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Create Multiple Neighborhoods
                  </div>
                </div>
                <div className="flex flex-row border-b border-slate-800 py-2 mb-5">
                  <AiOutlineCheck className="text-[#106100] mr-2 self-center" />
                  <div className="text-white">
                    Automaticly Create Discord For Neighborhood
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // <div className="product">
  //       <div className="description">
  //         <h3>Starter plan</h3>
  //         <h5>$19.00 / month</h5>
  //       </div>
  //     </div>
  //     <form onSubmit={handleSubmit}>
  //       {/* Add a hidden field with the lookup_key of your Price */}
  //       <input
  //         type="hidden"
  //         name="lookup_key"
  //         value="price_1MTpMTAwMFJAdmthkSqGw2MT"
  //       />
  //       <button id="checkout-and-portal-button" type="submit">
  //         Checkout
  //       </button>
  //     </form>

  const handleSubmitMonthly = async (e) => {
    let stripeURL = "";
    const price = "price_1Mz1JfAwMFJAdmthn4l1umg5";
    e.preventDefault();
    await axios({
      method: "post",
      url: "/create-checkout-session",
      withCredentials: false,
      params: {
        price: price,
      },
    })
      .then((response) => {
        console.log(response.data);
        setStripePurchaseId(response.data.id);
        stripeURL = response.data.url;
      })
      .then(() => {
        window.location = stripeURL;
      });
  };

  const handleSubmitYearly = async (e) => {
    let stripeURL = "";
    const price = "price_1Mz1OfAwMFJAdmth1lRhfqM2";
    e.preventDefault();
    await axios({
      method: "post",
      url: "/create-checkout-session",
      withCredentials: false,
      params: {
        price: price,
      },
    })
      .then((response) => {
        console.log(response.data);
        setStripePurchaseId(response.data.id);
        stripeURL = response.data.url;
      })
      .then(() => {
        window.location = stripeURL;
      });
  };

  const SuccessDisplay = ({ sessionId }) => {
    return (
      <section>
        <div className="product Box-root">
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
      </section>
    );
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );

  // useEffect(() => {
  //   if (success) {
  //     axios
  //       .get("/get-stripe-session", { params: { sessionId: sessionId } })
  //       .then((response) => {
  //         console.log(response.data);
  //       });
  //   }
  // }, [success]);

  if (!success && message === "") {
    return <ProductDisplay />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
