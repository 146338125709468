import React, { useEffect, useState } from "react";
import { add, format } from "date-fns";
import { GiPoliceBadge, GiPoliceCar } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { GrDocumentVideo } from "react-icons/gr";

function ReplyCard(props) {
  const [dateReported, setDateReported] = useState(null);
  const [collapseState, setCollapseState] = useState("collapse-close");
  const [open, setOpen] = useState(false);
  const [allImgVid, setAllImgVid] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const videoFormats = ["mp4", "mov", "wmv", "avi"];
  const imageNotFound =
    "https://firebasestorage.googleapis.com/v0/b/security-client-d3934.appspot.com/o/Heathwood%2Fimage-not-found.png?alt=media&token=dce34eb7-924d-471d-866e-f5d4c90fa527";

  useEffect(() => {
    if (open) {
      setCollapseState("collapse-open");
    } else {
      setCollapseState("collapse-close");
    }
  }, [open]);

  const handleClicked = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (props.item.imageURLs !== null) {
      setAllImgVid(props.item.imageURLs);
    }
  }, []);

  useEffect(() => {
    console.log(images);
  }, [images]);

  useEffect(() => {
    if (allImgVid !== null) {
      let tempImages = [];
      let tempVideos = [];
      const extensionRegEx = new RegExp(/\.([0-9a-z]+)(?:[\?#]|$)/i);
      allImgVid.map((item, i) => {
        const theExtension = item.match(extensionRegEx);
        if (videoFormats.includes(theExtension[1])) {
          console.log("triggered");
          tempVideos.push(item);
        } else {
          tempImages.push(item);
        }
      });
      setImages(tempImages);
      setVideos(tempVideos);
    }
  }, [allImgVid]);

  useEffect(() => {
    if (props.item.dateReported !== null) {
      setDateReported(
        format(props.item.dateReported.toDate(), "MM/dd/yyyy 'at' h:mm a")
      );
    }
  }, []);

  const handleImageClicked = (imageArray, index) => {
    props.setSingleReplyImageList(imageArray);
    props.setSingleReplyImageIndex(index);
    props.setShowReplyBigImage(true);
  };

  const handleVideoClicked = (item) => {
    // props.setVideoToView(item);
    // props.setOpenVideoPlayer(true);
    window.open(item, '_blank', 'location=yes')
  };

  return (
    <div
      tabindex="0"
      class={`${collapseState} collapse-arrow hover:border-gray-700 border  ${props.item.reporterName === "Admin" ? "bg-primary border-none" : "bg-base-100 border-base-300"}  rounded-box my-1`}
    >
      <div class="collapse-title stat-title" onClick={handleClicked}>
        <div className="flex flex-row">
          <span>Reply </span>
          <span className="mr-2 ml-2">{dateReported}</span>
          {props.item.reporterName !== "" ? (
            <div className="flex flex-row">
              <span className="mr-2">by</span>
              <span>{props.item.reporterName}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div class="flex flex-col collapse-content">
        <div className="flex flex-row">
          <div
            className={`w-3/4 pr-2 ${
              props.item.whoWasNotified.length !== 0
                ? "border-r-2 border-slate-500"
                : ""
            }`}
          >
            <span>{props.item.descriptionOfIncident}</span>
            <div className="flex flex-row">
              {images !== null ? (
                <div className="flex mr-1 mt-1">
                  {images.map(function (item, i) {
                    return (
                      <div
                        class="avatar mr-1"
                        key={item + i}
                        onClick={() =>
                          handleImageClicked(props.item.imageURLs, i)
                        }
                      >
                        <div class="w-16 rounded hover:border border-slate-500 cursor-pointer">
                          <img src={item} alt={imageNotFound} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {videos !== null
                ? videos.map(function (item, i) {
                    return (
                      <div
                        class="avatar mr-1 mt-1"
                        key={item + i}
                        onClick={() => handleVideoClicked(item)}
                      >
                        <div class="w-16 rounded hover:border border-info cursor-pointer bg-white">
                          <div className="flex justify-center items-center self-auto">
                            <GrDocumentVideo
                              className="mt-3 mb-3"
                              style={{ fontSize: "38px" }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          {props.item.whoWasNotified.length !== 0 ? (
            <div className="pl-2">
              <span>Additional Notified Parties</span>
              {props.item.whoWasNotified.length !== 0
                ? props.item.whoWasNotified.map(function (item, i) {
                    return (
                      <div key={item + i}>
                        {item === "Police" ? (
                          <div class="badge badge-success gap-2 m-2">
                            <GiPoliceBadge />
                            Police
                          </div>
                        ) : null}
                        {item === "Security" ? (
                          <div class="badge badge-success gap-2 m-2">
                            <GiPoliceCar />
                            Security
                          </div>
                        ) : null}
                        {item === "Association" ? (
                          <div class="badge badge-success gap-2 m-2">
                            <HiUserGroup />
                            Association
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ReplyCard;
