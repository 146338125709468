import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import IncidentsContext from "../context/IncidentsContext";
import logo1 from "../assets/logo1.png";

function Home() {
  const { nid: paramNeighborhoodID } = useParams();
  const { setNeighborhoodID, neighborhoodID, neighborhoodName } =
    useContext(IncidentsContext);
  let navigate = useNavigate();
  const [hoodName, setHoodName] = useState(null);

  const gotoReportIncident = () => {
    let path = `/${paramNeighborhoodID}/reportincident`;
    navigate(path);
  };

  const gotoIncidents = () => {
    let path = `/${paramNeighborhoodID}/incidents`;
    navigate(path);
  };

  useEffect(() => {
    if (paramNeighborhoodID !== undefined)
      setNeighborhoodID(paramNeighborhoodID);
    console.log(paramNeighborhoodID);
    console.log("Testing deploy")
  }, []);

  useEffect(() => {
    if (neighborhoodName !== null) {
      setHoodName(neighborhoodName);
      console.log(neighborhoodName);
    }
  }, [neighborhoodName]);

  return (
    <div className="w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px]">
      <div className="flex flex-col h-full min-h-screen">
        <div className="basis-1/4 mt-5">
          <div className="flex items-end		">
            <Link to={`/${paramNeighborhoodID}`}>
              <div className="flex flex-row items-end">
                <div className="mr-5 ml-3">
                  <img src={logo1} alt="Logo Image" height={80} width={60} />
                </div>
                <div className="flex flex-row">
                  <div className="font-bold text-2xl text-[#0037a5]">
                    Nexus
                  </div>
                  <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="basis-3/4 justify-self-center sm:mt-auto sm:mb-auto sm:mx-auto mx-2 mt-4">
          <div className="flex flex-col	py-6 sm:p-20 h-3/5 card bg-[#f8fafb] justify-center drop-shadow-xl">
            <div className="flex flex-col ml-auto mr-auto mb-20">
              <h1 className="text-4xl font-bold text-center">Real-Time</h1>
              <h1 className="text-4xl font-bold text-center">
                Neighborhood Notification System
              </h1>
              {neighborhoodName !== null ? (
                <h2 className="ml-auto mr-auto mt-3 mb-3 text-3xl text-black sm:text-base-100">
                  {hoodName}
                </h2>
              ) : null}
            </div>
            <div className="flex flex-col sm:flex-row sm:ml-auto sm:mr-auto">
              <div className="flex flex-col p-1 sm:p-3 sm:mr-4">
                <button className="btn btn-primary" onClick={gotoIncidents}>
                  Find Existing Incidents
                </button>
              </div>
              <div className="flex flex-col p-1 sm:p-3">
                <button className="btn btn-accent" onClick={gotoReportIncident}>
                  Submit A New Incident
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
