import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import logo1 from "../../assets/logo1.png"

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const onChange = (e) => {
    setEmail(e.target.value);
    console.log(email)
  };

  const onSubmit = async(e) => {
    e.preventDefault();
    try {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
      toast.success('Email was sent')
      console.log("submit submitted...")
      navigate(-1);
    } catch (error) {
      toast.error('Could not send reset email')
    }
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="flex w-full mx-2 sm:w-[500px] sm:h-[275px] shadow-2xl bg-base-100 items-center rounded-lg	">
        <div className="card-body ">
        <div className="flex justify-center">
        <img src={logo1} alt="Logo Image" height={50} width={30} />
        </div>  
        <form onSubmit={onSubmit}>
            <div className="form-control">
              <label className="label">
                <p className="label-text text-center text-lg">
                  Enter the email address to send a reset password link
                </p>
              </label>
              <input
                type="email"
                placeholder="Email Address"
                class="input input-bordered input-primary w-full "
                value={email}
                onChange={onChange}
              />
            </div>
            
              <div className="form-control mt-6">
                <button className="btn btn-primary" type="submit">Reset Password</button>
              </div>
            
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;