import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, addDoc, Timestamp, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase.config"
import { storage } from "../../firebase.storage";
import Loader from "./Loader";
import IncidentsContext from "../../context/IncidentsContext";
import Discord from "./Discord";
import SecurityUserContext from "../../context/SecurityUserContext";
import axios from "axios"

function ReplyModal(props) {
  const { nid: paramNeighborhoodID } = useParams();
  const {
    setRefreshData,
    setNewDataReceived,
    getAllIncidents,
    neighborhoodID,
  } = useContext(IncidentsContext);
  const { neighborhoods, setDiscordWebhookURL, adminUserDetails, neighborhoodIds } = useContext(SecurityUserContext);
  const [modalShow, setModalShow] = useState(true);
  const todaysDate = new Date().toLocaleString();
  const [reporterName, setReporterName] = useState("Admin");
  const [descriptionOfIncident, setDescriptionOfIncident] = useState("");
  const [checkedPolice, setCheckedPolice] = useState(false);
  const [checkedSecurity, setCheckedSecurity] = useState(false);
  const [checkedAssociation, setCheckedAssociation] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [allNotifiedParties, setAllNotifiedParties] = useState([]);
  const initialState = [];
  const [imageURLs, setImageURLs] = useState([]);
  const [submitBtnPushed, setSubmitBtnPushed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { send } = Discord();
  const [neighborhoodName, setNeighborhoodName] = useState("")
  const [currentURLBase, setCurrentURLBase] = useState("")

  const handleFileBtnClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files;
    console.log(fileUploaded);
    setFileSelected([...fileUploaded]);
  };

  useEffect(() => {
    if (neighborhoods !== null) {
      console.log(neighborhoods)
      neighborhoods.map((item, i) => {
        if (item.id === paramNeighborhoodID) {
          setNeighborhoodName(item.name)
        }
      })
    }
  }, [neighborhoods])

  const handleCheckChange = (e) => {
    console.log(e.target.value, e.target.checked);
    if (e.target.checked) {
      setAllNotifiedParties([...allNotifiedParties, e.target.value]);
    } else {
      setAllNotifiedParties(
        allNotifiedParties.filter((item) => item !== e.target.value)
      );
    }
  };

  useEffect(() => {
    console.log(props.incidentViewing.id);
  }, [modalShow]);

  useEffect(() => {
    neighborhoods.map((data) => {
      if (data.id === paramNeighborhoodID) {
        if(data.webhookURL) {
          setDiscordWebhookURL(data.webhookURL)
          console.log(data.webhookURL)
        }
      }
    });
  }, [])

  //get url base to insert as var into posts
  useEffect(() => {
    let pathURL = window.location.href
    let pathSplit = pathURL.split('/')
    setCurrentURLBase(pathSplit[2])
    console.log(pathSplit)
  }, [])

  const submitButtonClicked = (e) => {
    //getting the webhookURL for this specific neighborhood to use in send to discord

    console.log("submit button clicked");
    setShowLoader(true);
    e.preventDefault();
    setSubmitBtnPushed(true);
    uploadImages();

  };

  //Sending incident to firestore then getting firestoreID and creating a discord post with webhook about incident.
  const sendingReplyFirebaseDiscord = async () => {
    try {
      const fireStoreID = await sendReplyToFirebase();
      console.log(fireStoreID);
      sendByDiscordWebhook();
      sendIncidentSMS()
    } catch (err) {
      console.log(err);
    }
  };

  const sendIncidentSMS = async () => {
    let incidentUID = props.incidentViewing.id
    let message = "There is a new REPLY to an incident reported in " + neighborhoodName + "\n" 
    let incidentURL = `http://${currentURLBase}/${paramNeighborhoodID}/incidentdetails/${incidentUID}`
    let eventTypeMessage = 'The incident is a ' + props.incidentViewing.eventType + " \n"
    let smsContactNumbers = []
    if (adminUserDetails.phoneNumbers !== undefined) {
      smsContactNumbers = adminUserDetails.phoneNumbers
      console.log(smsContactNumbers)
    } 
    await axios
    .get("/sendIncidentSMS", {
      params: {message: message, eventTypeMessage: eventTypeMessage, incidentURL: incidentURL, smsContactNumbers: smsContactNumbers},
    })
    .then((response) => {
      console.log(response.data)
    })
  }

  const sendByDiscordWebhook = () => {
    const discordDataObject = {
      url: window.location.href,
      content: "Admin Reply To Incident",
      authorName: "Reply Link",
      title: props.incidentViewing.eventType,
      descriptionOfIncident: descriptionOfIncident,
      footer: false,
    };
    console.log(discordDataObject.url)
    send(discordDataObject);
  };

  const sendReplyToFirebase = () => {
    return new Promise ((resolve) => {
      const docData = {
        incidentUID: props.incidentViewing.id,
        dateReported: Timestamp.now(),
        reporterName: reporterName,
        descriptionOfIncident: descriptionOfIncident,
        whoWasNotified: allNotifiedParties,
        imageURLs: imageURLs,
        isReply:true,
      };
      addDoc(
        collection(db, "neighborhoods", paramNeighborhoodID, "incidentReplies"),
        docData
      ).then((res) => {
        setShowLoader(false);
        props.setModalShow(false);
        // props.setReplyModalSubmitted(true);
        setNewDataReceived(true);
        props.setReplyModalSubmitted(true);
        resolve(res.id)
      });
    })
  };

  const uploadImages = async () => {
    if (fileSelected === null) {
      return;
    } else {
      const date = Date.now();

      await fileSelected.forEach((file, index) => {
        const storageRef = ref(storage, `${neighborhoodID}/${file.name}`);
        uploadBytes(storageRef, file).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            console.log(url);
            setImageURLs((imageURLs) => [...imageURLs, url]);
          });
        });
      });
    }
  };

  //sends reply with image to firestore
  useEffect(() => {
    if (imageURLs && fileSelected !== null) {
      if (imageURLs.length === fileSelected.length && submitBtnPushed) {
        sendingReplyFirebaseDiscord();
        console.log("sending to firebase now");
      }
    }
  }, [imageURLs]);

  //sends reply without image to firestore
  useEffect(() => {
    if (fileSelected === null && submitBtnPushed) {
      sendingReplyFirebaseDiscord();
      console.log("sending to firebase with no images");
      resetVariables();
    }
  }, [submitBtnPushed]);

  const resetVariables = () => {
    setReporterName("");
    setCheckedPolice(false);
    setCheckedSecurity(false);
    setCheckedAssociation(false);
    setDescriptionOfIncident("");
    setAllNotifiedParties(initialState);
    setFileSelected(initialState);
  };

  return (
    <div class="flex fixed inset-0 justify-center	">
      <div
        className={`hero-content flex-col lg:flex-row-reverse ${
          showLoader ? " blur-sm" : ""
        }`}
        data-tip=""
      >
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-300 border-2 border-info">
          <div className="flex flex-col p-5">
            <h1 className="text-center text-4xl mb-4">
              Reply To This Incident
            </h1>
            <div class="flex flex-row">
              <div className="flex flex-col w-full">
                <div className="form-control mb-4">
                  <label className="label">
                    <span class="label-text">Your Name</span>
                  </label>
                  <input
                    value={reporterName}
                    onChange={(e) => setReporterName(e.target.value)}
                    type="text"
                    placeholder="Name"
                    disabled
                    class="input input-border w-full"
                  />
                </div>
                <div className="bg-white sm:bg-[#2a303c] rounded-md p-2">
                  <span>Who was notified</span>
                  <div class="form-control pl-2">
                    <label className="cursor-pointer label">
                      <span className="label-text">Police</span>
                      <input
                        type="checkbox"
                        onChange={handleCheckChange}
                        className="checkbox checkbox-info"
                        value="Police"
                      />
                    </label>
                  </div>
                  <div class="form-control pl-2">
                    <label class="cursor-pointer label">
                      <span class="label-text">Private Security</span>
                      <input
                        type="checkbox"
                        onChange={handleCheckChange}
                        className="checkbox checkbox-info"
                        value="Security"
                      />
                    </label>
                  </div>
                  <div className="form-control pl-2">
                    <label class="cursor-pointer label">
                      <span class="label-text">Home Association</span>
                      <input
                        type="checkbox"
                        onChange={handleCheckChange}
                        className="checkbox checkbox-info"
                        value="Association"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col ">
              <div class="form-control mb-4">
                <label class="label">
                  <span class="label-text">
                    Description you would like to add
                  </span>
                </label>
                <textarea
                  class="textarea "
                  placeholder="Description"
                  value={descriptionOfIncident}
                  onChange={(e) => setDescriptionOfIncident(e.target.value)}
                ></textarea>
              </div>

              <div className="form-control mb-2">
                <label>
                  <span>
                    Choose any videos or pictures you have of the incident
                  </span>
                </label>
                <button class="btn" onClick={handleFileBtnClick}>
                  Upload Files
                </button>
                <input
                  type="file"
                  className=""
                  ref={hiddenFileInput}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                />
                {fileSelected !== null
                  ? fileSelected.map(function (item, i) {
                      return <div key={item.name + i}>{item.name}</div>;
                    })
                  : null}
              </div>
              <div class="divider mt-0 mb-0"></div>
              <h1 className="mt-5">Date of Reply: {todaysDate}</h1>
            </div>
            <div className="flex flex-row justify-between">
              <div class="form-control mt-6">
                <button class="btn" onClick={() => props.setModalShow(false)}>
                  Cancel
                </button>
              </div>
              <div class="form-control mt-6">
                <button class="btn btn-info" onClick={submitButtonClicked}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLoader ? (
        <div className="flex fixed inset-0 items-center	justify-center">
          <div className="flex flex-col items-center text-[#ffffff]">
            <div className="text-4xl font-bold">Uploading Items Now</div>
            <div className="text-2xl">Please wait</div>
            <div className="text-xsm mb-4">
              Larger files will take longer to upload
            </div>
            <Loader />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ReplyModal;
