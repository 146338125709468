import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IncidentsContext from "../../context/IncidentsContext";
import { ImInfo } from "react-icons/im";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { add, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { doc, addDoc, Timestamp, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase.config";
import { storage } from "../../firebase.storage";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Discord from "../components/Discord";
import SecurityUserContext from "../../context/SecurityUserContext";
import axios from "axios";
import { BsArrowLeftSquareFill } from "react-icons/bs";

function ReportIncidentMain() {
  const navigate = useNavigate();

  const {
    setRefreshData,
    setNewDataReceived,
    getAllIncidents,
    neighborhoodID,
    discordWebhookURL,
  } = useContext(IncidentsContext);

  const { neighborhoods, adminUserDetails } = useContext(SecurityUserContext);
  const todaysDate = new Date().toLocaleString();
  const [dateWasSelected, setDateWasSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [incidentDate, setIncidentDate] = useState(null);
  const [incidentDateText, setIncidentDateText] = useState("");
  const [reporterName, setReporterName] = useState("");
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [descriptionOfIncident, setDescriptionOfIncident] = useState("");
  const [eventType, setEventType] = useState("");
  const [pOneImage, setPOneImage] = useState(null);
  const [pTwoImage, setPTwoImage] = useState(null);
  const [checkedPolice, setCheckedPolice] = useState(false);
  const [checkedSecurity, setCheckedSecurity] = useState(false);
  const [checkedAssociation, setCheckedAssociation] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [helpFromOthers, setHelpFromOthers] = useState(false);
  const [allNotifiedParties, setAllNotifiedParties] = useState([]);
  const initialState = [];
  const [imageURLs, setImageURLs] = useState([]);
  const [submitBtnPushed, setSubmitBtnPushed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { send } = Discord();
  const [neighborhoodSelectedID, setNeighborhoodSelectedID] = useState(null);
  const [isNeighborhoodSelected, setIsNeighborhoodSelected] = useState(false);
  const [showError, setShowError] = useState(false);
  const [neighborhoodPropId, setNeighborhoodPropId] = useState(null);
  const [neighborhoodPropName, setNeighborhoodPropName] = useState(null);
  const [currentURLBase, setCurrentURLBase] = useState("")


  //this will be obtained by selector menu that allows admin to select which neighborhood to post to.
  useEffect(() => {
    if (neighborhoods.length === 1) {
      setNeighborhoodSelectedID(neighborhoods[0].id);
    }
    
  }, []);

  //test effect
  useEffect(() => {
    if (neighborhoodPropName !== null) {
      console.log(neighborhoodPropName);
    }
  }, [neighborhoodPropName]);

  const handleFileBtnClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files;
    console.log(fileUploaded);
    setFileSelected([...fileUploaded]);
  };

  const handleSelectNeighborhoodChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "DEFAULT") {
      setIsNeighborhoodSelected(false);
    } else {
      setNeighborhoodSelectedID(e.target.value);
      setIsNeighborhoodSelected(true);
      setShowError(false);
      console.log(e.target.value);
    }
  };

  const handleSelectEventChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "DEFAULT") {
      setEventType("Other");
    } else {
      setEventType(e.target.value);
    }
  };

  const handleCheckChange = (e) => {
    console.log(e.target.value, e.target.checked);
    if (e.target.checked) {
      setAllNotifiedParties([...allNotifiedParties, e.target.value]);
    } else {
      setAllNotifiedParties(
        allNotifiedParties.filter((item) => item !== e.target.value)
      );
    }
  };

  useEffect(() => {
    if (neighborhoods.length >= 1) {
      console.log(neighborhoods);
    }
    console.log(allNotifiedParties);
  }, [allNotifiedParties, neighborhoods]);

  const handleCalendarClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleCalendarChange = (e) => {
    setIsOpen(!isOpen);
    setIncidentDate(e);
  };

  //get url base to insert as var into posts
  useEffect(() => {
    let pathURL = window.location.href
    let pathSplit = pathURL.split('/')
    setCurrentURLBase(pathSplit[2])
    console.log(pathSplit)
  }, [])

  useEffect(() => {
    if (incidentDate !== null) {
      setDateWasSelected(true);
      setIncidentDateText(format(incidentDate, "MM/dd/yyyy"));
    }
  }, [incidentDate]);

  const submitClicked = async (e) => {
    if (
      isNeighborhoodSelected === false &&
      neighborhoods.length > 1 &&
      neighborhoodPropId === null
    ) {
      setShowError(true);
      e.preventDefault();
      toast.error("A Neighborhood needs to be selected", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      //show error toast and highlight select neighborhood
    }
    if (
      isNeighborhoodSelected ||
      neighborhoods.length === 1 ||
      neighborhoodPropId !== null
    ) {
      e.preventDefault();
      setShowLoader(true);
      setSubmitBtnPushed(true);
      uploadImages();
    }
  };

  //Sending incident to firestore then getting firestoreID and creating a discord post with webhook about incident.
  const sendingIncidentFirebaseDiscord = async () => {
    try {
      const fireStoreID = await sendIncidentToFirebase();
      console.log(fireStoreID);
      sendByDiscordWebhook(fireStoreID);
      sendIncidentSMS(fireStoreID);
    } catch (err) {
      console.log(err);
    }
  };

  const sendIncidentSMS = async (fireStoreID) => {
    let message =
      "There was a new INCIDENT reported in " + neighborhoodPropName + "  \n";
      let incidentURL = `http://${currentURLBase}/${neighborhoodSelectedID}/incidentdetails/${fireStoreID}`
      let eventTypeMessage = "The incident is a " + eventType + " \n";
    let smsContactNumbers = [];
    if (adminUserDetails.phoneNumbers !== undefined) {
      smsContactNumbers = adminUserDetails.phoneNumbers;
      console.log(smsContactNumbers);
    }
    await axios
      .get("/sendIncidentSMS", {
        params: {
          message: message,
          eventTypeMessage: eventTypeMessage,
          incidentURL: incidentURL,
          smsContactNumbers: smsContactNumbers,
        },
      })
      .then((response) => {
        console.log(response.data);
      });
  };

  const sendByDiscordWebhook = (fireStoreID) => {
    console.log("triggered at 131");
    const discordDataObject = {
      url: `https://nexusneighbor.com/${neighborhoodSelectedID}/incidentdetails/${fireStoreID}`,
      authorName: "Incident Link",
      content: "New Incident",
      title: eventType,
      descriptionOfIncident: descriptionOfIncident,
      footer: helpFromOthers,
    };
    send(discordDataObject);
  };

  const sendIncidentToFirebase = () => {
    return new Promise((resolve) => {
      let tempDate = null;
      if (incidentDate === null) {
        tempDate = Timestamp.now();
      }
      if (incidentDate !== null) {
        tempDate = incidentDate;
      }

      const docData = {
        dateReported: Timestamp.now(),
        reporterName: "Admin",
        locationOfIncident: locationOfIncident,
        descriptionOfIncident: descriptionOfIncident,
        requestingHelp: helpFromOthers,
        dateOfIncident: tempDate,
        whoWasNotified: allNotifiedParties,
        imageURLs: imageURLs,
        eventType: eventType,
      };
      addDoc(
        collection(db, "neighborhoods", neighborhoodSelectedID, "incidents"),
        docData
      ).then((res) => {
        console.log(res.id);
        setShowLoader(false);
        toast.success(`Your incident report was received!`);
        navigate("/admin");
        resolve(res.id);
      });
    });
  };

  const uploadImages = async () => {
    if (fileSelected === null) {
      return;
    } else {
      const date = Date.now();

      await fileSelected.forEach((file, index) => {
        const storageRef = ref(
          storage,
          `${neighborhoodSelectedID}/${file.name}`
        );
        // const metadata = {
        //   contentType: file.type
        // }
        uploadBytes(storageRef, file).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setImageURLs((imageURLs) => [...imageURLs, url]);
            })
            // .then(() => {
            //   getAllIncidents();
            // });
        });
      });
    }
  };

  //sends reply with image to firestore
  useEffect(() => {
    if (imageURLs && fileSelected !== null) {
      if (imageURLs.length === fileSelected.length && submitBtnPushed) {
        // sendIncidentToFirebase();
        sendingIncidentFirebaseDiscord();
        console.log("sending to firebase now");
        resetVariables();
      }
    }
  }, [imageURLs]);

  //submits incident without image
  useEffect(() => {
    if (fileSelected === null && submitBtnPushed) {
      // sendIncidentToFirebase();
      sendingIncidentFirebaseDiscord();
      console.log("sending to firebase with no images");
      resetVariables();
    }
  }, [submitBtnPushed]);

  const resetVariables = () => {
    setReporterName("");
    setHelpFromOthers(false);
    setCheckedPolice(false);
    setCheckedSecurity(false);
    setCheckedAssociation(false);
    setLocationOfIncident("");
    setDescriptionOfIncident("");
    setAllNotifiedParties(initialState);
  };

  if (neighborhoods.length >= 1) {
    return (
      <div className="flex min-h-screen justify-center items-center bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div class="pt-2 sm:pt-0 mx-2 xsm:mx-5 mb-2 sm:mb-0 ">
          <div class={`flex flex-col ${showLoader ? " blur-sm" : ""}`}>
            <div className="flex flex-row w-fit ml-1 pt-2 mb-3 btn btn-sm bg-transparent hover:bg-transparent border-none"
            onClick={()=> navigate(-1)}>
              <div className="flex flex-row mb-0 items-end">
                <div className=" pl-0 mr-1	">
                  <BsArrowLeftSquareFill className="text-[30px] text-[#2a303c]" />
                </div>
                <div className=" pt-2 text-[#2a303c]">Back</div>
              </div>
            </div>
            <div class="card w-full shadow-2xl bg-[#ffffff] ">
              <h1 className="text-center text-4xl mb-4 mt-4 text-red-900">
                Admin
              </h1>
              <h1 className="text-center text-4xl text-[#2a303c]">
                Report A New Incident
              </h1>
              <form onSubmit={submitClicked}>
                <div className="grid grid-rows-1 sm:grid-rows-5 sm:px-20">
                  <div className="row-span-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 p-5 ">
                      <div className="col-span-1 w-full">
                        <div className="form-control mb-4 ">
                          {neighborhoods.length > 1 &&
                          neighborhoodPropId === null ? (
                            <select
                              className={`select w-full bg-neutral-content/25 text-[#2a303c] ${
                                showError ? "select-error" : null
                              }`}
                              onChange={handleSelectNeighborhoodChange}
                            >
                              <option value="DEFAULT" className="bg-neutral-content/25 text-[#2a303c]" disabled selected>
                                Select Neighborhood
                              </option>
                              {neighborhoods.map((item, i) => {
                                return (
                                  <option value={item.id} className="bg-neutral-content/25 text-[#2a303c]">{item.name}</option>
                                );
                              })}
                            </select>
                          ) : (
                            <div>
                              <label class="label">
                                <div className="flex flex-row">
                                  <span class="label-text">Neighborhood</span>
                                </div>
                              </label>
                              <input
                                type="text"
                                disabled
                                placeholder={
                                  neighborhoodPropName !== null
                                    ? neighborhoodPropName
                                    : neighborhoods[0].name
                                }
                                className="flex input w-full text-[#2a303c] bg-neutral-content/25"
                              />
                            </div>
                          )}
                        </div>

                        <div className="form-control mb-4">
                          <select
                            class="select  w-full bg-neutral-content/25 text-[#2a303c]"
                            onChange={handleSelectEventChange}
                          >
                            <option value="DEFAULT" disabled selected>
                              Type of incident
                            </option>
                            <option value="Theft">Theft</option>
                            <option value="Burglary">Burglary</option>
                            <option value="Suspicious Behavior">
                              Suspicious Behavior
                            </option>
                            <option value="Robbery">Robbery</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div className="bg-neutral-content/25 text-[#2a303c] rounded-md p-2">
                          <span>Who was notified</span>
                          <div class="form-control pl-2 ">
                            <label className="cursor-pointer label">
                              <span className="label-text text-[#2a303c]">Police</span>
                              <input
                                type="checkbox"
                                onChange={handleCheckChange}
                                className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                                value="Police"
                              />
                            </label>
                          </div>
                          <div class="form-control pl-2">
                            <label class="cursor-pointer label">
                              <span class="label-text text-[#2a303c]">Private Security</span>
                              <input
                                type="checkbox"
                                onChange={handleCheckChange}
                                className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                                value="Security"
                              />
                            </label>
                          </div>
                          <div className="form-control pl-2">
                            <label class="cursor-pointer label">
                              <span class="label-text text-[#2a303c]">Home Association</span>
                              <input
                                type="checkbox"
                                onChange={handleCheckChange}
                                className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                                value="Association"
                              />
                            </label>
                          </div>
                        </div>
                        <div class="form-control mb-2">
                          <label class="label">
                            <div className="flex flex-row">
                              <span class="label-text">
                                Location of Incident
                              </span>
                              <div
                                className="tooltip ml-2"
                                data-tip="Location closest to incident - street address or cross streets"
                              >
                                <ImInfo />
                              </div>
                            </div>
                          </label>
                          <input
                            type="text"
                            placeholder="Location"
                            value={locationOfIncident}
                            onChange={(e) =>
                              setLocationOfIncident(e.target.value)
                            }
                            className="flex input w-full bg-neutral-content/25 text-[#2a303c]"
                          />
                        </div>
                      </div>

                      <div className="col-span-1 w-full place-content-between">
                        <div class=" form-control mb-4">
                          <label class="label p-0">
                            <div className="flex flex-col w-full">
                              <span class="label-text py-2 pl-1">
                                Description
                              </span>
                              <textarea
                                className="textarea bg-neutral-content/25 text-[#2a303c]"
                                placeholder="Describe the incident here"
                                value={descriptionOfIncident}
                                onChange={(e) =>
                                  setDescriptionOfIncident(e.target.value)
                                }
                              ></textarea>
                            </div>
                          </label>
                        </div>
                        <div className="rounded-md p-2 bg-neutral-content/25 text-[#2a303c] sm:mb-6">
                          <span>
                            Would you like help from others to solve incident?
                          </span>
                          <div class="form-control">
                            <label className="cursor-pointer label">
                              <input
                                type="checkbox"
                                className="toggle mr-2"
                                checked={helpFromOthers}
                                onChange={(e) =>
                                  setHelpFromOthers(e.target.checked)
                                }
                              />
                              {helpFromOthers ? (
                                <span className="label-text mr-auto text-[#2a303c]">Yes</span>
                              ) : (
                                <span className="label-text mr-auto text-[#2a303c]">No</span>
                              )}
                            </label>
                          </div>
                        </div>

                        <div class="form-control sm:mb-5">
                          <label class="label">
                            <span class="label-text">
                              Date incident happened (click calendar)
                            </span>
                          </label>
                          <div className="flex flex-row place-content-start w-full text-[#2a303c]">
                            <BsCalendar
                              style={{ fontSize: "48px" }}
                              onClick={handleCalendarClick}
                            />
                            {isOpen && (
                              <DatePicker
                                selected={incidentDate}
                                onChange={handleCalendarChange}
                                inline
                              />
                            )}
                            {dateWasSelected ? (
                              <span className="my-auto ml-2.5">
                                {incidentDateText}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-control mb-2">
                          <label className="mb-1">
                            <span className="label-text">
                              Upload videos or pictures of the incident
                            </span>
                          </label>
                          <button class="btn bg-neutral-content/25 text-[#2a303c] drop-shadow hover:text-white" onClick={handleFileBtnClick}>
                            Upload Files
                          </button>
                          <input
                            type="file"
                            className=""
                            ref={hiddenFileInput}
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                            multiple
                          />
                          {fileSelected !== null
                            ? fileSelected.map(function (item, i) {
                                return (
                                  <div key={item.name + i}>{item.name}</div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row-span-1 form-control sm:mt-6 mb-2 sm:mb-0 ">
                    <button class="btn btn-info mx-5 drop-shadow-xl">
                      Submit Incident
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {showLoader ? <Loader /> : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ReportIncidentMain;
