import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { useParams } from "react-router-dom";
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  getDay,
  sub,
} from "date-fns";
import Chart from "react-apexcharts";

function Reports() {
  const { nid: paramNeighborhoodID } = useParams();
  const { allIncidents, allIncidentReplies } = useContext(SecurityUserContext);
  const [tabOneActive, setTabOneActive] = useState(true);
  const [tabTwoActive, setTabTwoActive] = useState(false);
  const [tabThreeActive, setTabThreeActive] = useState(false);
  const [incidentsThisNeighborhood, setIncidentsThisNeighborhood] =
    useState(null);
  const [repliesThisNeighborhood, setRepliesThisNeighborhood] = useState(null);


  //vars for Apex Donut Chart #3
  //Week Vars
  const [dailyIncidentTypeCount, setDailyIncidentTypeCount] = useState(null);
  const [incidentsPerDayThisWeek, setIncidentsPerDayThisWeek] = useState([
    { name: "Incidents", data: [0, 0, 0, 0, 0, 0, 0] },
  ]);
  const [repliesPerDayThisWeek, setRepliesPerDayThisWeek] = useState([
    { name: "Replies", data: [0, 0, 0, 0, 0, 0, 0] },
  ]);
  const [
    incidentsAndRepliesPerDayThisWeek,
    setIncidentsAndRepliesPerDayThisWeek,
  ] = useState([]);
  const [datesForWeek, setDatesForWeek] = useState(null);
  const [dailyIncidentDataComplete, setDailyIncidentDataComplete] =
    useState(false);
  const [dailyRepliesDataComplete, setDailyRepliesDataComplete] =
    useState(false);
  const [allPerDayThisWeekDataComplete, setAllPerDayThisWeekDataComplete] =
    useState(false);

  //Month Vars, data is in week batches because it is within a month
  const [WeeklyIncidentTypeCount, setWeeklyIncidentTypeCount] = useState(null); //done all
  const [incidentsPerWeekThisMonth, setIncidentsPerWeekThisMonth] = useState([
    //done all
    { name: "Incidents", data: [0, 0, 0, 0, 0, 0, 0] },
  ]);
  const [repliesPerWeekThisMonth, setRepliesPerWeekThisMonth] = useState([
    //done all
    { name: "Replies", data: [0, 0, 0, 0, 0, 0, 0] },
  ]);
  const [
    incidentsAndRepliesPerWeekThisMonth,
    setIncidentsAndRepliesPerWeekThisMonth,
  ] = useState([]); //done all
  const [datesForMonth, setDatesForMonth] = useState(null); //done all
  const [weeklyIncidentDataComplete, setWeeklyIncidentDataComplete] = //done all
    useState(false);
  const [weeklyRepliesDataComplete, setWeeklyRepliesDataComplete] =
    useState(false);
  const [allPerWeekThisMonthDataComplete, setAllPerWeekThisMonthDataComplete] =
    useState(false);

  const handleTabSelector = (e) => {
    if (e.target.name === "tab1") {
      setTabTwoActive(false);
      setTabThreeActive(false);
      setTabOneActive(true);
    }
    if (e.target.name === "tab2") {
      setTabOneActive(false);
      setTabThreeActive(false);
      setTabTwoActive(true);
    }
    if (e.target.name === "tab3") {
      setTabThreeActive(false);
      setTabTwoActive(false);
      setTabThreeActive(true);
    }
  };

 

  //get all incidents for just this neighborhood
  useEffect(() => {
    if (allIncidents !== null) {
      let tempIncidentList = [];
      allIncidents.map((item, i) => {
        if (item.neighborhoodID === paramNeighborhoodID) {
          tempIncidentList.push(item);
        }
      });
      console.log(tempIncidentList);
      setIncidentsThisNeighborhood(tempIncidentList);
    }
  }, [allIncidents]);

  //get all incident replies for just this neighborhood
  useEffect(() => {
    if (allIncidentReplies !== null) {
      let tempIncidentRepliesList = [];
      allIncidentReplies.map((item, i) => {
        if (item.neighborhoodID === paramNeighborhoodID) {
          tempIncidentRepliesList.push(item);
        }
      });
      console.log(tempIncidentRepliesList);
      setRepliesThisNeighborhood(tempIncidentRepliesList);
    }
  }, [allIncidentReplies]);

  //NEW for apex charts
  //Week BEGIN
  useEffect(() => {
    if (incidentsThisNeighborhood !== null) {
      const todaysDate = new Date();
      let tempWeekDates = [];
      //populate dates for x axis
      for (let index = 0; index < 6; index++) {
        let diff = 6 - index;
        let dateCalc = sub(todaysDate, { days: diff });
        tempWeekDates.push(format(dateCalc, "MM/dd/yy"));
      }
      tempWeekDates.push(format(todaysDate, "MM/dd/yy"));
      console.log(tempWeekDates);
      setDatesForWeek(tempWeekDates);
    }
  }, [incidentsThisNeighborhood]);

  useEffect(() => {
    if (datesForWeek !== null && incidentsThisNeighborhood !== null) {
      let tempIncidentCountArray = [
        {
          name: "Incidents",
          data: [0, 0, 0, 0, 0, 0, 0]
        },
      ];
      let tempIncidentTypeCount = [0, 0, 0, 0, 0];
      let tempIncidentGeneralArray = [];
      incidentsThisNeighborhood.map((item, i) => {
        //setting barchart data for chart 1 and general incidents for week

        let tempdate = format(item.dateOfIncident.toDate(), "MM/dd/yy");
        datesForWeek.map((dateItem, i) => {
          if (dateItem === tempdate) {
            tempIncidentCountArray[0].data[i] =
              tempIncidentCountArray[0].data[i] + 1;
            tempIncidentGeneralArray.push(item);

            //setting incident type count
            if (item.eventType === "DEFAULT") {
              tempIncidentTypeCount[4] = tempIncidentTypeCount[4] + 1;
            }
            if (item.eventType === "Theft") {
              tempIncidentTypeCount[0] = tempIncidentTypeCount[0] + 1;
            }
            if (item.eventType === "Burglary") {
              tempIncidentTypeCount[1] = tempIncidentTypeCount[1] + 1;
            }
            if (item.eventType === "Suspicious Behavior") {
              tempIncidentTypeCount[2] = tempIncidentTypeCount[2] + 1;
            }
            if (item.eventType === "Robbery") {
              tempIncidentTypeCount[3] = tempIncidentTypeCount[3] + 1;
            }
            if (item.eventType === "Other") {
              tempIncidentTypeCount[4] = tempIncidentTypeCount[4] + 1;
            }
          }
        });
      });
      setDailyIncidentTypeCount(tempIncidentTypeCount);
      setIncidentsPerDayThisWeek(tempIncidentCountArray);
    }
  }, [datesForWeek, incidentsThisNeighborhood]);

  useEffect(() => {
    if (datesForWeek !== null && repliesThisNeighborhood !== null) {
      let tempReplyCountArray = [
        {
          name: "Replies",
          data: [0, 0, 0, 0, 0, 0, 0]
        },
      ];
      repliesThisNeighborhood.map((item, i) => {
        //setting barchart data for chart 1 and general incidents for week
        let tempdate = format(item.dateReported.toDate(), "MM/dd/yy");
        datesForWeek.map((dateItem, i) => {
          if (dateItem === tempdate) {
            tempReplyCountArray[0].data[i] = tempReplyCountArray[0].data[i] + 1;
          }
        });
      });
      console.log(tempReplyCountArray);
      setRepliesPerDayThisWeek(tempReplyCountArray);
    }
  }, [repliesThisNeighborhood, datesForWeek]);

  //check for null values so chart doesn't try to render with null
  useEffect(() => {
    if (incidentsPerDayThisWeek !== null && datesForWeek !== null) {
      setDailyIncidentDataComplete(true);
    }
    if (repliesPerDayThisWeek !== null && datesForWeek !== null) {
      setDailyRepliesDataComplete(true);
    }
  }, [datesForWeek, incidentsPerDayThisWeek, repliesPerDayThisWeek]);

  useEffect(() => {
    if (dailyIncidentDataComplete && dailyRepliesDataComplete) {
      let tempArray = [];
      tempArray.push(incidentsPerDayThisWeek[0]);
      tempArray.push(repliesPerDayThisWeek[0]);
      setIncidentsAndRepliesPerDayThisWeek(tempArray);
      console.log(tempArray);
      setAllPerDayThisWeekDataComplete(true);
    }
  }, [dailyIncidentDataComplete, dailyRepliesDataComplete]);

  useEffect(() => {
    if (allPerDayThisWeekDataComplete) {
      console.log(incidentsAndRepliesPerDayThisWeek);
    }
  }, [incidentsAndRepliesPerDayThisWeek]);

  //Options for Apex charts
  const chartOneOptions = {
    title: {
      text: "Incidents For Week",
      align: "center",
      margin: 6,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    fill: {
      colors: "#2a303c",
    },
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: datesForWeek,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      min: 0,
    },
  };

  const chartTwoOptions = {
    title: {
      text: "Replies For Week",
      align: "center",
      margin: 6,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    fill: {
      colors: "#2a303c",
    },
    bar: {
      columnWidth: "50%",
    },
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: datesForWeek,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      min: 0,
    },
  };

  const chartFourOptions = {
    chart: {
      height: 328,
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 2,
        blur: 4,
        opacity: 1,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#2a303c", "#2196F3"],

    title: {
      text: "Incidents And Replies For Week",
      align: "center",
      offsetY: 25,
      offsetX: 20,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9,
      },
    },
    grid: {
      show: true,
      padding: {
        bottom: 0,
      },
    },
    labels: datesForWeek,
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -20,
    },
    responsive: [
      {
        breakpoint: 495,
        options: {
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
            position: "top",
            horizontalAlign: "right",
            offsetY: -20,
          },
        },
      },
    ],
  };

  //options for apex donut chart
  const donutChartOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Total Incidents",
            },

            value: {
              color: "#2a303c",
            },
          },
        },
      },
    },
    title: {
      text: "Incident Types",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    colors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
    fill: {
      colors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
    },
    labels: ["Theft", "Burglary", "Suspicious", "Robbery", "Other"],
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#6e747e",
      },
      markers: {
        fillColors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 640,
        options: {
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  total: {
                    show: false,
                    showAlways: true,
                    label: "Total Incidents",
                  },
                  value: {
                    color: "#ffffff",
                  },
                },
              },
            },
          },
        },
      },
    ],
    responsive: [
      {
        breakpoint: 1300,
        options: {
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  total: {
                    show: true,
                    showAlways: true,
                    label: "",
                  },
                  value: {
                    color: "#ffffff",
                  },
                },
              },
            },
          },
        },
      },
    ],
  };
  //Week ENDS

  //Month BEGINS
  useEffect(() => {
    if (incidentsThisNeighborhood !== null) {
      const todaysDate = new Date();
      let tempWeekDates = [];
      //populate dates for x axis
      for (let index = 0; index < 29; index++) {
        let diff = 29 - index;
        let dateCalc = sub(todaysDate, { days: diff });
        tempWeekDates.push(format(dateCalc, "MM/dd/yy"));
      }
      tempWeekDates.push(format(todaysDate, "MM/dd/yy"));
      console.log(tempWeekDates);
      setDatesForMonth(tempWeekDates);
    }
  }, [incidentsThisNeighborhood]);

  useEffect(() => {
    if (datesForMonth !== null && incidentsThisNeighborhood !== null) {
      let tempIncidentCountArray = [
        {
          name: "Incidents",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0
          ],
        },
      ];
      let tempIncidentTypeCount = [0, 0, 0, 0, 0];
      let tempIncidentGeneralArray = [];
      incidentsThisNeighborhood.map((item, i) => {
        //setting barchart data for chart 1 and general incidents for week
        let tempdate = format(item.dateOfIncident.toDate(), "MM/dd/yy");
        datesForMonth.map((dateItem, i) => {
          if (dateItem === tempdate) {
            tempIncidentCountArray[0].data[i] =
              tempIncidentCountArray[0].data[i] + 1;
            tempIncidentGeneralArray.push(item);

            //setting incident type count
            if (item.eventType === "DEFAULT") {
              tempIncidentTypeCount[4] = tempIncidentTypeCount[4] + 1;
            }
            if (item.eventType === "Theft") {
              tempIncidentTypeCount[0] = tempIncidentTypeCount[0] + 1;
            }
            if (item.eventType === "Burglary") {
              tempIncidentTypeCount[1] = tempIncidentTypeCount[1] + 1;
            }
            if (item.eventType === "Suspicious Behavior") {
              tempIncidentTypeCount[2] = tempIncidentTypeCount[2] + 1;
            }
            if (item.eventType === "Robbery") {
              tempIncidentTypeCount[3] = tempIncidentTypeCount[3] + 1;
            }
            if (item.eventType === "Other") {
              tempIncidentTypeCount[4] = tempIncidentTypeCount[4] + 1;
            }
          }
        });
      });
      setWeeklyIncidentTypeCount(tempIncidentTypeCount);
      setIncidentsPerWeekThisMonth(tempIncidentCountArray);
    }
  }, [datesForMonth, incidentsThisNeighborhood]);

  useEffect(() => {
    if (datesForMonth !== null && repliesThisNeighborhood !== null) {
      let tempReplyCountArray = [
        {
          name: "Replies",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0
          ],
        },
      ];
      repliesThisNeighborhood.map((item, i) => {
        //setting barchart data for chart 1 and general incidents for week
        let tempdate = format(item.dateReported.toDate(), "MM/dd/yy");
        datesForMonth.map((dateItem, i) => {
          if (dateItem === tempdate) {
            tempReplyCountArray[0].data[i] = tempReplyCountArray[0].data[i] + 1;
          }
        });
      });
      console.log(tempReplyCountArray);
      setRepliesPerWeekThisMonth(tempReplyCountArray);
    }
  }, [repliesThisNeighborhood, datesForMonth]);

  //check for null values so chart doesn't try to render with null
  useEffect(() => {
    if (incidentsPerWeekThisMonth !== null && datesForMonth !== null) {
      setWeeklyIncidentDataComplete(true);
    }
    if (repliesPerWeekThisMonth !== null && datesForMonth !== null) {
      setWeeklyRepliesDataComplete(true);
    }
  }, [datesForMonth, incidentsPerWeekThisMonth, repliesPerWeekThisMonth]);

  useEffect(() => {
    if (weeklyIncidentDataComplete && weeklyRepliesDataComplete) {
      let tempArray = [];
      tempArray.push(incidentsPerWeekThisMonth[0]);
      tempArray.push(repliesPerWeekThisMonth[0]);
      setIncidentsAndRepliesPerWeekThisMonth(tempArray);
      console.log(tempArray);
      setAllPerWeekThisMonthDataComplete(true);
    }
  }, [weeklyIncidentDataComplete, weeklyRepliesDataComplete]);

  useEffect(() => {
    if (allPerWeekThisMonthDataComplete) {
      console.log(incidentsAndRepliesPerWeekThisMonth);
    }
  }, [incidentsAndRepliesPerWeekThisMonth]);

  //Options for Apex charts
  const chartOneOptionsMonth = {
    title: {
      text: "Incidents For Month",
      align: "center",
      margin: 6,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    fill: {
      colors: "#2a303c",
    },
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: datesForMonth,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      min: 0,
    },
  };

  const chartTwoOptionsMonth = {
    title: {
      text: "Replies For Month",
      align: "center",
      margin: 6,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    fill: {
      colors: "#2a303c",
    },
    bar: {
      columnWidth: "50%",
    },
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: datesForMonth,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
      min: 0,
    },
  };

  const chartFourOptionsMonth = {
    chart: {
      height: 328,
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 2,
        blur: 4,
        opacity: 1,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    colors: ["#2a303c", "#2196F3"],

    title: {
      text: "Incidents And Replies For Week",
      align: "center",
      offsetY: 25,
      offsetX: 20,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9,
      },
    },
    grid: {
      show: true,
      padding: {
        bottom: 0,
      },
    },
    labels: datesForMonth,
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetY: -20,
    },
    responsive: [
      {
        breakpoint: 495,
        options: {
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
            position: "top",
            horizontalAlign: "right",
            offsetY: -20,
          },
        },
      },
    ],
  };

  //options for apex donut chart
  const donutChartOptionsMonth = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: "Total Incidents",
            },

            value: {
              color: "#2a303c",
            },
          },
        },
      },
    },
    title: {
      text: "Incident Types",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#6e747e",
      },
    },
    colors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
    fill: {
      colors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
    },
    labels: ["Theft", "Burglary", "Suspicious", "Robbery", "Other"],
    stroke: {
      show: false,
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#6e747e",
      },
      markers: {
        fillColors: ["#2a303c", "#1d242a", "#3F8DC2", "#1fb2a5", "#4EB2F5"],
      },
    },
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      enabled: true,
      dropShadow: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 640,
        options: {
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  total: {
                    show: false,
                    showAlways: true,
                    label: "Total Incidents",
                  },
                  value: {
                    color: "#ffffff",
                  },
                },
              },
            },
          },
        },
      },
    ],
    responsive: [
      {
        breakpoint: 1300,
        options: {
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                  total: {
                    show: true,
                    showAlways: true,
                    label: "",
                  },
                  value: {
                    color: "#ffffff",
                  },
                },
              },
            },
          },
        },
      },
    ],
  };
  //Week ENDS
  //Month ENDS

  //Year BEGINS

  //Year ENDS
    return (
      <div className="">
        <div class="flex flex-col pr-10 mt-5">
          {/*Selection section Selector here*/}
          <div className="basis-1/4 flex w-full h-full">
            <div class="tabs">
              <a
                name="tab1"
                class={`tab tab-lifted ${tabOneActive ? "tab-active" : ""}`}
                onClick={(e) => handleTabSelector(e)}
              >
                Week
              </a>
              <a
                name="tab2"
                class={`tab tab-lifted ${tabTwoActive ? "tab-active" : ""}`}
                onClick={(e) => handleTabSelector(e)}
              >
                Month
              </a>
            </div>
          </div>

          {/*Big overall main*/}
          <div className="w-full h-full">
            {/*Second Row Section*/}
            {/*Daily for a week*/}

            <div className="flex flex-col sm:flex-row h-96">
              {/*Chart 1 Incidents daily for week*/}
              <div className="basis-1/2 card shadow-2xl p-4 mr-4">
                {weeklyIncidentDataComplete ? (
                  <Chart
                    key={Math.random()}
                    options={
                      tabOneActive
                        ? chartOneOptions
                        : tabTwoActive
                        ? chartOneOptionsMonth
                        : null
                    }
                    series={
                      tabOneActive
                        ? incidentsPerDayThisWeek
                        : tabTwoActive
                        ? incidentsPerWeekThisMonth
                        : null
                    }
                    type="bar"
                    width="100%"
                    height="100%"
                  />
                ) : <div>No Data</div>}
              </div>

              {/*Chart 2 Replies daily for week*/}
              <div className="basis-1/2 card w-full shadow-2xl p-4">
                {dailyRepliesDataComplete ? (
                  <Chart
                    key={Math.random()}
                    options={
                      tabOneActive
                        ? chartTwoOptions
                        : tabTwoActive
                        ? chartTwoOptionsMonth
                        : null
                    }
                    series={
                      tabOneActive
                        ? repliesPerDayThisWeek
                        : tabTwoActive
                        ? repliesPerWeekThisMonth
                        : null
                    }
                    type="bar"
                    width="100%"
                    height="100%"
                  />
                ) : <div>No Data</div>}
              </div>
            </div>

            {/*Third Row Section*/}
            <div className="flex flex-col sm:flex-row h-96 mt-2">
              {/*Chart 1 Pie Chart row 4-5 Monthly for year*/}
              <div
                className="card w-full shadow-2xl p-4 mr-4 mt-4 sm:mt-0"
                key={Math.random()}
              >
                {dailyIncidentTypeCount !== null ? (
                  <Chart
                    options={
                      tabOneActive
                        ? donutChartOptions
                        : tabTwoActive
                        ? donutChartOptionsMonth
                        : null
                    }
                    series={
                      tabOneActive
                        ? dailyIncidentTypeCount
                        : tabTwoActive
                        ? WeeklyIncidentTypeCount
                        : null
                    }
                    type="donut"
                    width="100%"
                    height="100%"
                  />
                ) : <div>No Data</div>}
              </div>

              {/*Chart 2 Big yearly line chart or other extra chart*/}
              <div className="card w-full shadow-2xl p-4 mt-4 sm:mt-0">
                {allPerDayThisWeekDataComplete ? (
                  <Chart
                    key={Math.random()}
                    options={
                      tabOneActive
                        ? chartFourOptions
                        : tabTwoActive
                        ? chartFourOptionsMonth
                        : null
                    }
                    series={
                      tabOneActive
                        ? incidentsAndRepliesPerDayThisWeek
                        : tabTwoActive
                        ? incidentsAndRepliesPerWeekThisMonth
                        : null
                    }
                    type="line"
                    width="100%"
                    height="100%"
                  />
                ) : <div>No Data</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


export default Reports;
