import React, { useState, useEffect, useContext } from "react";
import { GiConsoleController } from "react-icons/gi";
import IncidentCard from "../components/IncidentCard";
import IncidentsContext from "../context/IncidentsContext";
import { useNavigate, useParams, Link } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import logo1 from "../assets/logo1.png";

function Incidents() {
  const { nid: paramNeighborhoodID } = useParams();
  let navigate = useNavigate();
  const {
    allIncidents,
    loading,
    getAllIncidents,
    setLoading,
    setRefreshData,
    refreshData,
    neighborhoodID,
    setNeighborhoodID,
    neighborhoodName,
  } = useContext(IncidentsContext);
  const [tempAllIncidents, setTempAllIncidents] = useState(null);
  const [allIncidentsFiltered, setAllIncidentsFiltered] = useState(null);
  const [filterSelection, setFilterSelection] = useState("Descending");

  //Setup to check if user dropped in from home screen or a link.
  //  If a link then it will set neighborhoodID and trigger fetch firestore
  useEffect(() => {
    console.log(neighborhoodID);
    if (neighborhoodID === null) {
      setNeighborhoodID(paramNeighborhoodID);
    }
  }, []);

  useEffect(() => {
    // getAllIncidents();
    if (allIncidents !== null) {
      setTempAllIncidents(allIncidents);
      sortIncidentArray();
    }
  }, [allIncidents]);

  useEffect(() => {
    sortIncidentArray();
  }, [filterSelection]);

  //used to reload data on back and reload screen
  useEffect(() => {
    if (allIncidentsFiltered === null) {
      setRefreshData(true);
    }
    if (allIncidentsFiltered !== null) {
      setLoading(false);
    }
    console.log(allIncidentsFiltered);
  }, [allIncidentsFiltered, allIncidents]);

  //sort the incidents by descending or ascending
  const sortIncidentArray = () => {
    let tempArray = null;
    if (allIncidents !== null) {
      if (filterSelection === "Descending") {
        tempArray = allIncidents.sort(
          (objA, objB) => Number(objB.dateReported) - Number(objA.dateReported)
        );
        setAllIncidentsFiltered([...tempArray]);
      }
      if (filterSelection === "Ascending") {
        tempArray = allIncidents.sort(
          (objA, objB) => Number(objA.dateReported) - Number(objB.dateReported)
        );
        setAllIncidentsFiltered([...tempArray]);
      }
    }
  };

  //Change path for production
  const gotoReturnHome = () => {
    let path = `/${paramNeighborhoodID}`;
    navigate(path);
  };

  const gotoReportIncident = () => {
    let path = `/${paramNeighborhoodID}/reportincident`;
    navigate(path);
  };

  //todo, need to fix the fact that if I hit refresh while on this screen
  //  it doesn't load the map function and no data shows
  if (allIncidents === null) {
    return (
      <div className="flex flex-col h-full justify-center items-center h-screen">
        <div className="stat-value mb-3">Loading...</div>
        <BarLoader loading="true" color="#3abff8" width={300} />
      </div>
    );
  }

  if (allIncidents.length === 0) {
    return (
      <div className="w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px]">
        <div className="flex flex-col h-full min-h-screen">
          <div className="basis-1/4 mt-5">
            <Link to={`/${paramNeighborhoodID}`}>
              <div className="flex flex-row items-end		">
                <div className="mr-5 ml-3">
                  <img src={logo1} alt="Logo Image" height={80} width={60} />
                </div>
                <div className="flex flex-row">
                  <div className="font-bold text-2xl text-[#0037a5]">
                    Nexus
                  </div>
                  <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
                </div>
              </div>
            </Link>
          </div>
          <div className="basis-3/4 justify-self-center sm:mt-auto sm:mb-auto sm:mx-auto mx-2 mt-4">
            <div className="flex flex-col	py-6 sm:p-20 h-3/5 card bg-[#f8fafb] justify-center drop-shadow-xl">
              <div className="flex flex-col ml-auto mr-auto mb-20 items-center">
                <h1 class="text-5xl font-bold mb-3">Incidents</h1>
                <h1 class="text-3xl mb-3 text-base-100 font-bold">
                  {neighborhoodName}
                </h1>
                <p class="py-2 text-lg">
                  This is where all the incidents for your neighborhood will
                  appear.
                </p>
                <p className="text-lg">
                  There are currently no incidents to report.
                </p>
              </div>
              <div className="flex flex-col sm:flex-row sm:ml-auto sm:mr-auto">
                <div className="flex flex-col p-1 sm:p-3 sm:mr-4">
                  <button
                    class="btn bg-neutral-content text-black hover:bg-base-100 hover:text-white mb-2 sm:w-56 border-none"
                    onClick={gotoReturnHome}
                  >
                    Return Home
                  </button>
                </div>
                <div className="flex flex-col p-1 sm:p-3">
                  <button
                    class="btn btn-primary sm:w-56"
                    onClick={gotoReportIncident}
                  >
                    Report Incident
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px] min-h-screen	">
      <div className="flex	pt-5">
        <Link to={`/${paramNeighborhoodID}`}>
          <div className="flex flex-row items-end">
            <div className="mr-5 ml-3">
              <img src={logo1} alt="Logo Image" height={80} width={60} />
            </div>
            <div className="flex flex-row">
              <div className="font-bold text-2xl text-[#0037a5]">Nexus</div>
              <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
            </div>
          </div>
        </Link>
      </div>

      <div className="flex flex-col ml-auto mr-auto mt-20 ">
        <h1 className="text-5xl font-bold text-center">Incidents</h1>
      </div>
      <div className="flex flex-col justify-center mt-4 drop-shadow-xl">
        <div className="flex flex-row justify-between mb-1 border-t-2 border-x-2 border-base-100 rounded-t-xl pl-2">
          {neighborhoodName ? (
            <div className="text-3xl self-center text-base-100 font-bold">
              {neighborhoodName}
            </div>
          ) : (
            <div className="text-3xl self-center">Neighborhood</div>
          )}

          <div>
            <div class="dropdown dropdown-end">
              <label tabindex="0" class="btn m-1 ">
                Filter
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-200 border border-info bg-opacity-90 rounded-box w-52"
              >
                <li onClick={() => setFilterSelection("Descending")}>
                  <a>Newest</a>
                </li>
                <li onClick={() => setFilterSelection("Ascending")}>
                  <a>Oldest</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-full items-center justify-center bg-neutral-content rounded-lg h-[500px] border-2">
          <table class="sm:table-auto w-full">
            <thead className="bg-base-100 mb-2">
              <tr className="text-white mb-2">
                <th>Date Reported</th>
                <th>Indicent Type</th>
                <th>Description</th>
                <th>Location</th>
                <th>Incident Reported To</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allIncidentsFiltered !== null
                ? allIncidentsFiltered.map(function (item, i) {
                    return (
                      <IncidentCard
                        key={item.id}
                        item={item}
                        neighborhoodID={neighborhoodID}
                        itemNumber={i}
                      />
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Incidents;
