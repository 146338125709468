import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { red } from "@mui/material/colors";
import IncidentsContext from "../context/IncidentsContext";

function Discord() {
  const { discordWebhookURL } = useContext(IncidentsContext);

  let footerMessage = "";
  const send = async (dataObject) => {
    console.log("I was called at discord 11");
    if (dataObject.footer === true) {
      footerMessage = "Requesting help solving incident";
    }
    const body = {
      content: dataObject.content,
      tts: false,
      color: "white",
      embeds: [
        {
          title: dataObject.title,
          author: {
            name: dataObject.authorName,
            url: dataObject.url,
          },
          description: dataObject.descriptionOfIncident,
          footer: {
            text: footerMessage,
            color: "15548997",
          },
        },
      ],
    };

    try {
      const data = await axios.post(discordWebhookURL, body);
      console.log("data sent to discord");
    } catch (error) {
      console.log(error);
    }
  };

  return {
    send,
  };
}

export default Discord;
