import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  withRouter,
  useNavigate,
} from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GiPoliceBadge, GiPoliceCar } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import {
  GiCarnivalMask,
  GiSwapBag,
  GiArrest,
  GiDominoMask,
} from "react-icons/gi";
import { add, format, isEqual, differenceInDays } from "date-fns";

function IncidentCard({ item, neighborhoodID, itemNumber }) {
  const [dateReported, setDateReported] = useState(null);
  const [timeOfDayReported, setTimeOfDayReported] = useState(null);
  const [gotoDetailsPage, setGotoDetailsPage] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [happenedRecently, setHappenedRecently] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [descriptionLimited, setDescriptionLimited] = useState("");
  const [isEven, setIsEven] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(item);
    if (item !== null) {
      setDateReported(format(item.dateReported.toDate(), "MM/dd/yyyy"));
      setTimeOfDayReported(format(item.dateReported.toDate(), "h:m a"));
      isItterationOddEven()
    }
  }, []);

  const isItterationOddEven = () => {
    console.log(item)
    let tempNum = itemNumber
    if (tempNum % 2 === 0) {
      setIsEven(true) 
      console.log(true)
    } else {
      setIsEven(false)
      console.log(false)
    }
}

  //set happenedRecently to true if the event happened within the last 24 hours
  useEffect(() => {
    const todaysDate = new Date();
    const dayDifference = differenceInDays(
      item.dateReported.toDate(),
      todaysDate
    );
    console.log(dayDifference);
    if (dayDifference === 0) {
      setHappenedRecently(true);
    }
  }, [dateReported]);

  //navigate user to matchdetails page and sends data with it.
  useEffect(() => {
    if (gotoDetailsPage) {
      navigate(`/${neighborhoodID}/incidentdetails/${currentItemId}`, {
        state: {
          incidentId: currentItemId,
        },
      });
    }
  }, [gotoDetailsPage]);

  useEffect(() => {
    if (item.descriptionOfIncident) {
      if (item.descriptionOfIncident.length <= 50) {
        setShowAll(true);
      } else {
        setShowAll(false);
        setDescriptionLimited(item.descriptionOfIncident.substring(0, 50));
      }
    }
  }, []);

  if (item.id === undefined) {
    return <div>Loading...</div>;
  } else {
    return (
      <tr
        onClick={() => (setGotoDetailsPage(true), setCurrentItemId(item.id))}
        className={`hover:bg-base-200 hover:text-white cursor-pointer text-[#2a303c]  ${isEven ? " bg-[#e2e4e8] text-[#2a303c] hover:text-white" : "bg-gray-400"} `}
        key={item.id}
      >
        <th>
          <div class="font-bold">{dateReported}</div>
          <div class="text-sm opacity-50">{timeOfDayReported}</div>
        </th>
        <td className="py-2 h-24">
          <div class="flex items-center space-x-3">
            <div class="avatar">
              <div class="mask mask-squircle w-12 h-12">
                {item.eventType === "Burglary" ? (
                  <FaHome style={{ fontSize: "48px" }} />
                ) : null}
                {item.eventType === "Theft" ? (
                  <GiSwapBag style={{ fontSize: "48px" }} />
                ) : null}
                {item.eventType === "Suspicious Behavior" ? (
                  <GiDominoMask style={{ fontSize: "48px" }} />
                ) : null}
                {item.eventType === "Robbery" ? (
                  <GiArrest style={{ fontSize: "48px" }} />
                ) : null}
                {item.eventType === "Other" ? (
                  <AiOutlineExclamationCircle style={{ fontSize: "48px" }} />
                ) : null}
              </div>
            </div>
            <div>
              <div class="font-bold">{item.eventType}</div>
            </div>
          </div>
        </td>
        <td >
          {showAll ? (
            <div style={{ maxWidth: "200px", whiteSpace: "pre-line", position: "relative" }}>
            <p className="">{item.descriptionOfIncident}</p>
            </div>
          ) : (
            <div className="h-20" style={{ maxWidth: "200px", whiteSpace: "pre-line", position: "relative", display: "inline-block" }}>
            <p className="p-auto h-20 break-words" >{`${descriptionLimited} ...More`}</p>
            </div>
            )}
        </td>
        <td>
          <div>
            <div class="font-bold">{item.locationOfIncident}</div>
          </div>
        </td>
        <td>
          <div className="flex flex-row justify-start flex-wrap">
            {item.whoWasNotified.length !== 0
              ? item.whoWasNotified.map(function (item, i) {
                  return (
                    <div>
                      {item === "Police" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <GiPoliceBadge />
                          Police
                        </div>
                      ) : null}
                      {item === "Security" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <GiPoliceCar />
                          Security
                        </div>
                      ) : null}
                      {item === "Association" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <HiUserGroup />
                          Association
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        </td>
        <th>
          <div className={`flex justify-center indicator`}>
            {happenedRecently ? (
              <span className="indicator-item indicator-start badge bg-slate-600 border-none">
                New
              </span>
            ) : null}
            <button
              class="btn btn-info btn-sm "
              onClick={() => (
                setGotoDetailsPage(true), setCurrentItemId(item.id)
              )}
            >
              details
            </button>
          </div>
        </th>
      </tr>
    );
  }
}

export default IncidentCard;
