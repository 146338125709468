import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import SecurityUserContext from "../../../context/SecurityUserContext";

function SigningIn() {
  const { neighborhoodsLoaded, userSubscribed } = useContext(SecurityUserContext);
  const navigate = useNavigate();

  //check if user has a current subscription.  If not, then take to accept payment page
  useEffect(() => {
    if (userSubscribed === false) {
      navigate("/admin/AcceptPayment");
    }
    if (userSubscribed === true && neighborhoodsLoaded) {
      navigate("/admin");
    }
  }, [userSubscribed, neighborhoodsLoaded]);

  //setup a check for if user is logged in and if not, then navigate to signin.
  {
    /*Loading data*/
  }

  return (
    <div className="flex flex-col h-full justify-center items-center h-screen">
      <div className="stat-value mb-3">Loading...</div>
      <BarLoader loading="true" color="#3abff8" width={300} />
    </div>
  );
}

export default SigningIn;
