import React, { useState, useContext } from "react";
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SecurityUserContext from "../../../../context/SecurityUserContext";

function ActivityCard(props) {
  const {
    allIncidents,
    allIncidentReplies,
    neighborhoodID,
    setNeighborhoodID,
    neighborhoods,
  } = useContext(SecurityUserContext);
  // const dateReported = format(props.item.dateReported.toDate(), "MM/dd/yyyy")
  const timeReported = format(props.item.dateReported.toDate(), "h:mm a");
  const [dateReportedCalc, setDateReportedCalc] = useState(null);
  const [repliesExist, setRepliesExist] = useState(false);
  const [withinTwentyFourHours, setWithinTwentyFourHours] = useState(false);
  const navigate = useNavigate();
  const [newReplies, setNewReplies] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [points, setPoints] = useState({ left: 0, top: 0 });
  const [currentURL, setCurrentURL] = useState("");
  const [isEven, setIsEven] = useState(null)
  const [neighborhoodName, setNeighborhoodName] = useState("")

  //determining what neighborhood name goes with this incident.
  //  props.neighborhoods.filter(
  //   (item) => item.id === props.item.neighborhoodID
  // );

  //getting neighborhood name
  useEffect(() => {
    if (props.item !== null) {
      let propID = props.item.neighborhoodID
      if (neighborhoods.length !== 0) {
        neighborhoods.map((item, i) => {
          if (item.id === propID) {
            setNeighborhoodName(item.name)
          }
        })
      }
    console.log(props.item)
    console.log(neighborhoods)
    }
  }, [neighborhoods])




  //determining if any replies exist for this incident.
  const doRepliesExist = () => {
    if (props.incidentsWithReplies !== null) {
      let doesExist = props.incidentsWithReplies.includes(props.item.id);
      if (doesExist) {
        setRepliesExist(true);
      }
    }
  };

  //Get all replies for this incident and set value of newReply if a reply is made within 24 hours.
  const getAllRepliesForThisIncident = () => {
    const allReplies = props.incidentReplies;
    const incidentUID = props.item.id;
    const repliesForThisIncident = allReplies.filter(
      (item) => item.incidentUID.indexOf(incidentUID) !== -1
    );
    console.log(repliesForThisIncident);
    aReplyWithinTwentyFour(repliesForThisIncident);
  };

  //run through all replies and if any have dates within 24 hours it'll set setNewReplies to true
  const aReplyWithinTwentyFour = (replies) => {
    const todaysDate = new Date();
    if (replies !== undefined) {
      replies.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference === 0) {
          console.log(item);
          setNewReplies(true);
        }
      });
    }
  };
  
  const isItterationOddEven = () => {
      let tempNum = props.iVar
      if (tempNum % 2 === 0) {
        setIsEven(true) 
      } else {
        setIsEven(false)
      }
  }

  useEffect(() => {
    if (isEven !== null) {
      console.log(isEven)
    }
  }, [isEven])

  useEffect(() => {
    if (props.item !== null) {
      isItterationOddEven()
      console.log(props.item);
      console.log(props.incidentsWithReplies);
      if (props.incidentsWithReplies !== null) {
        doRepliesExist();
        getAllRepliesForThisIncident();
      }
      if (props.item.isReply) {
        setCurrentURL(
          `localhost:3000/${props.item.neighborhoodID}/incidentdetails/${props.item.incidentUID}`
        );
      } else {
        setCurrentURL(
          `localhost:3000/${props.item.neighborhoodID}/incidentdetails/${props.item.id}`
        );
      }
    }
  }, [allIncidentReplies]);

  //calculate date difference, if less than 1 day show hours or minutes
  let tempDateReported = null;
  const dateReported = () => {
    const todaysDate = new Date();
    let minuteDifference = null;
    let hourDifference = null;
    const dayDifference = differenceInDays(
      props.item.dateReported.toDate(),
      todaysDate
    );
    if (dayDifference === 0) {
      setWithinTwentyFourHours(true);
      hourDifference = differenceInHours(
        todaysDate,
        props.item.dateReported.toDate()
      );
      tempDateReported = hourDifference.toString() + "h ago";
      if (hourDifference === 0) {
        minuteDifference = differenceInMinutes(
          todaysDate,
          props.item.dateReported.toDate()
        );
        tempDateReported = minuteDifference.toString() + "m ago";
      }
    } else {
      tempDateReported = format(props.item.dateReported.toDate(), "MM/dd/yyyy");
    }
    setDateReportedCalc(tempDateReported);
  };

  //navigate user to incident details page
  const handleClick = () => {
    console.log("activity card handled click");
    navigate(`/admin/${props.item.neighborhoodID}/incidentdetails/${props.item.id}`, {
      state: {
        incidentId: props.item.id,
      },
    });
  };

  useEffect(() => {
    dateReported();
    console.log(props.allIncidentReplies);
  }, [dateReportedCalc]);

  // bg-[#ecf6ff] ${withinTwentyFourHours ? "bg-indigo-300 " : ""}`}

  return (
    <React.Fragment>
      <tr className={`hover:bg-base-200 cursor-pointer ${isEven ? " bg-[#e2e4e8] text-[#2a303c] hover:text-white" : ""} `} onClick={handleClick}>
        <td>{dateReportedCalc}</td>
        <td>{timeReported}</td>
        <td>{neighborhoodName}</td>
        <td>{props.item.eventType}</td>
        <td>{props.item.locationOfIncident}</td>
        <td className="">
          {repliesExist && !newReplies ? (
            <div className="badge badge-info gap-2">Yes</div>
          ) : newReplies ? (
            <div className="badge badge-success gap-2">New</div>
          ) : (
            ""
          )}
        </td>
      </tr>
    </React.Fragment>
  );
}

export default ActivityCard;
