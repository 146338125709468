import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import IncidentsContext from "../context/IncidentsContext";
import logo1 from "../assets/logo1.png";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";

function Home() {
  let navigate = useNavigate();
  const [hoodName, setHoodName] = useState(null);
  const [neighborhoodID, setNeighborhoodID] = useState(null);
  const [error, setError] = useState(false)
  const [invalidNeighborhood, setInvalidNeighborhood] = useState(false)

  const checkNeighborhoodExists = async () => {
    const docRef = doc(db, "neighborhoods", neighborhoodID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let path = `/${neighborhoodID}`;
      navigate(path);
    } else {
      setInvalidNeighborhood(true);
      console.log("The neighborhood does not exist")
    }
  };

  const gotoNeighborhood = async () => {
    if (neighborhoodID === null || neighborhoodID === "") {
      setError(true);
    } else {
      checkNeighborhoodExists();
    }
  };

  return (
    <div className="w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px]">
      <div className="flex flex-col h-full min-h-screen">
        <div className="basis-1/4 mt-5">
          <div className="flex items-end">
            <Link to={`/`}>
              <div className="flex flex-row items-end">
                <div className="mr-5 ml-3">
                  <img src={logo1} alt="Logo Image" height={80} width={60} />
                </div>
                <div className="flex flex-row">
                  <div className="font-bold text-2xl text-[#0037a5]">
                    Nexus
                  </div>
                  <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="basis-3/4 justify-self-center sm:mt-auto sm:mb-auto sm:mx-auto mx-2 mt-4">
          <div className="flex flex-col	py-6 p-2 sm:p-20 h-3/5 card bg-[#f8fafb] justify-center drop-shadow-xl">
            <div className="flex flex-col ml-auto mr-auto mb-20">
              <h1 className="text-4xl font-bold text-center">Real-Time</h1>
              <h1 className="text-4xl font-bold text-center mb-5">
                Neighborhood Notification System
              </h1>
              <p className="self-center text-center">
                You need a neighborhood ID to enter your neighborhood
              </p>
              <p className="self-center">Please enter it below</p>
            </div>
            <div className="flex flex-col sm:flex-row sm:ml-auto sm:mr-auto place-self-center			">
              <div className="sm:mr-5 mb-2 sm:mb-0">
                <input
                  type="text"
                  value={neighborhoodID}
                  onChange={(e) => (setNeighborhoodID(e.target.value), setError(false), setInvalidNeighborhood(false))}
                  placeholder="Neighborhood ID"
                  className="bg-white input input-bordered w-full sm:w-[300px]"
                />
              </div>
              <button className="btn btn-primary" onClick={gotoNeighborhood}>
                Go To Your Neighborhood
              </button>
            </div>
            {error ? (
              <div className="flex flex-col self-center mt-2">
                <p className="text-red-500 font-bold self-center text-center">
                  You need to enter a valid neighborhood ID
                </p>
                <p className="self-center text-center">
                  If you do not have your neighborhood ID, contact a member of
                  your neighborhood group
                </p>
              </div>
            ) : null}
            {invalidNeighborhood ? (
                <div className="flex flex-col mt-2">
                  <p className="text-red-500 font-bold self-center text-center">
                    This is not a valid neighborhood ID
                  </p>
                </div>
              ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

// Y70ODNyoopAaI3zSwp0f
