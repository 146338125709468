import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase.config";

function EditCompanyName(props) {
  const { setSignout, adminUID } = useContext(SecurityUserContext);
  const navigate = useNavigate();
  const [tempName, setTempName] = useState("");
  const [showError, setShowError] = useState(false);
  const [newCompanyName, setNewCompanyName] = useState("");

  const handleSubmit = async () => {
    props.setShowEditNameModal(false);
    await updateCompanyName();
  };

  const updateCompanyName = async () => {
    const companyNameRef = doc(db, "adminUsers", adminUID);
    await updateDoc(companyNameRef, {
      name: newCompanyName,
    });
  };


  return (
    <div class="hero min-h-full drop-shadow-xl">
      <div class="hero-content flex-col lg:flex-row-reverse p-0 rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div class="card flex-shrink-0 w-full ">
          <div class="card-body p-0">
            <div className="flex justify-end 	mr-2 mt-1 cursor-pointer font-bold text-xl text-neutral-700"
            onClick={() => props.setShowEditNameModal(false)}>
              X
            </div>
            <div className="flex flex-col px-10 pb-8">
              <div className=" mb-2">
                <p className="font-bold text-[#2a303c] text-center text-4xl pb-4">
                  Update
                </p>
                <p className="text-2xl text-center">Company Name</p>
              </div>

              <div className="flex flex-row justify-between">
                <div class="form-control mt-6 mr-6 pr-2">
                  <input
                    type="text"
                    placeholder="Organization Name"
                    class="input bg-white w-full max-w-xs"
                    onChange={(e) => setNewCompanyName(e.target.value)}
                    value={newCompanyName}
                  />
                </div>
                <div class="form-control mt-6">
                  <button class="btn btn-info" onClick={handleSubmit}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCompanyName;
