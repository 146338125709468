import React from "react";
import { Link } from "react-router-dom";

function SubscriptionProducts() {
  return (
    <div>
      <div>SubscriptionProducts</div>
      <Link to={"/admin/AcceptPayment"}>
        <div>Subscribe to Monthly Subscription</div>
      </Link>
    </div>
  );
}

export default SubscriptionProducts;
