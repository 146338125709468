import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import SecurityUserContext from "../../../context/SecurityUserContext";
import logo1 from "../../assets/logo1.png";

function Login() {
  const navigate = useNavigate();
  const { setSignedIn } = useContext(SecurityUserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredential.user) {
        navigate("/admin/SigningIn");
        setSignedIn(true);
      }
    } catch (error) {
      toast.error("Incorrect User Credentials");
      console.log(error);
    }
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse sm:w-[750px]">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Login now!</h1>
          <p className="py-6 w-80">Login to access your admin control panel.</p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <div className="card-body">
            <div className="flex justify-center		">
              <img src={logo1} alt="Logo Image" height={50} width={30} />
            </div>
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  className="input input-bordered"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  type="text"
                  placeholder="password"
                  id="password"
                  value={password}
                  onChange={onChange}
                  className="input input-bordered"
                />

                <label className="label">
                  <Link to={"/admin/forgotpassword"}>
                    <span className="label-text-alt link link-hover text-accent">
                      Forgot password?
                    </span>
                  </Link>
                </label>
              </div>
              <div className="form-control mt-6">
                <button className="btn btn-primary">Login</button>
              </div>
            </form>
            <Link to={"/admin/AcceptPayment"}>
              <div className="text-center mt-5 text-lg"> Sign Up </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

// <Loader className="justify-center mb-2" />
