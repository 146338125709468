import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase.config";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDoc,
  setDoc,
  addDoc,
  doc,
  onSnapshot,
  DocumentReference,
} from "firebase/firestore";
import { auth } from "../firebase.auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { async } from "@firebase/util";
import axios from "axios";

const SecurityUserContext = createContext();

export const SecurityUserProvider = ({ children }) => {
  let auth = getAuth();
  const [securityUser, setSecurityUser] = useState(null);
  const [allIncidents, setAllIncidents] = useState(null);
  const [allIncidentReplies, setAllIncidentReplies] = useState(null);
  const [signinClicked, setSignInClicked] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [signout, setSignout] = useState(false);
  const [adminUID, setAdminUID] = useState(null);
  const [adminUser, setAdminUser] = useState({});
  const [adminUserDetails, setAdminUserDetails] = useState(null)
  const [companyName, setCompanyName] = useState("");
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [neighborhoodIds, setNeighborhoodIds] = useState(null);
  const [neighborhoodSnapshot, setNeighborhoodSnapshot] = useState(null);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [neighborhoodCreated, setNeighborhoodCreated] = useState(true);
  const [neighborhoodBeingCreated, setNeighborhoodBeingCreated] =
    useState(false);
  const [newDataAvailable, setNewDataAvailable] = useState(false);
  const [neighborhoodID, setNeighborhoodID] = useState(null);
  const [dataFromStorage, setDataFromStorage] = useState({
    email: null,
    userID: null,
    companyName: null,
  });
  const [activitySummaryLoaded, setActivitySummaryLoaded] = useState(true);
  const [discordWebhookURL, setDiscordWebhookURL] = useState(null);
  const [neighborhoodsLoaded, setNeighborhoodsLoaded] = useState(false);
  const emptyArray = [];
  const [userStrInfo, setUserStrInfo] = useState(null);
  const [createNewUser, setCreateNewUser] = useState(false);
  const [adminUpdated, setAdminUpdated] = useState(false)
  const [newNeighborhoodData, setNewNeighborhoodData] = useState(null)
  const [neighborhoodJustCreated, setNeighborhoodJustCreated] = useState(false)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [userSubscribed, setUserSubscribed] = useState(null)
  const [stripePurchaseId, setStripePurchaseId] = useState(null)

  //getting list of subscribers from stripe and checking if user is subscribed.
  const getUserStatusInStripe = async() => {
    if (adminUserDetails !== null) {
      let tempArray = []
      let tempSubed = null
      await axios
      .get("/getUserStatusInStripe")
      .then((response) => {
        console.log(response.data)
        console.log(adminUserDetails.stripeCustId)
        let tempSubscriberList = response.data
        tempSubscriberList.map((item, i) => {
          tempArray.push(item.customer)
        })
        //if user subscribed it will return true, else false
        tempSubed = (tempArray.includes(adminUserDetails.stripeCustId))
        setUserSubscribed(tempSubed)
        console.log(tempSubed)
      })
      tempSubed = null
    }
  }

  useEffect(() => {
    if (stripePurchaseId !== null) {
      console.log(stripePurchaseId)
    }
  }, [stripePurchaseId])

  useEffect(() => {
    if (adminUserDetails !== null) {
      getUserStatusInStripe()
    }
  }, [adminUserDetails])

  const createNewNeighborhood = async (name) => {
    const tempName = {
      name: name,
      date: Date.now(),
      adminUID: adminUID,
    };

    await axios
      .get("/createNeighborhood", { params: { data: tempName } })
      .then((response) => {
        console.log("neighborhood data sent");
        console.log(response.data);
        setNeighborhoodBeingCreated(false);
        setNewNeighborhoodData(response.data);
        setNeighborhoodJustCreated(true);
      });
  };

  //Gets all neighborhoods for current adminUID with real-time listener for changes
  //  in firestore.  If changes made to DB it will be pushed down automatically to variable
  //  WORKS PERFECTLY...  USE THIS FOR ALL OTHER QUERIES
  useEffect(() => {
    if (adminUID !== "") {
      const neighborhoodRef = collection(db, "neighborhoods");
      const q = query(neighborhoodRef, where("adminUID", "==", adminUID));
      onSnapshot(q, (snapshot) => {
        let tempNeighborhoods = [];
        let tempNeighborhoodIds = [];
        snapshot.docs.forEach((doc) => {
          tempNeighborhoods.push({ ...doc.data(), id: doc.id });
          tempNeighborhoodIds.push({
            id: doc.id,
            neighborhoodName: doc.data().name,
          });
        });
        console.log(tempNeighborhoods);
        console.log(tempNeighborhoodIds);
        setNeighborhoods(tempNeighborhoods);
        setNeighborhoodIds(tempNeighborhoodIds);
        setNeighborhoodsLoaded(true);
      });
    }
  }, [adminUID]);

  //Gets all neighborhood incidents that this admin is associated with.
  //  this useEffect sets the trigger that new data is available
  //  this will trigger call to fetch data from firestore.
  //  Fix in future to work with doc.data() to only updata arrays with changed data.
  useEffect(() => {
    if (neighborhoodIds !== null) {
      console.log(neighborhoodIds);
      neighborhoodIds.forEach((item, i) => {
        const neighborhoodRef = collection(
          db,
          "neighborhoods",
          item.id,
          "incidents"
        );
        const q = query(neighborhoodRef);
        onSnapshot(q, (snapshot) => {
          snapshot.docs.forEach((doc) => {});
          console.log("new data available set");
          setNewDataAvailable(!newDataAvailable);
          populateNeighborhoodData();
        });
      });
    }
  }, [neighborhoods, neighborhoodID]);

  //gets all neighborhood incidents and stores them into allIncidents hook
  const populateNeighborhoodData = async () => {
    console.log("populate called");
    let tempArray = [];
    if (neighborhoods !== "") {
      neighborhoodIds.forEach(async (item) => {
        const q = query(collection(db, "neighborhoods", item.id, "incidents"));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc, i) => {
          //adding neighborhood ID to allincidents hook
          tempArray.push({
            neighborhoodID: item.id,
            id: doc.id,
            ...doc.data(),
          });
          // console.log(doc.data());
          // console.log(doc.id, " => ", doc.data());
        });
        setAllIncidents(tempArray);
      });
    }
  };

  //Real-time Snapshot trigger that calls for a fetch of neighborhood replies
  useEffect(() => {
    if (neighborhoodIds !== null) {
      neighborhoodIds.forEach((item, i) => {
        const neighborhoodRef = collection(
          db,
          "neighborhoods",
          item.id,
          "incidentReplies"
        );
        const q = query(neighborhoodRef);
        onSnapshot(q, (snapshot) => {
          snapshot.docs.forEach((doc) => {});
          setNewDataAvailable(!newDataAvailable);
          populateNeighborhoodReplies();
        });
      });
    }
  }, [neighborhoods, neighborhoodID]);

  //gets all neighborhood incident replies and stores them into allIncidentReplies
  const populateNeighborhoodReplies = async () => {
    console.log("populate called");
    let tempArray = [];
    if (neighborhoods !== "") {
      neighborhoodIds.forEach(async (item) => {
        const q = query(
          collection(db, "neighborhoods", item.id, "incidentReplies")
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc, i) => {
          tempArray.push({
            neighborhoodID: item.id,
            id: doc.id,
            ...doc.data(),
          });
          // console.log(doc.data());
          // console.log(doc.id, " => ", doc.data());
        });
        setAllIncidentReplies(tempArray);
      });
    }
  };

  useEffect(() => {
    if (adminUID !== null) {
      const adminRef = collection(
        db,
        "adminUsers",
      );
      const q = query(adminRef);
        onSnapshot(q, (snapshot) => {
          snapshot.docs.forEach((doc) => {});
          getAdminDetailsFirestoreUpdate();
        })
      // onSnapshot(doc(db, "adminUsers", adminUID), (doc) => {
      //   console.log(doc.data())
      // })
    }
  }, [adminUID])

  //testing values stored in hooks
  useEffect(() => {
    if (allIncidents !== "") {
      console.log(allIncidents);
    }
  }, [allIncidents]);

  useEffect(() => {
    if (allIncidentReplies !== "") {
      console.log(allIncidentReplies);
    }
  }, [allIncidentReplies]);

  //BEGIN Setting user info into vars and creating adminUsers in Firestore when signing up.
  useEffect(() => {
    auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        setSignedIn(true);
        setAdminUID(user.uid);
        setAdminUser(user.reloadUserInfo);
      }
    });
  }, [signedIn]);

  //specially designed useEffect to only be called and work when it is a new user
  //first time sign in.
  useEffect(() => {
    if ((createNewUser === true) && (userStrInfo !== null)) {
      auth = getAuth();
      console.log(userStrInfo)
      auth.onAuthStateChanged((user) => {
        if (user) {
          console.log(user);
          setSignedIn(true);
          setAdminUID(user.uid);
          setAdminUser(user.reloadUserInfo);
          sendUserInfoToFirebaseDB(user);
        }
      });
    }
  }, [createNewUser, userStrInfo]);

  //sending user login information to Firestore DB
  //first checking if user already exists, if not create it, else do nothing
  //Also runs check if stripe user is in app userStrInfo
  const sendUserInfoToFirebaseDB = async (user) => {
    console.log(companyName)
    const tempUserInfo = {
      email: user.reloadUserInfo.email,
      accountCreated: user.reloadUserInfo.createdAt,
      email: userStrInfo.customer_details.email,
      name: companyName,
      signupName: userStrInfo.customer_details.name,
      stripeCustId: userStrInfo.customer,
    };
      try {
        console.log("The doc doesn't exist");
        if (userStrInfo !== null) {
          console.log("called at 256")
          await setDoc(doc(db, "adminUsers", user.uid), tempUserInfo);
          console.log("Admin user info sent to firestore db");
          console.log(companyName)
          setCreateNewUser(false);
        }
      } catch (error) {
        console.log(error);
      }
  };
  //END Setting user info into vars and creating adminUsers in Firestore

  // //BEGIN LOGGIN IN AND OUT USER
  useEffect(() => {
    if (auth.currentUser !== null && signedIn) {
      console.log("called at 325")
      setSecurityUser({
        email: auth.currentUser.reloadUserInfo.email,
        uid: auth.currentUser.reloadUserInfo.localId,
        validSince: auth.currentUser.reloadUserInfo.validSince,
        name: companyName,
      });
      setSignedIn(true);
      getAdminDetailsFirestore(auth.currentUser.reloadUserInfo.localId);
    }
    if (auth !== null) {
      console.log(auth);
    }
    console.log(auth.lastNotifiedUid);
    // console.log(user);
  }, [signedIn]);

  const getAdminDetailsFirestore = async (uid) => {
    const adminDetails = await getDoc(doc(db, "adminUsers", uid))
    setAdminUserDetails(adminDetails.data())
  }

  const getAdminDetailsFirestoreUpdate = async () => {
    const adminDetails = await getDoc(doc(db, "adminUsers", adminUID))
    setAdminUserDetails(adminDetails.data())
  }

  useEffect(() => {
    if (adminUserDetails !== null) {
      console.log(adminUserDetails)
    }
  }, [adminUserDetails])

  //pulling data from storage
  useEffect(() => {
    auth = getAuth();
    if (window.localStorage.getItem("securityAdminEmail") !== null) {
      setDataFromStorage({
        ...dataFromStorage,
        email: window.localStorage.getItem("securityAdminEmail"),
      });
      setDataFromStorage({
        ...dataFromStorage,
        userID: window.localStorage.getItem("securityAdminUID"),
      });
      setDataFromStorage({
        ...dataFromStorage, 
        name: window.localStorage.getItem("securityCompanyName"),
      });
      console.log("data from storage was written to hook");
    }
    

  }, []);

  //Storing user information into local storage if local storage is empty
  //  and securityUser has the user information
  useEffect(() => {
    const tempEmail = window.localStorage.getItem("securityAdminEmail");
    if (tempEmail === null && securityUser !== null) {
      window.localStorage.setItem("securityAdminEmail", securityUser.email);
      window.localStorage.setItem("securityAdminUID", securityUser.uid);
      window.localStorage.setItem("securityCompanyName", securityUser.name);
    }
  }, [securityUser]);

  //logging out user and setting variables
  useEffect(() => {
    if (signout) {
      auth.signOut();
      setSignedIn(false);
      setUserSubscribed(null);
      window.localStorage.removeItem("securityAdminEmail");
      window.localStorage.removeItem("securityAdminUID");
      window.localStorage.removeItem("securityCompanyName");
      setSignout(false);
    }
  }, [signout]);
  //END LOGGIN IN AND OUT USER

  //trying to get fresh admin info
  useEffect(() => {
    if(adminUID !== null) {
      getAdminDetailsFirestore(adminUID)
    }
  }, [adminUpdated])


  return (
    <SecurityUserContext.Provider
      value={{
        securityUser,
        signedIn,
        adminUser,
        adminUID,
        neighborhoods,
        dataUpdated,
        neighborhoodCreated,
        allIncidents,
        activitySummaryLoaded,
        allIncidentReplies,
        neighborhoodID,
        discordWebhookURL,
        neighborhoodsLoaded,
        companyName,
        neighborhoodBeingCreated,
        userStrInfo,
        createNewUser,
        adminUserDetails,
        adminUpdated,
        newNeighborhoodData,
        neighborhoodJustCreated,
        neighborhoodIds,
        globalLoading,
        userSubscribed,
        stripePurchaseId,
        setDataUpdated,
        setSecurityUser,
        setSignInClicked,
        setSignedIn,
        setSignout,
        createNewNeighborhood,
        setNeighborhoodCreated,
        setActivitySummaryLoaded,
        setNeighborhoodID,
        setDiscordWebhookURL,
        setCompanyName,
        setNeighborhoodBeingCreated,
        setUserStrInfo,
        setCreateNewUser,
        getAdminDetailsFirestore,
        setAdminUpdated,
        setNeighborhoodJustCreated,
        setGlobalLoading,
        setUserSubscribed,
        setStripePurchaseId,
      }}
    >
      {children}
    </SecurityUserContext.Provider>
  );
};

export default SecurityUserContext;

// To test server with Fetch, it works.  I'll use this to call the local server to make backend request.
// useEffect(() => {
//   fetch("/api")
//     .then((response) => response.json())
//     .then((data) => {
//       console.log(data);
//     });
// }, []);

//Create a new neighborhood called from AdminHome.js
