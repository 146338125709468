import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import IncidentsContext from "../context/IncidentsContext";
import { ImInfo } from "react-icons/im";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { add, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { doc, addDoc, Timestamp, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase.config";
import { storage } from "../firebase.storage";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import Loader from "../components/Loader";
import Discord from "../components/Discord";
import axios from "axios";

function Reportincident() {
  const navigate = useNavigate();
  const { nid: paramNeighborhoodID, id: paramMatchId } = useParams();
  const {
    setRefreshData,
    setNewDataReceived,
    getAllIncidents,
    neighborhoodID,
    discordWebhookURL,
    neighborhoodName,
    setNeighborhoodID,
  } = useContext(IncidentsContext);
  const todaysDate = new Date().toLocaleString();
  const [dateWasSelected, setDateWasSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [incidentDate, setIncidentDate] = useState(null);
  const [incidentDateText, setIncidentDateText] = useState("");
  const [reporterName, setReporterName] = useState("");
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [descriptionOfIncident, setDescriptionOfIncident] = useState("");
  const [eventType, setEventType] = useState("");
  const [pOneImage, setPOneImage] = useState(null);
  const [pTwoImage, setPTwoImage] = useState(null);
  const [checkedPolice, setCheckedPolice] = useState(false);
  const [checkedSecurity, setCheckedSecurity] = useState(false);
  const [checkedAssociation, setCheckedAssociation] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const [helpFromOthers, setHelpFromOthers] = useState(false);
  const [allNotifiedParties, setAllNotifiedParties] = useState([]);
  const initialState = [];
  const [imageURLs, setImageURLs] = useState([]);
  const [submitBtnPushed, setSubmitBtnPushed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { send } = Discord();
  const [currentURLBase, setCurrentURLBase] = useState("");

  //Setup to check if user dropped in from home screen or a link.
  //  If a link then it will set neighborhoodID and trigger fetch firestore
  useEffect(() => {
    console.log(neighborhoodID);
    if (neighborhoodID === null) {
      setNeighborhoodID(paramNeighborhoodID);
    }
  }, []);

  useEffect(() => {
    let pathURL = window.location.href;
    let pathSplit = pathURL.split("/");
    setCurrentURLBase(pathSplit[2]);
    console.log(pathSplit);
  }, []);

  useEffect(() => {
    console.log(currentURLBase);
  }, [currentURLBase]);

  const handleFileBtnClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const fileUploaded = event.target.files;
    console.log(fileUploaded);
    setFileSelected([...fileUploaded]);
  };

  const handleSelectEventChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "DEFAULT") {
      setEventType("Other");
    } else {
      setEventType(e.target.value);
    }
  };

  const handleCheckChange = (e) => {
    console.log(e.target.value, e.target.checked);
    if (e.target.checked) {
      setAllNotifiedParties([...allNotifiedParties, e.target.value]);
    } else {
      setAllNotifiedParties(
        allNotifiedParties.filter((item) => item !== e.target.value)
      );
    }
  };

  useEffect(() => {
    console.log(allNotifiedParties);
  }, [allNotifiedParties]);

  const handleCalendarClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleCalendarChange = (e) => {
    setIsOpen(!isOpen);
    setIncidentDate(e);
  };

  useEffect(() => {
    if (incidentDate !== null) {
      setDateWasSelected(true);
      setIncidentDateText(format(incidentDate, "MM/dd/yyyy"));
    }
  }, [incidentDate]);

  useEffect(() => {
    console.log(checkedPolice);
  }, [checkedPolice]);

  const submitClicked = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    setSubmitBtnPushed(true);
    uploadImages();
  };

  //Sending incident to firestore then getting firestoreID and creating a discord post with webhook about incident.
  const sendingIncidentFirebaseDiscord = async () => {
    try {
      const fireStoreID = await sendIncidentToFirebase();
      console.log(fireStoreID);
      await sendByDiscordWebhook(fireStoreID);
      await sendSMSToBackend(fireStoreID);
    } catch (err) {
      console.log(err);
    }
  };

  //Sending SMS texts
  const sendSMSToBackend = async (fireStoreID) => {
    let incidentURL = `http://${currentURLBase}/${neighborhoodID}/incidentdetails/${fireStoreID}`;
    let message =
      "There is a new INCIDENT reported in " + neighborhoodName + "\n";
    let eventTypeMessage = "The incident is a " + eventType + " \n";
    axios
      .get("/api/sendIncidentReplySMS", {
        params: {
          message: message,
          neighborhoodID: neighborhoodID,
          incidentID: paramMatchId,
          eventTypeMessage: eventTypeMessage,
          incidentURL: incidentURL,
        },
      })
      .then((response) => {
        console.log(response.data);
      });
  };

  const sendByDiscordWebhook = async (fireStoreID) => {
    console.log("triggered at 131");
    const discordDataObject = {
      url: `http://${currentURLBase}/${neighborhoodID}/incidentdetails/${fireStoreID}`,
      authorName: "Incident Link",
      content: "New Incident",
      title: eventType,
      descriptionOfIncident: descriptionOfIncident,
      footer: helpFromOthers,
    };
    send(discordDataObject);
  };

  const sendIncidentToFirebase = () => {
    return new Promise((resolve) => {
      let tempDate = null;
      if (incidentDate === null) {
        tempDate = Timestamp.now();
      }
      if (incidentDate !== null) {
        tempDate = incidentDate;
      }

      if (incidentDate === null) {
        setIncidentDate(Timestamp.now());
      }
      const docData = {
        dateReported: Timestamp.now(),
        reporterName: reporterName,
        locationOfIncident: locationOfIncident,
        descriptionOfIncident: descriptionOfIncident,
        requestingHelp: helpFromOthers,
        dateOfIncident: tempDate,
        whoWasNotified: allNotifiedParties,
        imageURLs: imageURLs,
        eventType: eventType,
      };
      addDoc(
        collection(db, "neighborhoods", paramNeighborhoodID, "incidents"),
        docData
      ).then((res) => {
        console.log(res.id);
        setShowLoader(false);
        toast.success(`Your incident report was received!`);
        navigate(`/${paramNeighborhoodID}`);
        resolve(res.id);
      });
    });
  };

  const uploadImages = async () => {
    if (fileSelected === null) {
      return;
    } else {
      const date = Date.now();

      await fileSelected.forEach((file, index) => {
        const storageRef = ref(storage, `${neighborhoodID}/${file.name}`);
        // const metadata = {
        //   contentType: file.type
        // }
        uploadBytes(storageRef, file).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setImageURLs((imageURLs) => [...imageURLs, url]);
            })
            .then(() => {
              getAllIncidents();
            });
        });
      });
    }
  };

  //sends reply with image to firestore
  useEffect(() => {
    if (imageURLs && fileSelected !== null) {
      if (imageURLs.length === fileSelected.length && submitBtnPushed) {
        // sendIncidentToFirebase();
        sendingIncidentFirebaseDiscord();
        console.log("sending to firebase now");
        resetVariables();
      }
    }
  }, [imageURLs]);

  //submits incident without image
  useEffect(() => {
    if (fileSelected === null && submitBtnPushed) {
      // sendIncidentToFirebase();
      sendingIncidentFirebaseDiscord();
      console.log("sending to firebase with no images");
      resetVariables();
    }
  }, [submitBtnPushed]);

  const resetVariables = () => {
    setReporterName("");
    setHelpFromOthers(false);
    setCheckedPolice(false);
    setCheckedSecurity(false);
    setCheckedAssociation(false);
    setLocationOfIncident("");
    setDescriptionOfIncident("");
    setAllNotifiedParties(initialState);
  };

  const handleCancel = () => {
    navigate(`/${paramNeighborhoodID}`);
  };

  return (
    <div>
      <div class="hero min-h-screen bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1] sm:px-[75px]">
        <div class={`hero-content flex-row ${showLoader ? " blur-sm" : ""}`}>
          <div class="w-full shadow-2xl bg-[#ffffff] p-5 rounded-xl">
            {neighborhoodName !== null ? (
              <h2 className="text-center text-3xl mb-4 mt-2 text-slate-950 sm:text-base-100">
                {neighborhoodName}
              </h2>
            ) : null}
            <h1 className="text-center text-4xl mb-4">Report A New Incident</h1>
            <form>
              <div className="grid grid-rows-1 sm:grid-rows-5">
                <div className="row-span-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 ">
                    <div className="col-span-1 w-full">
                      <div className="form-control mb-4">
                        <select
                          class="select bg-neutral-content/25 text-[#2a303c] w-full border border-slate-400 sm:border-none"
                          onChange={handleSelectEventChange}
                        >
                          <option
                            value="DEFAULT"
                            className="bg-neutral-content/25 text-[#2a303c]"
                            disabled
                            selected
                          >
                            Type of incident
                          </option>
                          <option value="Theft">Theft</option>
                          <option value="Burglary">Burglary</option>
                          <option value="Suspicious Behavior">
                            Suspicious Behavior
                          </option>
                          <option value="Robbery">Robbery</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div class="form-control mb-2">
                        <input
                          type="text"
                          placeholder="Your Name (optional)"
                          value={reporterName}
                          onChange={(e) => setReporterName(e.target.value)}
                          className="flex input w-full bg-neutral-content/25 text-[#2a303c] border border-slate-400 sm:border-none"
                        />
                      </div>
                      <div className="bg-neutral-content/25 text-[#2a303c] rounded-md p-2 border border-slate-400 sm:border-none">
                        <span>Who was notified</span>
                        <div class="form-control pl-2">
                          <label className="cursor-pointer label">
                            <span className="label-text text-[#2a303c]">
                              Police
                            </span>
                            <input
                              type="checkbox"
                              onChange={handleCheckChange}
                              className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                              value="Police"
                            />
                          </label>
                        </div>
                        <div class="form-control pl-2">
                          <label class="cursor-pointer label">
                            <span class="label-text text-[#2a303c]">
                              Private Security
                            </span>
                            <input
                              type="checkbox"
                              onChange={handleCheckChange}
                              className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                              value="Security"
                            />
                          </label>
                        </div>
                        <div className="form-control pl-2">
                          <label class="cursor-pointer label">
                            <span class="label-text text-[#2a303c]">
                              Home Association
                            </span>
                            <input
                              type="checkbox"
                              onChange={handleCheckChange}
                              className="checkbox checkbox-info text-[#2a303c] border-[#2a303c]"
                              value="Association"
                            />
                          </label>
                        </div>
                      </div>
                      <div class="form-control mb-2">
                        <label class="label">
                          <div className="flex flex-row">
                            <span class="label-text ">
                              Location of Incident
                            </span>
                            <div
                              className="tooltip ml-2"
                              data-tip="Location closest to incident - street address or cross streets"
                            >
                              <ImInfo />
                            </div>
                          </div>
                        </label>
                        <input
                          type="text"
                          placeholder="Location"
                          value={locationOfIncident}
                          onChange={(e) =>
                            setLocationOfIncident(e.target.value)
                          }
                          className="flex input w-full bg-neutral-content/25 text-[#2a303c] border border-slate-400 sm:border-none"
                        />
                      </div>
                    </div>

                    <div className="col-span-1 w-full place-content-between">
                      <div class=" form-control mb-4">
                        <textarea
                          class="textarea bg-neutral-content/25 text-[#2a303c] border border-slate-400 sm:border-none"
                          placeholder="Describe the incident here"
                          value={descriptionOfIncident}
                          onChange={(e) =>
                            setDescriptionOfIncident(e.target.value)
                          }
                        ></textarea>
                      </div>
                      <div className="rounded-md p-2 bg-neutral-content/25 text-[#2a303c] sm:mb-6">
                        <span>
                          Would you like help from others to solve incident?
                        </span>
                        <div class="form-control">
                          <label className="cursor-pointer label">
                            <input
                              type="checkbox"
                              className="toggle mr-2"
                              checked={helpFromOthers}
                              onChange={(e) =>
                                setHelpFromOthers(e.target.checked)
                              }
                            />
                            {helpFromOthers ? (
                              <span className="label-text mr-auto text-[#2a303c]">
                                Yes
                              </span>
                            ) : (
                              <span className="label-text mr-auto text-[#2a303c]">
                                No
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      <div class="form-control sm:mb-5">
                        <label class="label">
                          <span class="label-text">
                            Date incident happened (click calendar)
                          </span>
                        </label>
                        <div className="flex flex-row place-content-start w-full text-[#2a303c]">
                          <BsCalendar
                            style={{ fontSize: "48px" }}
                            onClick={handleCalendarClick}
                          />
                          {isOpen && (
                            <DatePicker
                              selected={incidentDate}
                              onChange={handleCalendarChange}
                              inline
                            />
                          )}
                          {dateWasSelected ? (
                            <span className="my-auto ml-2.5">
                              {incidentDateText}
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-control mb-2">
                        <label className="mb-1">
                          <div className="flex flex-row">
                            <div className="label-text">
                              Upload videos or pictures of the incident
                            </div>
                            <div
                              className="tooltip ml-2"
                              data-tip="Large video files will take some time to upload, please don't close screen until done"
                            >
                              <ImInfo />
                            </div>
                          </div>
                        </label>
                        <button
                          class="btn sm:bg-neutral-content/25 text-white sm:text-[#2a303c] border-none sm:hover:text-white"
                          onClick={handleFileBtnClick}
                        >
                          Upload Files
                        </button>
                        <input
                          type="file"
                          className=""
                          ref={hiddenFileInput}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          multiple
                        />
                        {fileSelected !== null
                          ? fileSelected.map(function (item, i) {
                              return <div key={item.name + i}>{item.name}</div>;
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row-span-1 form-control mt-2 border-t-2">
                  <div className="flex flex-row mt-4">
                    <div className="w-1/2 pr-2">
                      <button
                        class="btn sm:btn-outline w-full border-none sm:bg-neutral-content text-white sm:text-black "
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="w-1/2 pl-2">
                      <button
                        class="btn btn-info w-full"
                        style={{ position: "relative" }}
                        onClick={submitClicked}
                      >
                        Submit Incident
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showLoader ? (
        <div className="flex fixed inset-0 items-center	justify-center">
          <div className="flex flex-col items-center text-[#2a303c]">
            <div className="text-4xl font-bold">Uploading Items Now</div>
            <div className="text-2xl">Please wait</div>
            <div className="text-xsm mb-4">
              Larger files will take longer to upload
            </div>
            <Loader />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Reportincident;
