import React, { useState, useEffect, useContext } from "react";
import { getAuth } from "firebase/auth";
import SecurityUserContext from "../../../context/SecurityUserContext";
import { useNavigate } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineLogout, AiOutlineDown } from "react-icons/ai";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { VscGear } from "react-icons/vsc";
import CreateNeighborhood from "./AdminComponents/CreateNeighborhood";
import AdjustSMSNotifications from "./AdminComponents/AdjustSMSNotifications";
import BarLoader from "react-spinners/BarLoader";
import ActivityCard from "./AdminComponents/ActivityCard";
import ActivitySummaryCard from "./AdminComponents/ActivitySummaryCard";
import Popup from "reactjs-popup";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Loader from "../../components/Loader";
import { differenceInDays } from "date-fns";
import logo1 from "../../assets/logo1.png";
import { GiHouse } from "react-icons/gi";
import { CgAddR } from "react-icons/cg";
import { IoMdSettings } from "react-icons/io";
import { AiOutlinePoweroff } from "react-icons/ai";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { FaHome, FaPeopleArrows } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GiPoliceBadge, GiPoliceCar } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import {
  GiCarnivalMask,
  GiSwapBag,
  GiArrest,
  GiDominoMask,
} from "react-icons/gi";
import NewNeighborhoodInfoModal from "./AdminComponents/NewNeighborhoodInfoModal";
import AdminHomeSettings from "./AdminComponents/AdminHomeSettings";
import SignOutConfirm from "./AdminComponents/SignOutConfirm";
import EditCompanyName from "./AdminComponents/EditCompanyName";
import neighborhoodImage from "../../assets/welcomeneighborhood.jpg"

function AdminHome() {
  const {
    signedIn,
    setSignedIn,
    setSignout,
    companyName,
    createNewNeighborhood,
    neighborhoodBeingCreated,
    neighborhoodCreated,
    setNeighborhoodCreated,
    neighborhoods,
    allIncidents,
    allIncidentReplies,
    neighborhoodsLoaded,
    adminUserDetails,
    newNeighborhoodData,
    neighborhoodJustCreated,
    setNeighborhoodJustCreated,
    setGlobalLoading,
    userSubscribed,
  } = useContext(SecurityUserContext);
  const auth = getAuth();
  const navigate = useNavigate();
  const [openCreateNeighborhood, setOpenCreateNeighborhood] = useState(false);
  const [openSMSNotificationSettings, setOpenSMSNotificationSettings] =
    useState(false);
  const [neighborhoodName, setNeighborhoodName] = useState("");
  const [allActivityLoaded, setAllActivityLoaded] = useState(true);
  const [allIncidentsDateFiltered, setAllIncidentsDateFiltered] =
    useState(null);
  const [incidentsWithReplies, setIncidentsWithReplies] = useState(null);
  const [isDateFilterOpened, setIsDateFilterOpened] = useState("false");
  const [dateFilter, setDateFilter] = useState(10);
  const [isReplyFilterOpened, setIsReplyFilterOpened] = useState("false");
  const [showReplies, setShowReplies] = useState(false);
  const [incidentAndReplies, setIncidentAndReplies] = useState([]);
  const [dateSorter, setDateSorter] = useState(false);
  const [listToShow, setListToShow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [numIncidentsLast24HoursAllNeigh, setNumIncidentsLast24HoursAllNeigh] =
    useState(null);
  const [numRepliesLast24HoursAllNeigh, setNumRepliesLast24HoursAllNeigh] =
    useState(null);
  const [newNeighborhood, setNewNeighborhood] = useState(null);
  const [typesLast24, setTypesLast24] = useState([]);
  const [leftMenuSelection, setLeftMenuSelection] = useState("activity");
  const [middleSectionToShow, setMiddleSectionToShow] = useState("activity");
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);

  //check if no user exists and take them to signin screen
  useEffect(() => {
    if (
      window.localStorage.getItem("securityAdminEmail") === null &&
      auth.currentUser === null
    ) {
      navigate("/admin/securityservices/signin");
    }
    console.log("Testing admin deploy")
  }, []);

  

  //check if user has a current subscription.  If not, then take to accept payment page
  useEffect(() => {
    setTimeout(() => {
      if (userSubscribed === false) {
        navigate("/admin/AcceptPayment");
      }
    }, 3000)
    
  }, [userSubscribed]);

  //Check if companyname has value
  useEffect(() => {
    if (companyName !== null) {
      console.log(companyName);
    }
  }, [companyName]);

  //sort All activity
  useEffect(() => {
    let tempArray = null;
    //setting gloabalLoading to false to reset loader if coming from stripe customer portal
    setGlobalLoading(false);
    if (allIncidents !== null) {
      tempArray = allIncidents;
      tempArray.sort((a, b) => b.dateReported - a.dateReported);
      setAllIncidentsDateFiltered([...tempArray]);
      setLoading(false);

      //get count of all incidents in last 24 here
      const todaysDate = new Date();
      let tempIncidentCount = 0;
      let tempTypes = [];
      allIncidents.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference === 0) {
          tempIncidentCount = tempIncidentCount + 1;
          tempTypes.push(item.eventType);
        }
      });
      setNumIncidentsLast24HoursAllNeigh(tempIncidentCount);
      setTypesLast24(tempTypes);
    }
  }, [allIncidents]);

  //If new neighborhood is created, load it's data into newNeighborhood
  useEffect(() => {
    if (newNeighborhoodData !== null) {
      setNewNeighborhood(newNeighborhoodData);
    }
  }, [neighborhoodJustCreated]);

  //Get List to display in Activity
  useEffect(() => {
    let tempArray = null;
    if (showReplies) {
      if (allIncidents !== null && allIncidentReplies !== null) {
        setDateSorter(!dateSorter);
      }
    }
    if (!showReplies) {
      setDateSorter(!dateSorter);
    }
  }, [showReplies, allIncidents, allIncidentReplies]);

  //Filter functions BEGIN
  //Sort incidents by recent to oldest
  useEffect(() => {
    let tempArray = null;
    if (allIncidents !== null && showReplies === false) {
      tempArray = allIncidents;
      tempArray.sort((a, b) => b.dateReported - a.dateReported);
      setListToShow([...tempArray]);
      setLoading(false);
    }
  }, [dateSorter]);

  //Filter functions End

  //To show results
  useEffect(() => {
    if (allIncidents !== null && allIncidentReplies !== null) {
    }
  }, [incidentAndReplies, listToShow]);

  //get list of incidents that have a reply
  //get count of replies in last 24 hours
  useEffect(() => {
    let tempArray = [];
    if (allIncidentReplies !== null) {
      allIncidentReplies.map((item) => {
        tempArray.push(item.incidentUID);
      });
      setIncidentsWithReplies(tempArray);

      //getting count of replies last 24 hours
      const todaysDate = new Date();
      let tempRepliesCount = 0;
      allIncidentReplies.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference === 0) {
          tempRepliesCount = tempRepliesCount + 1;
        }
      });
      setNumRepliesLast24HoursAllNeigh(tempRepliesCount);
    }
  }, [allIncidentReplies]);

  useEffect(() => {
    if (allIncidentsDateFiltered !== null) {
    }
  }, [allIncidentsDateFiltered]);

  //Useeffect to call create new neighborhood
  useEffect(() => {
    if (neighborhoodName !== "" && neighborhoodCreated === false) {
      createNewNeighborhood(neighborhoodName);
    }
  }, [neighborhoodName]);

  useEffect(() => {}, [neighborhoods]);

  const handleActivityClick = (item) => {
    navigate(`/admin/${item.neighborhoodID}/incidentDetails/${item.id}`);
  };

  const handleNeighborhoodClicked = (item) => {
    navigate(`/admin/neighborhood/${item.id}`, {
      item: item,
    });
  };

  //Date Filter processing
  useEffect(() => {
    if (isDateFilterOpened === "true") {
      switch (dateFilter) {
        case 10:
          setIsDateFilterOpened("false");
          break;
        case 30:
          setIsDateFilterOpened("false");
          break;
        case 999:
          setIsDateFilterOpened("false");
          break;
      }
    }
  }, [isDateFilterOpened]);

  //Show Reply Filter processing
  useEffect(() => {
    if (isReplyFilterOpened === "true") {
      switch (showReplies) {
        case true:
          setIsReplyFilterOpened("false");
          break;
        case false:
          setIsReplyFilterOpened("false");
          break;
      }
    }
  }, [isReplyFilterOpened]);

  const gotoReportIncident = () => {
    let path = `/admin/reportincidentmain`;
    navigate(path);
  };

  const handleSettingsClicked = () => {
    setLeftMenuSelection("settings");
    setMiddleSectionToShow("settings");
  };

  useEffect(() => {
    if (typesLast24 !== null) {
      console.log(typesLast24);
    }
  }, [typesLast24]);

  const ActivityTable = () => {
    return (
      <div className="flex flex-col h-full">
        <div className="pl-3 flex flex-col align-middle">
          <div className="mt-6 text-xl font-bold text-[#2a303c]">Activity</div>
          <div className="flex flex-row py-3 mr-2">
            <Popup
              trigger={
                <button className="btn btn-xs mr-2">
                  <div className="flex flex-row">
                    {dateFilter === 999 ? (
                      <span className="pr-1 w-20">Show All</span>
                    ) : (
                      <span className="pr-1 w-20">{`Show ${dateFilter}`}</span>
                    )}

                    <AiOutlineDown />
                  </div>
                </button>
              }
              arrow={false}
              position="bottom left"
            >
              <div className="w-40 cursor-pointer ">
                <div
                  className=" text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white rounded-t-md"
                  onClick={() => {
                    setIsDateFilterOpened("true");
                    setDateFilter(10);
                  }}
                >
                  Last 10
                </div>
                <div
                  className="text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white"
                  onClick={() => {
                    setIsDateFilterOpened("true");
                    setDateFilter(30);
                  }}
                >
                  Last 30
                </div>
                <div
                  className="text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white rounded-b-md "
                  onClick={() => {
                    setIsDateFilterOpened("true");
                    setDateFilter(999);
                  }}
                >
                  All
                </div>
              </div>
            </Popup>
          </div>
        </div>
        {allActivityLoaded ? (
          <div className="flex flex-col h-full w-full justify-start items-start px-3">
            <div class="overflow-y-auto overflow-x-auto flex items-center space-x-3a w-full">
              <table className="sm:table-auto w-full">
                <thead className="">
                  <tr className="">
                    <th>Date Reported</th>
                    <th>Time</th>
                    <th>Neighborhood Name</th>
                    <th>Incident Type</th>
                    <th>Location</th>
                    <th>Replies</th>
                  </tr>
                </thead>

                <tbody className="">
                  {listToShow && !loading
                    ? listToShow.map((item, i) => {
                        if (i <= dateFilter) {
                          return (
                            <ActivityCard
                              onClick={() => handleActivityClick(item)}
                              iVar={i}
                              item={item}
                              neighborhoods={neighborhoods}
                              incidentsWithReplies={incidentsWithReplies}
                              incidentReplies={allIncidentReplies}
                              key={item.id + item.dateReported}
                            />
                          );
                        }
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-center items-center">
            <BarLoader loading="true" color="#3abff8" />
          </div>
        )}
      </div>
    );
  };

  //When Activity is empty this will show
  const NoActivityBlock = () => {
    return (
      <div class="flex flex-col h-full hero-content text-center ">
        <div class="max-w-md">
          <h1 class="text-5xl font-bold">Activity Summary</h1>
          <p class="py-6">
            You currently do not have any activity in this neighborhood. When an
            incident occurs it will appear in this section. If you would like to
            create a new incident, click below.
          </p>
          <button
            class="btn btn-outline btn-block lg:btn-wide bg-neutral-content text-black"
            onClick={gotoReportIncident}
          >
            Post New Incident
          </button>
        </div>
      </div>
    );
  };

  //BEGIN Showing Screen

  //NOTE FOR FUTURE CHANGE.  Might need first screen of icon or whatever
  //  to show when user details are being determined if an account exists or is
  //  signed in.  I check for system credentials and auth tokens, while doing that
  //  show something.

  //setup a check for if user is logged in and if not, then navigate to signin.
  {
    /*Loading data*/
  }
  if (neighborhoodsLoaded === false || userSubscribed === null) {
    return (
      <div className="flex flex-col h-full justify-center items-center h-screen">
        <div className="stat-value mb-3">Loading...</div>
        <BarLoader loading="true" color="#3abff8" width={300} />
      </div>
    );
  }
  {
    /*User has no neighborhoods and will be shown intro with option to create first neighborhood*/
  }
  if (neighborhoodsLoaded === true && neighborhoods.length === 0) {
    return (
      <div class="hero min-h-screen bg-base-200">
        <div
          class={`hero-content flex-col lg:flex-row-reverse bg-base-300 rounded-lg ${
            openCreateNeighborhood ? "blur-sm" : ""
          }`}
        >
          <img
            src={neighborhoodImage}
            class="max-w-sm rounded-lg shadow-2xl w-[260] h-[400]"
          />
          <div>
            <h1 class="text-5xl font-bold">Welcome To A Safer Neighborhood</h1>
            <p class="py-6">
              Click the button below to create and connect your neighborhood
              with Nexus Neighbor
            </p>
            <button
              class="btn btn-wide"
              onClick={() => setOpenCreateNeighborhood(true)}
            >
              Create A Neighborhood
            </button>
          </div>
        </div>
        {openCreateNeighborhood ? (
          <CreateNeighborhood
            setModalShow={setOpenCreateNeighborhood}
            setNeighborhoodName={setNeighborhoodName}
            setNeighborhoodCreated={setNeighborhoodCreated}
          />
        ) : null}
        {neighborhoodBeingCreated ? (
          <div className="flex flex-col justify-center items-center">
            <Loader />
          </div>
        ) : null}
      </div>
    );
  } else {
    {
      /*User has a neighborhoods and normal admin screen begins here*/
    }

    //colors main dark = #2a303c, light background plate = #f8fafb
    //nice green I might use #49b977, goes well with dark blue gray
    return (
      <div className="md:hero w-full bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div className="flex items-stretch items-center	">
          <div className=" flex flex-col min-h-screen w-full py-2 px-1 sm:py-10 sm:px-10 place-content-center">
            <div
              className={`flex flex-col py-1 px-1 sm:py-5 sm:px-2 bg-[#f8fafb] rounded-xl drop-shadow-2xl ${
                openSMSNotificationSettings || openCreateNeighborhood
                  ? "blur-sm"
                  : ""
              }`}
            >
              <div className="flex flex-row items-end">
                <div className="mb-8 ml-3">
                  <img src={logo1} alt="Logo Image" height={80} width={60} />
                </div>
                <div className="flex flex-row mb-8 ml-2">
                  <div className="font-bold text-2xl text-[#0037a5]">
                    Nexus
                  </div>
                  <div className="font-bold text-2xl text-[#8315a4]">Neighbor</div>
                </div>
              </div>
              {/*Top section boxes summary last 24 hours*/}
              <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-2 md:flex-row basis-1/4 justify-between sm:mt-0 mb-2">
                <div className="flex w-full md:w-1/4 shadow-2xl bg-white sm:h-28 rounded-lg justify-start sm:justify-center">
                  <div className="self-center w-full sm:w-4/5 text-xl sm:text-2xl font-bold p-4 text-[#2a303c] rounded-xl ">
                    {adminUserDetails !== null ? adminUserDetails.name : ""}
                  </div>
                </div>
                <div className="flex w-full md:w-1/4 sm:h-28 shadow-2xl content-center w-full rounded-lg bg-white">
                  <div className="flex w-1/2 rounded-l-lg justify-start sm:justify-center">
                    <div className="self-center">
                      <p className="text-xl sm:text-2xl font-bold text-[#2a303c]">
                        Incidents
                      </p>
                      <p className="text-sm">Last 24 Hours</p>
                    </div>
                  </div>
                  <div className="flex w-1/2 content-center font-bold sm:text-lg max-w-3xl rounded-r-lg self-center	">
                    <div className="text-center w-full text-4xl text-adminConsoleText">
                      {numIncidentsLast24HoursAllNeigh !== null
                        ? numIncidentsLast24HoursAllNeigh
                        : "0"}
                    </div>
                  </div>
                </div>
                <div className="flex w-full md:w-1/4 sm:h-28 shadow-2xl content-center w-full rounded-lg bg-white">
                  <div className="flex w-1/2 rounded-l-lg justify-start sm:justify-center">
                    <div className="self-center">
                      <p className="text-xl sm:text-2xl font-bold text-[#2a303c]">
                        Replies
                      </p>
                      <p className="text-sm">Last 24 Hours</p>
                    </div>
                  </div>
                  <div className="flex w-1/2 content-center font-bold sm:text-lg max-w-3xl rounded-r-lg self-center	">
                    <div className="text-center w-full text-4xl text-adminConsoleText">
                      {numRepliesLast24HoursAllNeigh !== null
                        ? numRepliesLast24HoursAllNeigh
                        : "0"}
                    </div>
                  </div>
                </div>
                <div className="flex w-full md:w-1/4 sm:h-28 shadow-2xl content-center w-full rounded-lg bg-white">
                  <div className="flex w-1/2 rounded-l-lg justify-start sm:justify-center">
                    <div className="self-center">
                      <p className="text-xl md:text-2xl font-bold text-[#2a303c]">
                        Type
                      </p>
                      <p className="text-sm">Incident Types</p>
                    </div>
                  </div>
                  <div className="flex w-1/2 content-center font-bold sm:text-lg max-w-3xl rounded-r-lg self-center	">
                    <div className="flex flex-row flex-wrap text-center justify-center w-full text-adminConsoleText">
                      {typesLast24.length > 0 ? (
                        typesLast24.map((item, i) => {
                          return (
                            <div className="p-1">
                              {item === "Burglary" ? (
                                <div className="tooltip" data-tip="Burglary">
                                  <FaHome className="text-[24px]" />{" "}
                                </div>
                              ) : null}
                              {item === "Theft" ? (
                                <div className="tooltip" data-tip="Theft">
                                  <GiSwapBag className="text-[24px]" />{" "}
                                </div>
                              ) : null}
                              {item === "Suspicious Behavior" ? (
                                <div
                                  className="tooltip"
                                  data-tip="Suspicious Behavior"
                                >
                                  <GiDominoMask className="text-[24px]" />{" "}
                                </div>
                              ) : null}
                              {item === "Robbery" ? (
                                <div className="tooltip" data-tip="Robery">
                                  <GiArrest className="text-[24px]" />{" "}
                                </div>
                              ) : null}
                              {item === "Other" ? (
                                <div className="tooltip" data-tip="Other">
                                  <AiOutlineExclamationCircle className="text-[24px]" />{" "}
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      ) : (
                        <div>None</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="basis-3/4">
                <div
                  className={`flex flex-col md:flex-row justify-between	md:w-[1350px] w-full px-0 sm:px-2 ${
                    openCreateNeighborhood ? "blur-sm" : ""
                  }`}
                >
                  {/*Left section summaries and options*/}
                  <div className="flex flex-col bg-white drop-shadow-2xl rounded-lg px-0 sm:px-2 mt-0 sm:mt-2 w-full md:w-[260px]">
                    <div className="mt-0 sm:mt-6 pl-3  h-50  ">
                      <div className="text-xl text-[#2a303c] font-bold">
                        Admin Console
                      </div>
                    </div>
                    <div class="divider"></div>

                    <div className="flex flex-col w-full bg-neutral-content/25 rounded drop-shadow-md mb-2">
                      <div className="pt-4 pb-3 items-start">
                        <span className="text-xl font-bold text-[#2a303c] pl-1">
                          Activity Summary
                        </span>
                      </div>
                      <div className="pt-2">
                        <span className="text-lg text-[#2a303c] font-bold pl-2">
                          Last 24Hrs
                        </span>
                      </div>
                      {allIncidents !== null ? (
                        <ActivitySummaryCard />
                      ) : (
                        <div className="flex flex-col justify-center items-center">
                          <BarLoader loading="true" color="#3abff8" />
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col w-full bg-neutral-content/25 rounded drop-shadow-md mb-2">
                      <div className="pt-4 pb-3 items-start">
                        <div className="pl-2 text-xl text-[#2a303c] font-bold ">
                          Options
                        </div>
                      </div>
                      <div
                        className={`btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start mb-1 border border-black-500 ${
                          leftMenuSelection === "activity"
                            ? "font-bold text-[#2a303c]"
                            : ""
                        }`}
                        onClick={() => (
                          setLeftMenuSelection("activity"),
                          setMiddleSectionToShow("activity")
                        )}
                      >
                        <div className="mr-2">
                          <FaPeopleArrows
                            style={{ fontSize: "32px" }}
                            className={`p-1 ${
                              leftMenuSelection === "activity"
                                ? "font-bold text-[#2a303c]"
                                : ""
                            }`}
                          />
                        </div>
                        Activity
                      </div>
                      <div
                        className={`btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start mb-1 border border-black-500 ${
                          leftMenuSelection === "settings"
                            ? "font-bold text-[#2a303c]"
                            : ""
                        }`}
                        onClick={handleSettingsClicked}
                      >
                        <div className="mr-2">
                          <IoMdSettings
                            style={{ fontSize: "32px" }}
                            className={`p-1 ${
                              leftMenuSelection === "settings"
                                ? "font-bold text-[#2a303c]"
                                : ""
                            }`}
                          />
                        </div>
                        Settings
                      </div>
                      <div
                        className={`btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start mb-1 border border-black-500 ${
                          leftMenuSelection === "notificationNumbers"
                            ? "font-bold text-[#2a303c]"
                            : ""
                        }`}
                        onClick={() => (
                          setOpenSMSNotificationSettings(true),
                          setLeftMenuSelection("notificationNumbers")
                        )}
                      >
                        <div className="mr-2">
                          <BsFillChatSquareTextFill
                            style={{ fontSize: "32px" }}
                            className={`p-1 ${
                              leftMenuSelection === "notificationNumbers"
                                ? "font-bold text-[#2a303c]"
                                : ""
                            }`}
                          />
                        </div>
                        Notification Numbers
                      </div>

                      <div
                        className="btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start mb-1 border border-black-500"
                        onClick={() => setShowSignoutModal(true)}
                      >
                        <div className="mr-2">
                          <AiOutlinePoweroff
                            style={{ fontSize: "32px" }}
                            className="p-1"
                          />
                        </div>
                        Sign Out
                      </div>
                    </div>
                  </div>

                  {/*Middle section - Activity*/}

                  {allIncidents !== null &&
                  allIncidents.length === 0 &&
                  middleSectionToShow === "activity" ? (
                    <div className="mt-2 shadow-2xl bg-white rounded-xl bg-base-300 w-full md:w-[800px]">
                      <NoActivityBlock />
                    </div>
                  ) : null}
                  {allIncidents !== null &&
                  allIncidents.length === 0 &&
                  middleSectionToShow === "settings" ? (
                    <div className="mt-2 shadow-2xl bg-white rounded-xl w-full sm:w-[800px]">
                      <AdminHomeSettings
                        setShowEditNameModal={setShowEditNameModal}
                      />
                    </div>
                  ) : null}
                  {allIncidents !== null && allIncidents.length > 0 ? (
                    <div className="mt-2 shadow-2xl bg-white rounded-xl w-full sm:w-[800px]">
                      <div className="rounded">
                        {middleSectionToShow === "activity" ? (
                          <ActivityTable />
                        ) : null}
                        {allIncidents !== null &&
                        allIncidents.length > 0 &&
                        middleSectionToShow === "settings" ? (
                          <AdminHomeSettings
                            setShowEditNameModal={setShowEditNameModal}
                          />
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {/*top right section - Neighborhood list*/}
                  <div className="flex flex-col bg-white drop-shadow-2xl rounded-lg px-0 sm:px-2 mt-0 sm:mt-2 w-full md:w-[260px]">
                    <div className="flex flex-row pt-5">
                      <div className="flex w-1/2 content-center self-center my-auto">
                        <div className="flex pl-4 text-xl text-[#2a303c] font-bold text-center">
                          Neighborhoods
                        </div>
                      </div>
                      <div className="flex w-1/2 content-center font-bold sm:text-lg max-w-3xl rounded-r-lg self-center">
                        <div className="text-center w-full text-xl text-adminConsoleText">
                          {neighborhoods ? neighborhoods.length : "0"}
                        </div>
                      </div>
                    </div>
                    <div class="divider"></div>

                    <div className="flex flex-col w-full bg-neutral-content/25 rounded drop-shadow-md mb-2 mr-4">
                      <div className="pt-4 pb-3 items-start">
                        <div className="pl-2 text-xl text-[#2a303c] font-bold ">
                          Neighborhood List
                        </div>
                      </div>

                      {neighborhoods ? (
                        neighborhoods.map((item, i) => {
                          return (
                            <div
                              key={item.id + item.adminUID}
                              className="btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start border border-black-500"
                              onClick={() => handleNeighborhoodClicked(item)}
                            >
                              <div className="mr-2">
                                <GiHouse
                                  style={{ fontSize: "32px" }}
                                  className="p-1"
                                />
                              </div>
                              <div className="self-center">{item.name}</div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="justify-self-center">
                          <BarLoader loading="true" color="#3abff8" />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col w-full bg-neutral-content/25 rounded drop-shadow-md mb-2">
                      <div
                        className="btn rounded border-none bg-white/0 hover hover:bg-natural-content justify-start border border-black-500"
                        onClick={() => setOpenCreateNeighborhood(true)}
                      >
                        <div className="mr-2">
                          <CgAddR
                            style={{ fontSize: "32px" }}
                            className="p-1"
                          />
                        </div>
                        New Neighborhood
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openCreateNeighborhood ? (
          <CreateNeighborhood
            setModalShow={setOpenCreateNeighborhood}
            setNeighborhoodName={setNeighborhoodName}
            setNeighborhoodCreated={setNeighborhoodCreated}
          />
        ) : null}
        {openSMSNotificationSettings ? (
          <AdjustSMSNotifications
            setModalShow={setOpenSMSNotificationSettings}
            setLeftMenuSelection={setLeftMenuSelection}
            middleSectionToShow={middleSectionToShow}
          />
        ) : null}
        {neighborhoodJustCreated ? <NewNeighborhoodInfoModal /> : null}
        {neighborhoodBeingCreated ? (
          <div className="flex flex-col justify-center items-center">
            <Loader />
          </div>
        ) : null}
        {showSignoutModal ? (
          <SignOutConfirm setShowSignoutModal={setShowSignoutModal} />
        ) : null}
        {showEditNameModal ? (
          <EditCompanyName setShowEditNameModal={setShowEditNameModal} />
        ) : null}
      </div>
    );
  }
}
//END Showing Screen

export default AdminHome;
