import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { differenceInDays } from "date-fns";
import SecurityUserContext from "../../context/SecurityUserContext";
import { FaHome } from "react-icons/fa";
import { GiArrest, GiDominoMask, GiSwapBag } from "react-icons/gi";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import BarLoader from "react-spinners/BarLoader";
import ActivityCardNeighborhood from "./Admin/AdminComponents/ActivityCardNeighborhood";
import Popup from "reactjs-popup";
import { AiOutlineLogout, AiOutlineDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function NeighborhoodActivity(props) {
  const { nid: paramNeighborhoodID } = useParams();
  const navigate = useNavigate();
  const { allIncidents, allIncidentReplies } = useContext(SecurityUserContext);
  const [loading, setLoading] = useState(true);
  const [neighborhoodIncidents, setNeighborhoodIncidents] = useState(null);
  const [neighborhoodReplies, setNeighborhoodReplies] = useState([]);
  const [repliesCount, setRepliesCount] = useState(0);
  const [incidentCount, setIncidentCount] = useState(0);
  const [typesLast24, setTypesLast24] = useState([]);
  const [allActivityLoaded, setAllActivityLoaded] = useState(true);
  const [allIncidentsDateFiltered, setAllIncidentsDateFiltered] = useState([]);
  const [dateFilter, setDateFilter] = useState(10);
  const [isDateFilterOpened, setIsDateFilterOpened] = useState("false");

  useEffect(() => {
    //get count of all incidents in last 24 here
    if (allIncidents && allIncidentReplies !== null) {
      const todaysDate = new Date();
      let tempIncidents = [];
      let tempReplies = [];

      allIncidents.map((item, i) => {
        if (item.neighborhoodID === paramNeighborhoodID) {
          tempIncidents.push(item);
        }
      });
      allIncidentReplies.map((item, i) => {
        if (item.neighborhoodID === paramNeighborhoodID) {
          tempReplies.push(item);
        }
      });
      setNeighborhoodIncidents(tempIncidents);
      setNeighborhoodReplies(tempReplies);

      // let tempIncidents = neighborhoodIncidents;
      // if (tempIncidents !== null) {
      //   tempIncidents.map((item, i) => {
      //     const dayDifference = differenceInDays(
      //       item.dateReported.toDate(),
      //       todaysDate
      //     );
      //     if (dayDifference === 0) {
      //       tempIncidentCount = tempIncidentCount + 1;
      //     }
      //   });
      //   setNumIncidentsLast24Hours(tempIncidentCount);
      // }

      //getting count of replies last 24 hours
      //   let tempRepliesCount = 0;
      //   let tempReplies = neighborhoodReplies;
      //   if (tempReplies !== null) {
      //     tempReplies.map((item, i) => {
      //       const dayDifference = differenceInDays(
      //         item.dateReported.toDate(),
      //         todaysDate
      //       );
      //       if (dayDifference === 0) {
      //         tempRepliesCount = tempRepliesCount + 1;
      //       }
      //     });
      //     setNumRepliesLast24Hours(tempRepliesCount);
      //   }
    }
  }, [allIncidents, allIncidentReplies]);

  //Getting incidents and replies within 24 Hours
  useEffect(() => {
    let todaysDate = new Date();
    if (neighborhoodReplies.length > 0) {
      let tempRepliesCount = 0;
      neighborhoodReplies.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference === 0) {
          tempRepliesCount = tempRepliesCount + 1;
        }
      });
      setRepliesCount(tempRepliesCount);
    }
    if (neighborhoodIncidents !== null) {
      let tempIncidentCount = 0;
      let tempTypes = [];
      neighborhoodIncidents.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference === 0) {
          tempIncidentCount = tempIncidentCount + 1;
          tempTypes.push(item.eventType);
        }
      });
      setIncidentCount(tempIncidentCount);
      setTypesLast24(tempTypes);
    }
  }, [neighborhoodReplies, neighborhoodIncidents]);

  //sort all activity by date
  useEffect(() => {
    let tempArray = null;
    if (neighborhoodIncidents !== null) {
      tempArray = neighborhoodIncidents;
      tempArray.sort((a, b) => b.dateReported - a.dateReported);
      setAllIncidentsDateFiltered([...tempArray]);
      setLoading(false);
    }
  }, [neighborhoodIncidents]);

  //Date Filter processing
  useEffect(() => {
    if (isDateFilterOpened === "true") {
      switch (dateFilter) {
        case 10:
          setIsDateFilterOpened("false");
          break;
        case 30:
          setIsDateFilterOpened("false");
          break;
        case 999:
          setIsDateFilterOpened("false");
          break;
      }
    }
  }, [isDateFilterOpened]);

  //Take user to incidient
  const handleActivityClick = (item) => {
    navigate(`/admin/${item.neighborhoodID}/incidentDetails/${item.id}`);
  };

  //When Activity is empty this will show
  const NoActivityBlock = () => {
    return (
      <div class="flex justify-center py-20 m-3 rounded-md text-center bg-base-300">
        <div class="max-w-md">
          <h1 class="text-5xl font-bold">No Activity</h1>
          <p class="py-6">
            This is where the list of incidents, current and past, will appear.
            When an incident occurs, it will appear in this section with details
            and a link to the incident.
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full w-full">
      {/* If I want to toggle the drawer, add "hidden" to the class below*/}
      <div className="basis-1/4">
        <div className="flex flex-col md:flex-row h-75 mr-10">
          <div className="basis-1/3 bg-[#ffffff] mt-10 drop-shadow-xl rounded-xl p-10">
            <div className="flex flex-col justify-center	">
              <div className=" text-center mt-2">
                <p className="text-adminConsoleText text-4xl font-bold ">
                  Incidents
                </p>
                <p className="text-sm mt-2">Last 24 Hours</p>
              </div>
              <div className="text-adminConsoleText text-4xl font-bold text-center mt-2">
                {incidentCount}
              </div>
            </div>
          </div>
          <div className="basis-1/3 bg-[#ffffff] mt-10 drop-shadow-xl rounded-xl p-10 ml-0 md:ml-4">
            <div className="flex flex-col justify-center	">
              <div className=" text-center mt-2">
                <p className="text-adminConsoleText sm:text-4xl font-bold ">
                  Replies
                </p>
                <p className="text-sm text-red mt-2">Last 24 Hours</p>
              </div>
              <div className="text-adminConsoleText text-4xl font-bold text-center mt-2">
                {repliesCount}
              </div>
            </div>
          </div>
          <div className="basis-1/3 bg-[#ffffff] mt-10 drop-shadow-xl rounded-xl p-10 ml-0 md:ml-4">
            <div className="flex flex-col justify-center	">
              <div className=" text-center mt-2">
                <p className="text-adminConsoleText text-4xl font-bold ">
                  Type
                </p>
                <p className="text-sm mt-2">Last 24 Hours</p>
              </div>
              <div className="flex flex-row flex-wrap text-adminConsoleText text-lg font-bold text-center mt-2 justify-center">
                {typesLast24.length > 0 ? (
                  typesLast24.map((item, i) => {
                    return (
                      <div className="p-1">
                        {item === "Burglary" ? (
                          <div className="tooltip" data-tip="Burglary">
                            <FaHome className="text-[24px]" />{" "}
                          </div>
                        ) : null}
                        {item === "Theft" ? (
                          <div className="tooltip" data-tip="Theft">
                            <GiSwapBag className="text-[24px]" />{" "}
                          </div>
                        ) : null}
                        {item === "Suspicious Behavior" ? (
                          <div
                            className="tooltip"
                            data-tip="Suspicious Behavior"
                          >
                            <GiDominoMask className="text-[24px]" />{" "}
                          </div>
                        ) : null}
                        {item === "Robbery" ? (
                          <div className="tooltip" data-tip="Robery">
                            <GiArrest className="text-[24px]" />{" "}
                          </div>
                        ) : null}
                        {item === "Other" ? (
                          <div className="tooltip" data-tip="Other">
                            <AiOutlineExclamationCircle className="text-[24px]" />{" "}
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <div>None</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*List of incidents*/}
      <div className="flex w-full mt-4">
        <div className="bg-[#ffffff] w-full mr-10 drop-shadow-xl rounded-xl">
          {allIncidentsDateFiltered.length === 0 ? (
            <NoActivityBlock />
          ) : (
            <div className="w-full mt-2 shadow-2xl bg-white rounded-xl">
              <div className=" py-2 row-span-2 rounded">
                <div className="flex flex-col h-full">
                  <div className="pl-3 flex flex-col align-middle">
                    <div className="my-auto text-xl font-bold text-[#2a303c]">
                      Activity
                    </div>
                    <div className="flex flex-row py-3 mr-2">
                      <Popup
                        trigger={
                          <button className="btn btn-xs mr-2">
                            <div className="flex flex-row">
                              {dateFilter === 999 ? (
                                <span className="pr-1 w-20">Show All</span>
                              ) : (
                                <span className="pr-1 w-20">{`Show ${dateFilter}`}</span>
                              )}

                              <AiOutlineDown />
                            </div>
                          </button>
                        }
                        arrow={false}
                        position="bottom left"
                      >
                        <div className="w-40 cursor-pointer ">
                          <div
                            className=" text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white rounded-t-md"
                            onClick={() => {
                              setIsDateFilterOpened("true");
                              setDateFilter(10);
                            }}
                          >
                            Last 10
                          </div>
                          <div
                            className="text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white"
                            onClick={() => {
                              setIsDateFilterOpened("true");
                              setDateFilter(30);
                            }}
                          >
                            Last 30
                          </div>
                          <div
                            className="text-sm p-1 pl-6 bg-[#191d24] hover:bg-[#2a303c] hover:text-white rounded-b-md "
                            onClick={() => {
                              setIsDateFilterOpened("true");
                              setDateFilter(999);
                            }}
                          >
                            All
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </div>
                  {allActivityLoaded ? (
                    <div className="flex flex-col h-full w-full justify-start items-start px-3">
                      <div class="overflow-y-auto overflow-x-auto flex items-center space-x-3a w-full">
                        <table className="sm:table-auto w-full">
                          <thead className="">
                            <tr className="">
                              <th>Date Reported</th>
                              <th>Time</th>
                              <th>Neighborhood Name</th>
                              <th>Incident Type</th>
                              <th>Location</th>
                              <th>Replies</th>
                            </tr>
                          </thead>

                          <tbody className="">
                            {allIncidentsDateFiltered && !loading
                              ? allIncidentsDateFiltered.map((item, i) => {
                                  if (i <= dateFilter) {
                                    return (
                                      <ActivityCardNeighborhood
                                        onClick={() =>
                                          handleActivityClick(item)
                                        }
                                        iVar={i}
                                        item={item}
                                        neighborhoodName={
                                          props.neighborhoodPropName
                                        }
                                        neighborhoodReplies={
                                          neighborhoodReplies
                                        }
                                        key={item.id + item.dateReported}
                                      />
                                    );
                                  }
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col h-full justify-center items-center">
                      <BarLoader loading="true" color="#3abff8" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NeighborhoodActivity;
