import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";

function ActivitySummaryCard() {
  const { allIncidents, allIncidentReplies, setActivitySummaryLoaded } =
    useContext(SecurityUserContext);
  const [last24Incidents, setLast24Incidents] = useState(null);
  const [last24Replies, setLast24Replies] = useState(null);

  //Activity Summary get all summary
  useEffect(() => {
    if (allIncidents !== null) {
      const tempArray = [];
      const todaysDate = new Date();
      allIncidents.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference >= 0) {
          tempArray.push(item);
        }
        console.log(tempArray);
      });
      setLast24Incidents(tempArray);
    }
    if (allIncidentReplies) {
      const tempArray = [];
      const todaysDate = new Date();
      allIncidentReplies.map((item, i) => {
        const dayDifference = differenceInDays(
          item.dateReported.toDate(),
          todaysDate
        );
        if (dayDifference >= 0) {
          tempArray.push(item);
        }
        console.log(tempArray);
      });
      setLast24Replies(tempArray);
    }
    setActivitySummaryLoaded(true);
  }, [allIncidents, allIncidentReplies]);

  useEffect(() => {
    if (last24Incidents !== null) {
      console.log(last24Incidents.length);
    }
  }, [last24Incidents]);

  return (
    <div className="pl-2">
      {last24Incidents !== null ? (
        <div className="flex flex-row">
          <div className="pr-4">Total Incidents:</div>

          <div className="text-xl text-[#2a303c]">{`   ${last24Incidents.length}`}</div>
        </div>
      ) : (
        <div>
          <div className="pr-4">Total Incidents:</div>
          <div className="text-xl text-[#2a303c]">None</div>
        </div>
      )}
      {last24Replies !== null ? (
        <div className="flex flex-row">
        <div className="pr-7">Total Replies:</div>
          <span className="text-xl text-[#2a303c]">{`   ${last24Replies.length}`}</span>
        </div>
      ) : (
        <div>Total Replies: None</div>
      )}
    </div>
  );
}

export default ActivitySummaryCard;
