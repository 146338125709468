import React, { useState, useEffect, useContext } from "react";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { useNavigate } from "react-router-dom";

function SignOutConfirm(props) {
  const { setSignout, setUserSubscribed } = useContext(SecurityUserContext);
  const navigate = useNavigate();
  const [tempName, setTempName] = useState("");
  const [showError, setShowError] = useState(false);

  const handleSubmit = () => {
    setSignout(true);
    
    props.setShowSignoutModal(false);
    navigate("/admin/securityservices/signin");
  };

  return (
    <div class="hero min-h-full drop-shadow-xl">
      <div class="hero-content flex-col lg:flex-row-reverse rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div class="card flex-shrink-0 w-full">
          <div class="card-body">
            <div className="flex flex-col">
              <div className=" mb-2">
                <p className="font-bold text-[#2a303c] text-center text-4xl pb-4">
                  Signout
                </p>
                <p className="">
                  Are you sure you would like to sign out completely?
                </p>
              </div>

              <div className="flex flex-row justify-between">
                <div class="form-control mt-6 pr-2">
                  <button
                    class="btn"
                    onClick={() => props.setShowSignoutModal(false)}
                  >
                    Cancel
                  </button>
                </div>
                <div class="form-control mt-6">
                  <button class="btn btn-info" onClick={handleSubmit}>
                    Yes, Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignOutConfirm;
