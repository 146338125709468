import React, { useState, useEffect, useContext } from "react";
import { PatternFormat } from "react-number-format";
import axios from "axios";
import SecurityUserContext from "../../../../context/SecurityUserContext";
import { MdDeleteForever } from "react-icons/md";
import { async } from "@firebase/util";

function AdjustSMSNotifications(props) {
  const { adminUID, adminUserDetails, setAdminUpdated, adminUpdated } =
    useContext(SecurityUserContext);
  const [addedNumber, setAddedNumber] = useState("");
  const [tempAddedNumber, setTempAddedNumber] = useState("");
  const [numbersInDB, setNumbersInDB] = useState([]);
  const [showErrorOutline, setShowErrorOutline] = useState(false)

  //phone numbers will be in securityusercontext under adminUserDetails
  //call getAdminDetailsFirestore from securityusercontext to get updated adminuserDetails
  //use useEffect to get new phone number list any time adminUserDetails is updated.

  //Adding number to list
  const handleAddPhoneNumberClicked = async () => {
    //show error tooltip if number is not 10 digits
    if(addedNumber.length !== 10) {
      console.log("show error number not correct")
      setShowErrorOutline(true)

      setTimeout(() => {
        setShowErrorOutline(false)
      }, 3000);

    } else {
    let tempNumbers = numbersInDB;
    tempNumbers.push(addedNumber);
    console.log(tempNumbers);
    console.log(addedNumber.length)

    await axios
      .get("/updatePhoneNumberContacts", {
        params: { allPhoneNumbers: tempNumbers, adminUID: adminUID },
      })
      .then((response) => {
        console.log(response.data);
      })
      .then(() => {
        setAdminUpdated(!adminUpdated);
        setTempAddedNumber("");
      });
    console.log("adding number");
    }
  };

  //Delete number in List
  const handleDeletePhoneNumberClicked = async (item) => {
    await axios
    .get("/deletePhoneNumberContact", {
      params: {adminUID: adminUID, arrayItem: item}
    })
    .then(() => {
      setAdminUpdated(!adminUpdated)
    })
  }

  //populating numbers variable numbersInDB
  useEffect(() => {
    if (adminUserDetails !== null) {
      console.log(adminUserDetails);
      if (adminUserDetails.phoneNumbers !== undefined) {
        setNumbersInDB(adminUserDetails.phoneNumbers);
      }
    }
    if (adminUID !== null) {
      console.log(adminUID.toString());
    }
  }, [adminUserDetails, adminUID]);

  useEffect(() => {
    if (numbersInDB !== null) {
      console.log(numbersInDB);
    }
  }, [numbersInDB]);

  // const handleDeleteNumber = async(number) => {
  //   let tempNumbers = numbersInDB
  //   const index = await tempNumbers.indexOf(number)
  //   if (index > -1) {
  //     tempNumbers.splice(index, 1)
  //   }
  //   await axios
  //     .get("/updatePhoneNumberContacts", {
  //       params: { allPhoneNumbers: tempNumbers, adminUID: adminUID },
  //     })
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .then(() => {
  //       setAdminUpdated(!adminUpdated);
  //       setTempAddedNumber("");
  //     });
  //   console.log("adding number");
  // }

  return (
    <div class="hero min-h-full drop-shadow-xl">
      <div class="hero-content py-40 px-24 flex-col lg:flex-row-reverse rounded rounded-md bg-gradient-to-r from-[#f2f1f6] to-[#e2e7f1]">
        <div className="flex flex-col">
          {/*Heading*/}
          <div className=" font-bold text-[#2a303c] mb-5">
            <p className="text-center text-2xl">Add / Remove</p>
            <p className="text-center text-3xl">Text Notification Numbers</p>
          </div>
          <div className="pl-3">Numbers currently being used for notifications</div>

          <div className={`flex flex-col h-full border-t border-b border-[#2a303c]/25 rounded-xl mb-4 p-4 ${numbersInDB.length === 0 ? " h-44" : ""}`}>
            {/*This is where you will output the numbers and have buttons to take numbers out*/}
            {numbersInDB !== null ? (
              numbersInDB.map((item, i) => {
                return (
                  <div key={item+i} className="flex flex-row pb-2">
                    <div className="mr-auto font-bold text-[#2a303c]">
                    <PatternFormat value={item} displayType={'text'} format="###-###-####" />
                    </div>
                    <div className="tooltip" data-tip="Delete Number" onClick={() => handleDeletePhoneNumberClicked(item)}>
                      <MdDeleteForever className="text-[#880808]/75 text-[24px] drop-shadow cursor-pointer" />
                    </div>
                  </div>
                );
              })
            ) : (
              <div>You do not have any contact numbers saved</div>
            )}
            {numbersInDB.length === 0 ? (
              <div className="">
                <p className="pb-4 text-[#2a303c]">You currently do not have any text contacts</p>
                <p className="pb-2">Contact numbers will show up in this box</p>
                <p>To add a text notification contact</p>
                <p> - Enter the phone number below</p>
                <p> - Then click add</p>
                

              </div>) : null}
          </div>

          {/*input and buttons*/}
          <div className="flex flex-col">
            <div className="flex flex-row mb-2 justify-between">
              <div className={`flex flex-col w-75 content-end ${showErrorOutline ? "tooltip tooltip-open tooltip-error" : ""}`} data-tip="Enter Valid Number">
                <PatternFormat
                  autoFocus
                  format="+1 (###) ### ####"
                  isNumericString
                  allowEmptyFormatting
                  mask="_"
                  className={`p-2 bg-white rounded-lg text-[#2a303c] ${showErrorOutline ? "border-2 border-red-500 animate-pulse" : ""}`}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    setTempAddedNumber(formattedValue);
                    setAddedNumber(value);
                  }}
                  value={tempAddedNumber}
                />
              </div>
              <div
                className="btn bg-[#49b977] border-none hover:bg-[#296b44] self-end"
                onClick={handleAddPhoneNumberClicked}
              >
                <p className="text-xs text-[#2a303c] font-bold">Add Number</p>
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div
                className="btn w-28"
                onClick={() => 
                  (props.setModalShow(false), props.setLeftMenuSelection(props.middleSectionToShow))
                }
              >
                Close
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdjustSMSNotifications;
