import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Signup from "../UserSignin/Signup";
import Loader from "../../components/Loader";
import SecurityUserContext from "../../../context/SecurityUserContext";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase.config";
import { toast } from "react-toastify";

function PaymentSuccess() {
  const {
    adminUID,
    setSignedIn,
    signedIn,
    setCompanyName,
    setUserStrInfo,
    userStrInfo,
    createNewUser,
    setCreateNewUser,
    setUserSubscribed,
    stripePurchaseId,
    userSubscribed,
  } = useContext(SecurityUserContext);
  const navigate = useNavigate();
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let userInfo = {};
  const [passwordIsIncorrect, setPasswordIsIncorrect] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const { name, email, password } = formData;

  let auth = getAuth();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  //BEGIN creating firestore account credentials and creating adminUser in database
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      //Sending credentials and creating email password auth in firestore
      auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(name)
      setCompanyName(name);
      await startCreatingUser();
    } catch (error) {
      console.log(error);

      //Launch this if user has already used this email.
      if (error.code === "auth/email-already-in-use") {
        try {
          const auth = getAuth();
          const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );
          setCompanyName(name);
          if (userCredential.user) {
            await startCreatingUser();
          }
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            setPasswordIsIncorrect(true);
            toast.error(
              "You have used this email before but the password is incorrect."
            );
          }
          console.log(error);
        }
      }
      if (error.code === "auth/weak-password") {
        toast.error("Your password needs to be at least 6 characters long");
      }
    }
  };

  const startCreatingUser = async () => {
    auth = getAuth();
    // updateCompanyName();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setCreateNewUser(true);
        console.log(user);
        userInfo = user;
        setUserSubscribed(true);
      } else {
        console.log("no user logged in");
      }
    });
  };

  const updateCompanyName = async () => {
    if (name !== "") {
      const companyNameRef = doc(db, "adminUsers", adminUID);
      await updateDoc(companyNameRef, {
        name: name,
      });
    }
  };

  //when usersubscribed is turned true then navigate to /signingin.
  useEffect(() => {
    if (userSubscribed) {
      navigate("/admin/signingin");
    }
  }, [userSubscribed]);
  //END creating firestore account credentials and creating adminUser in database

  //BEGIN this runs immediatly after coming from AcceptPayments.js
  //instantly set loading to true
  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    console.log(sessionId);
    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }
    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
    if (sessionId !== "") {
      setTimeout(() => {
        getStripeSessionInfo();
      }, 2000);
    }
  }, [sessionId]);

  const getStripeSessionInfo = async () => {
    await axios
      .get("/get-stripe-session", { params: { sessionId: sessionId } })
      .then((response) => {
        console.log(response.data);
        setUserStrInfo(response.data);
        setIsLoading(false);
      });
  };

  const SuccessDisplay = () => {
    return (
      <div>
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Payment Success!!!</h1>
        </div>
      </div>
    );
  };

  const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
  );
  //END this runs immediatly after coming from AcceptPayments.js

  if (success && sessionId !== "") {
    return (
      <div className="hero min-h-screen bg-base-200">
        <div className="hero-content flex-col lg:flex-row-reverse">
          <div className="flex flex-col">
            <SuccessDisplay sessionId={sessionId} />
            {isLoading ? (
              <p className="py-6 text-center lg:text-left">
                Please wait while we setup your accout
              </p>
            ) : null}
            {!isLoading ? (
              <p className="py-6 text-center lg:text-left	">
                Please create your account now.
              </p>
            ) : null}
          </div>
          {isLoading ? <Loader /> : null}
          {userStrInfo !== null ? (
            <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
              <div className="card-body">
                <form onSubmit={onSubmit}>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Company or Neighborhood Name"
                      id="name"
                      value={name}
                      onChange={onChange}
                      className="input input-bordered"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Email</span>
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      value={email}
                      onChange={onChange}
                      className="input input-bordered"
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Password</span>
                    </label>
                    <input
                      type="password"
                      placeholder="password"
                      id="password"
                      value={password}
                      onChange={onChange}
                      className="input input-bordered"
                    />
                  </div>
                  <div className="form-control mt-6">
                    <button className="btn btn-primary">Create Account</button>
                  </div>
                  {passwordIsIncorrect ? (
                    <div className="text-red-500 text-center mt-2 cursor-pointer">
                      Reset Password
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return <Message message={message} />;
  }
}

export default PaymentSuccess;
