import React from "react";
import { Link } from "react-router-dom";

function HomeNotLoggedIn() {
  return (
    <div>
      <div>HomeNotLoggedIn</div>
      <Link to={"/SubscriptionProducts"}>
        <div>Subscriptions</div>
      </Link>
    </div>
  );
}

export default HomeNotLoggedIn;
