import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  withRouter,
  useNavigate,
} from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GiPoliceBadge, GiPoliceCar } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import {
  GiCarnivalMask,
  GiSwapBag,
  GiArrest,
  GiDominoMask,
} from "react-icons/gi";
import { add, format, isEqual, differenceInDays } from "date-fns";

function IncidentCard({ item }) {
  const [dateReported, setDateReported] = useState(null);
  const [timeOfDayReported, setTimeOfDayReported] = useState(null);
  const [gotoDetailsPage, setGotoDetailsPage] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [happenedRecently, setHappenedRecently] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(item);
    if (item !== null) {
      setDateReported(format(item.data.dateReported.toDate(), "MM/dd/yyyy"));
      setTimeOfDayReported(format(item.data.dateReported.toDate(), "h:m a"));
    }
  }, []);

  //set happenedRecently to true if the event happened within the last 24 hours
  useEffect(() => {
    const todaysDate = new Date();
    const dayDifference = differenceInDays(item.data.dateReported.toDate(),todaysDate);
    console.log(dayDifference);
    if (dayDifference === 0) {
      setHappenedRecently(true);
    }
  }, [dateReported]);

  //navigate user to matchdetails page and sends data with it.
  useEffect(() => {
    if (gotoDetailsPage) {
      navigate(`/incidentdetails/${currentItemId}`, {
        state: {
          incidentId: currentItemId,
        },
      });
    }
  }, [gotoDetailsPage]);

  if (item.id === undefined) {
    return <div>Loading...</div>;
  } else {
    return (
      <tr
        onClick={() => (setGotoDetailsPage(true), setCurrentItemId(item.id))}
        className="hover cursor-pointer"
        key={item.id}
      >
        <th>
          <div class="font-bold">{dateReported}</div>
          <div class="text-sm opacity-50">{timeOfDayReported}</div>
        </th>
        <td>
          <div class="flex items-center space-x-3">
            <div class="avatar">
              <div class="mask mask-squircle w-12 h-12">
                {item.data.eventType === "Breakin" ? (
                  <FaHome style={{ fontSize: "48px" }} />
                ) : null}
                {item.data.eventType === "Theft" ? (
                  <GiSwapBag style={{ fontSize: "48px" }} />
                ) : null}
                {item.data.eventType === "Suspicious Person or Car" ? (
                  <GiDominoMask style={{ fontSize: "48px" }} />
                ) : null}
                {item.data.eventType === "Robbery" ? (
                  <GiArrest style={{ fontSize: "48px" }} />
                ) : null}
                {item.data.eventType === "Other" ? (
                  <AiOutlineExclamationCircle style={{ fontSize: "48px" }} />
                ) : null}
              </div>
            </div>
            <div>
              <div class="font-bold">{item.data.eventType}</div>
            </div>
          </div>
        </td>
        <td>
          <div>
            <div class="font-bold">{item.data.locationOfIncident}</div>
            <div class="text-sm opacity-50">Houston, TX 77077</div>
          </div>
        </td>
        <td>
          <div className="flex flex-row justify-start">
            {item.data.whoWasNotified.length !== 0
              ? item.data.whoWasNotified.map(function (item, i) {
                  return (
                    <div>
                      {item === "Police" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <GiPoliceBadge />
                          Police
                        </div>
                      ) : null}
                      {item === "Security" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <GiPoliceCar />
                          Security
                        </div>
                      ) : null}
                      {item === "Association" ? (
                        <div class="badge badge-success gap-2 m-2">
                          <HiUserGroup />
                          Association
                        </div>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        </td>
        <th>
          <div className={`flex justify-center indicator`}>
            {happenedRecently ? (
              <span className="indicator-item indicator-start badge bg-slate-600 border-none">
                New
              </span>
            ) : null}
            <button
              class="btn btn-outline btn-info"
              onClick={() => (
                setGotoDetailsPage(true), setCurrentItemId(item.id)
              )}
            >
              details
            </button>
          </div>
        </th>
      </tr>
    );
  }
}

export default IncidentCard;
