import React, { useState, useContext, useEffect, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";


function ViewBigImage(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(true);
  const resetArray = []

  useEffect(() => {
    setIsViewerOpen(props.isViewerOpen)
  }, [])


  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setCurrentImage(resetArray)
    props.setIsViewerOpen(false)
    props.setImageArrayViewing(resetArray)  
    props.setShowReplyBigImage(false)
    props.setShowBigImage(false)
  };

  return (
    <div>
      ImageViewer
      {isViewerOpen && (
        <ImageViewer
          src={props.imageArrayViewing}
          currentIndex={props.indexViewing}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={(closeImageViewer)}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.7)"
          }}
        />
      )}
    </div>
  );
}

export default ViewBigImage;
