import React, { useState, useEffect, useContext } from "react";
import { GiConsoleController } from "react-icons/gi";
import IncidentCard from "../components/IncidentCard";
import IncidentsContext from "../../context/IncidentsContext";

function Incidents() {
  const { allIncidents, loading, getAllIncidents, setLoading, setRefreshData, refreshData } =
    useContext(IncidentsContext);
  const [tempAllIncidents, setTempAllIncidents] = useState(null);
  const [allIncidentsFiltered, setAllIncidentsFiltered] = useState(null);
  const [filterSelection, setFilterSelection] = useState("Descending");


  useEffect(() => {
    getAllIncidents();
    if (!loading && allIncidents !== null) {
      setTempAllIncidents(allIncidents);
      sortIncidentArray();
    }
  }, [loading, refreshData]);

  useEffect(() => {
    sortIncidentArray()
  }, [filterSelection])

  //used to reload data on back and reload screen
  useEffect(() => {
    if (allIncidentsFiltered === null) {
      setRefreshData(true)
    }
    if (allIncidentsFiltered !== null) {
      setLoading(false);
    }
    console.log(allIncidentsFiltered);
  }, [allIncidentsFiltered, allIncidents]);

  //sort the incidents by descending or ascending
  const sortIncidentArray = () => {
    let tempArray = null;
    if (allIncidents !== null) {
      if (filterSelection === "Descending") {
        tempArray = allIncidents.sort(
          (objA, objB) =>
            Number(objB.data.dateReported) - Number(objA.data.dateReported)
        );
        setAllIncidentsFiltered([...tempArray]);
      }
      if (filterSelection === "Ascending") {
        tempArray = allIncidents.sort(
          (objA, objB) =>
            Number(objA.data.dateReported) - Number(objB.data.dateReported)
        );
        setAllIncidentsFiltered([...tempArray]);
      } 
    }
  };


//todo, need to fix the fact that if I hit refresh while on this screen
//  it doesn't load the map function and no data shows
  return (
    <div>
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col w-5/6">
          <div class="dropdown dropdown-bottom">
            <label tabindex="0" class="btn m-1 ">
              Filter
            </label>
            <ul
              tabindex="0"
              class="dropdown-content menu p-2 shadow bg-base-200 border border-info bg-opacity-90 rounded-box w-52"
            >
              <li onClick={() => setFilterSelection("Descending")}>
                <a>Date - Newest</a>
              </li>
              <li onClick={() => setFilterSelection("Ascending")}>
                <a>Date - Oldest</a>
              </li>
            </ul>
          </div>
          <div className="w-full items-center justify-center p-4 bg-white/20 rounded">
            <table class="table w-full">
              <thead>
                <tr>
                  <th>Date Reported</th>
                  <th>Indicent Type</th>
                  <th>Location</th>
                  <th>Incident Reported To</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!loading && allIncidentsFiltered !== null
                  ? allIncidentsFiltered.map(function (item, i) {
                      return <IncidentCard key={item.id} item={item} />;
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Incidents;
