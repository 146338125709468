import React, { useState, useContext, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import SecurityUserContext from "../../../context/SecurityUserContext";

function Signup() {
  const navigate = useNavigate();
  let userInfo = {};
  const {
    setSignedIn,
    signedIn,
    setCompanyName,
  } = useContext(SecurityUserContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = formData;

  let auth = getAuth();

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      //Sending credentials and creating email password auth in firestore
      auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;
      // await user.updateProfile(user, {
      //   displayName: name,
      // });
      setCompanyName(name)
      setSignedIn(true);
    } catch (error) {
      console.log(error);
    }
  };

  

  useEffect(() => {
    auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setSignedIn(true);
        console.log(user);
        userInfo = user;
        // sendUserInfoToFirebaseDB();
        navigate("/admin");
      } else {
        console.log("no user logged in");
      }
    });
  }, [signedIn]);

  

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Login now!</h1>
          <p className="py-6">
            Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
            excepturi exercitationem quasi. In deleniti eaque aut repudiandae et
            a id nisi.
          </p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <div className="card-body">
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Company or Neighborhood Name"
                  id="name"
                  value={name}
                  onChange={onChange}
                  className="input input-bordered"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Email</span>
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  className="input input-bordered"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  type="password"
                  placeholder="password"
                  id="password"
                  value={password}
                  onChange={onChange}
                  className="input input-bordered"
                />
              </div>
              <div className="form-control mt-6">
                <button className="btn btn-primary">Login</button>
              </div>
            </form>
            <Link to={"/securityservices/signin"}>
              <div className="text-center mt-5 text-lg"> Sign In </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;


//To Delete soon in future, i don't think I need it
//sending user login information to Firestore DB
// const sendUserInfoToFirebaseDB = async () => {
//   try {
//     adminUserInfo = {
//       name: name,
//       userInfo: userInfo.reloadUserInfo,
//     }
//     await setDoc(doc(db, "adminUsers", userInfo.uid), adminUserInfo);
//     await console.log("data sent to firestore db");
//   } catch (error) {
//     console.log(error);
//   }
// };