import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase.config";
import { collection, query, onSnapshot, getDoc, doc } from "firebase/firestore";

const IncidentsContext = createContext();

export const IncidentsProvider = ({ children }) => {
  //begin data
  const [allIncidents, setAllIncidents] = useState(null);
  const [allIncidentReplies, setAllIncidentReplies] = useState(null);
  const [neighborhoodDetails, setNeighborhoodDetails] = useState(null);
  //end data
  const [loading, setLoading] = useState(true);
  const [newDataReceived, setNewDataReceived] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [incidentsLoading, setIncidentsLoading] = useState(true);
  const [repliesLoading, setRepliesLoading] = useState(true);
  const [neighborhoodID, setNeighborhoodID] = useState(null);
  const [neighborhoodName, setNeighborhoodName] = useState(null);
  const [discordWebhookURL, setDiscordWebhookURL] = useState(null)

  //setting real-time snapshot listener on incidents for this one neighborhood
  useEffect(() => {
    if (neighborhoodID !== null) {
      const neighborhoodRef = collection(
        db,
        "neighborhoods",
        neighborhoodID,
        "incidents"
      );
      const q = query(neighborhoodRef);
      onSnapshot(q, (snapshot) => {
        let tempIncidents = [];
        snapshot.docs.forEach((doc) => {
          tempIncidents.push({ ...doc.data(), id: doc.id });
        });
        console.log(tempIncidents);
        setAllIncidents(tempIncidents);
      });
    }
  }, [neighborhoodID]);

  //setting real-time snapshot listener on incident replies for this one neighborhood
  useEffect(() => {
    if (neighborhoodID !== null) {
      const neighborhoodRef = collection(
        db,
        "neighborhoods",
        neighborhoodID,
        "incidentReplies"
      );
      const q = query(neighborhoodRef);
      onSnapshot(q, (snapshot) => {
        let tempIncidentReplies = [];
        snapshot.docs.forEach((doc) => {
          tempIncidentReplies.push({ ...doc.data(), id: doc.id });
        });
        console.log(tempIncidentReplies);
        setAllIncidentReplies(tempIncidentReplies);
      });
    }
  }, [neighborhoodID]);

  //Get neighborhood information
  useEffect(() => {
    getNeighborhoodname()
  }, [neighborhoodID]);

  const getNeighborhoodname = async () => {
    if (neighborhoodID !== null) {
      const docRef = doc(db, "neighborhoods", neighborhoodID);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNeighborhoodName(docSnap.data().name);
        setNeighborhoodDetails(docSnap.data())
        if (docSnap.data().webhookURL !== undefined) {
          setDiscordWebhookURL(docSnap.data().webhookURL)
          console.log(docSnap.data().webhookURL)
        }
        console.log(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
  };

  useEffect(() => {
    console.log(neighborhoodName);
  }, [neighborhoodName]);

  //let app know when all data is loaded
  useEffect(() => {
    if (incidentsLoading === false && repliesLoading === false) {
      setLoading(false);
    }
  }, [incidentsLoading, repliesLoading]);

  //run these functions when new data is received
  useEffect(() => {
    if (newDataReceived) {
      setNewDataReceived(false);
    }
    if (refreshData) {
      setRefreshData(false);
    }
  }, [newDataReceived, refreshData]);

  useEffect(() => {
    if (allIncidents !== null) {
      console.log(allIncidents);
    }
  }, [allIncidents]);

  useEffect(() => {
    console.log(neighborhoodID);
  }, [neighborhoodID]);

  return (
    <IncidentsContext.Provider
      value={{
        loading,
        allIncidents,
        allIncidentReplies,
        refreshData,
        neighborhoodID,
        neighborhoodName,
        discordWebhookURL,
        setLoading,
        setNewDataReceived,
        setRefreshData,
        setNeighborhoodID,
      }}
    >
      {children}
    </IncidentsContext.Provider>
  );
};

export default IncidentsContext;